.settings-content {
    margin: 12px 0;
}

.settings-page .ui.input > input {
    max-width: 90% !important;
}

.setting-conf-val {
    margin: 12px;
    display: flex;
    justify-content: center;
}

.setting-conf-val .ui.input {
    justify-content: center;
}

.input-box-setting {
    display: flex;
    flex-direction: column;
}

.margin-12 {
    margin:12px
}
.set{
    position: fixed;
    left: 40%;
    top: 4%;
    margin: 0;
    z-index: 2;
    padding: 12px;
    width: 200px;
}
.create-tournament.btn{
    height: 38px;
}
.pointer.disabled.own{
    color: #000!important;
}