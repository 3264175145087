@font-face {
  font-family: 'Roboto';
  src: url('../../assets/font/Roboto-Regular.woff2') format('woff2'),
  url('../../assets/font/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  //src: url('../../assets/font/Roboto-Medium.woff2') format('woff2'),
  //url('../../assets/font/Roboto-Medium.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --blot-white: 255, 255, 255;
  --blot-black: 0, 0, 0;
  --blot-white-1: rgba(255, 255, 255, 1);
  --blot-white-08: rgba(255, 255, 255, 0.8);
  --blot-white-07: rgba(255, 255, 255, 0.7);
  --blot-white-06: rgba(255, 255, 255, 0.6);
  --blot-white-05: rgba(255, 255, 255, 0.5);
  --blot-white-04: rgba(255, 255, 255, 0.4);
  --blot-white-03: rgba(255, 255, 255, 0.3);
  --blot-white-01: rgba(255, 255, 255, 0.1);
  --blot-black-06: rgba(0, 0, 0, 0.6);
  --blot-black-02: rgba(0, 0, 0, 0.2);
  --blot-black-01: rgba(0, 0, 0, 0.1);
  --blot-gray: rgba(85, 83, 83, 1);
  --blot-gray-v2: rgba(45, 51, 61, 1);
  --blot-dirty-gold: rgba(198, 167, 94, 1);
  --blot-light-blue: rgba(80, 185, 212, 1);
  --blot-light-blue-06: rgba(80, 185, 212, 0.6);
  --blot-light-green: rgb(99, 137, 78, 1);
  --blot-light-green-2x: rgba(125, 184, 81, 1);
  --blot-light-green-2x-06: rgba(125, 184, 81, 0.6);
  --blot-light-red: rgba(234, 68, 50, 1);
}

.dirty-gold {
  color: var(--blot-dirty-gold);
}


.light-green {
  color: var(--blot-light-green-2x);
}

.white-04 {
  color: var(--blot-white-04);
}

//* {
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  -webkit-touch-callout: none; /* iOS Safari */
//  -webkit-user-select: none; /* Safari */
//  -khtml-user-select: none; /* Konqueror HTML */
//  -moz-user-select: none; /* Old versions of Firefox */
//  -ms-user-select: none; /* Internet Explorer/Edge */
//  user-select: none; /* Non-prefixed version, currently
//                                 supported by Chrome, Opera and Firefox */
//}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  height: auto;
  width: 100%;
  min-height: 100%;
  background-color: rgba(var(--blot-gray), 1);
  color: rgba(var(--blot-white), 1);
  font-size: 15px;
  //-webkit-overflow-scrolling: touch;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: normal;
  //overflow: hidden;
}

body * {
  font-family: inherit;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
