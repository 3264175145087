.player-content{
    margin: 12px 0;
}
.check-box.padding-0, .margin-l.padding-0{
    padding: 0!important;
}
.player-info-content table{
    background-color:#f3f3f3!important;
}
.item{
    display: flex;
}
.fnt-10{
    font-size: 10px;
}
i.star{
    width: 31px!important;
    height: 29px!important;
    margin: 7px!important;
    padding: 0;
}
i.star.unset{
    width: unset!important;
    height: unset!important;
    margin: unset!important;
  }
i.large.icon, i.large.icons {
    line-height: 1.3!important;
    text-align: unset!important;
    font-size: 1.6em!important;

}
i.selected-icon{
    box-shadow: 0px 0px 7px 2px #d2d2d2;
    border: 2px solid;
}
i.grey.icon.star {
    color: #cacaca!important;
}
.flex-wrap{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.ellipsis{
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}









