.user-content{
    margin: 12px 0;
}
.user-content .ui.dropdown:not(.button)>.default.text {
    color: black!important;
}
.label, .label-X{
    font-size: 12px;
    margin: 7px 0 3px 0;
    text-indent: 3px;
}
.label-X{
    font-size: 11px;
    flex-basis: 40%;
}
.label-X.own{
    font-size: 20px;
    flex-basis: 40%;
    font-weight: bolder;
   }

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 30px;
    width: 240px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}
.ui.green.button{
    background-color: #63894e !important;
}