.profile-content{
    margin: 12px auto;
}
.profile-content>h2{
    text-align: center;
}
. req-mess {
    height: 14px;
}
.margin-r{
    margin-right: 12px;
}
.action-btn{
    display: flex;
    justify-content: flex-end;
}
.m-left{
    margin-left: 0;
}
.profile-content .p-left{
    padding-left: 0!important;
}
.profile-content .title{
    text-align: center;
    color: cadetblue;
}

.gl-toggle{
    display: flex;
    align-items: center;
}
.toggle-title{
    margin: 12px;
    color: rgba(255, 255, 255, 0.93);
}
.center-toggle{
    width: 203px;
    text-align: center
}
.highlight.toggle-title{
    color: #69c935;
}