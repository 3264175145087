.game-connection-status {
  color: var(--blot-white-06);
  font-weight: bold;

  .game-connection-status__span {
    align-items: center;
  }
}

.game-connection-status__span {
  display: none;

  &::before {
    content: "";
    width: 7px;
    height: 7px;
    margin-right: 4px;
    border-radius: 50%;
  }

  &--none {
    &::before {
      display: none;
    }
  }

  &--registration {
    &::before {
      background-color: var(--blot-light-blue);
    }
  }

  &--finished {
    &::before {
      background-color: var(--blot-light-red);
    }
  }

  &--busy {
    &::before {
      background-color: var(--blot-light-red);
    }
  }

  &--started {
    &::before {
      background-color: var(--blot-light-green);
    }
  }

  &--joined {
    &::before {
      background-color: var(--blot-dirty-gold);
    }
  }
}

.game-connection-status--none {
  .game-connection-status__span--none {
    display: flex;
  }
}

.game-connection-status--registration {
  .game-connection-status__span--registration {
    display: flex;
  }
}

.game-connection-status--busy {
  .game-connection-status__span--busy {
    display: flex;
  }
}

.game-connection-status--finished {
  .game-connection-status__span--finished {
    display: flex;
  }
}

.game-connection-status--started {
  .game-connection-status__span--started {
    display: flex;
  }
}

.game-connection-status--joined {
  .game-connection-status__span--joined {
    display: flex;
  }
}
