.tour-info {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #1e2229;
  background-image: url("../../assets/img/wrapper-trophy.png");
  background-size: 100% auto;
  background-position: bottom left;
  background-repeat: no-repeat;
  z-index: 60;
}

.tour-info__header {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tour-info__close-button {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
}

.tour-info__close-decor {
  width: 23px;
  height: 19px;
}

.tour-info__close-decor-svg {
  width: 100%;
  height: 100%;
  fill: var(--blot-white-1);
}

.tour-info__title {
  margin: 0;
  padding-left: 50px;
  padding-right: 50px;
  color: var(--blot-dirty-gold);
  font-size: 1.2rem;
  font-weight: bold;
}

.tour-info__body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-bottom: 70px;
}

.tour-info__inner-tabs-container {
  flex: 1 1 auto;
}

.tour-info__tabs {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
}

.tour-info__tab {
  flex-wrap: wrap;
  display: flex;
  align-items: stretch;
  width: calc(100% / 3);
}

.tour-info__tab-button {
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 15px;
  border: 0;
  background-color: transparent;
  color: var(--blot-white-1);
  font-size: 1.1rem;
  font-weight: normal;
  transition-duration: 0.2s;
  transition-property: font-weight;
  transition-timing-function: ease;
}

.tour-info__tab-button--active {
  font-weight: bold;

  .tour-info__tab-button-text {
    &::before {
      opacity: 1;
      width: 100%;
    }
  }
}

.tour-info__tab-button-text {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    opacity: 0;
    top: calc(100% + 5px);
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--blot-light-blue);
    transition-property: width;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
}

.tour-info__inner-tabs {
  list-style-type: none;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tour-info__inner-tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.tour-info__inner-tab--active {
  transform: translateX(0);
}

.tour-info__inner-tab--info {
  overflow: auto;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 1.1rem;
}

.tour-info__inner-tab--prizepool {
  overflow: auto;
}

.tour-info__info-row {
  display: flex;
  justify-content: space-between;
  color: var(--blot-white-07);
  font-weight: normal;

  &:not(:last-child) {
    margin-bottom: 23px;
  }
}

.tour-info__info-cell--right {
  font-weight: bold;
}

.tour-info__bottom-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 26px;

  .standard-button {
    width: 68%;
  }
}
