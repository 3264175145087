.players-information.players-information--special {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.players-information--special {
  .players-information__statistics-list {
    list-style-type: none;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    padding-left: 25px;
    padding-right: 35px;
    color: var(--blot-light-blue-06);
    font-size: 1.2rem;
  }

  .players-information__statistics-value {
    color: var(--blot-white-08);
    font-weight: bold;
  }

  .players-information__statistics-item {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .players-information__list {
    list-style-type: none;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
  }

  .players-information__item:nth-child(2n + 1) {
    background-color: var(--blot-black-02);
  }

  .players-information__item {
    display: flex;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    color: var(--blot-white-03);

    &.players-information__item--header {
      padding-top: 0;
      padding-bottom: 0;
      color: var(--blot-white-05);
      font-size: 1.2rem;

      & > span {
        display: flex;
        align-items: stretch;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }

    &.players-information__item--green {
      background-color: var(--blot-light-green-2x-06);
      color: var(--blot-white-08);
    }
  }

  .players-information__item.players-information__item--with-button:hover {
      background-color: var(--blot-white-01);
      color: var(--blot-white-08);

      .player-condition__status-text {
        display: none;
      }

      .player-condition__status-button {
        display: inline-block;
      }
  }

  .player-condition__id-search-container-button {
    padding: 0;
    padding-right: 35px;
    border: 0;
    background-image: url("../../assets/img/magnifying-blue.svg");
    background-size: auto 100%;
    background-position: right 5px center;
    background-color: transparent;
    background-repeat: no-repeat;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  .player-condition__id-search-container-input {
    display: none;
    width: calc(100% - 10px);
    border: 1px solid var(--blot-light-blue);
    border-radius: 3px;
    background-color: transparent;
    color: var(--blot-light-blue);
  }

  .player-condition__number {
    width: 31%;
  }

  .player-condition__id {
    width: 41%;
  }

  .player-condition__status {
    position: relative;
    width: 28%;
  }


  .player-condition__status-button {
    position: absolute;
    top: -3px;
    right: 34px;
    bottom: -3px;
    display: none;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 3px;
    color: inherit;
    background-color: var(--blot-black-06);
    border: 0;
    font-weight: inherit;
  }
}
