.managment-content{
    width: 100%;
    margin: 12px 0;
}
.error-mes.acl {
    display: block;
    font-size: 12px;
    text-indent: 12px;
    color: red;
    margin: 2px;
}
.resources-list{
    display: flex;
    flex-direction: column;
    min-height: 47px;
    border: thin #dbdada solid;
    border-radius: 10px;
    padding: 2px 13px 4px;
}
.groups-list{
    display: block;
    flex-direction: row;
    min-height: 47px;
    border: thin #dbdada solid;
    border-radius: 10px;
    padding: 2px 13px 8px;
    margin: 8px 0;
}
.resource-name, .group-name{
    width: 48%;
    display: inline-flex;
}
.group-name{
    width: max-content;
    margin: 2px 8px;
    border: thin solid lightgray;
    padding: 2px 6px;
    border-radius: 3px;
    display: inline-flex
}
.res-group-list, .res-user-list{
    width: fit-content;
    margin: 2px 4px;
    border: thin solid lightgray;
    padding: 1px 7px;
    border-radius: 18px;
    display: flow-root;
    float: left;
}
.resources-title, .groups-title{
    margin:3px 0;
    font-weigth:800;
    color:#2185d0;
}

.acl-edit-res{
    min-height: 149px;
    border: thin solid #c1c1c1;
    border-radius: 10px;
    padding: 14px;
    margin: 12px;
    height: auto;
}
.auto-complete{
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
}
.margin-12-acl {
    margin: 0 12px
}