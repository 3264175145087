.rounds {
    width: fit-content;
    border-right: 2px solid grey;
    margin: 12px;
    display: flex;
    flex-wrap: wrap-reverse;

}
.rounds td{
    display: flex;
}
td.td-flex{
    display: flex;
    flex-direction: column;
    margin: 0 20px 0 0;
}
td div.td-div-flex {
    display: flex;
    flex-direction: column;
    width: 120px;
    margin: 0 0 15px 12px;
    box-shadow: 0 0 14px 0 #e0e0e0;
    transition: all 0.5s linear
}
.table-id-div-flex{
    display: flex;
    flex-direction: column;
    width: 120px;
    margin: 0 0 10px 12px;
    text-align: center;
    color: #074252!important;
}
td div.td-div-flex:hover{
    transform:scale(1.08);
}

.winner{
    border-left-color: #c5e288!important;
    border-left-style: solid;
    border-left-width: 5px;
    color: #012e3a;
    padding: 12px;
    display: flex;
    box-shadow: 0px 3px 0px 0px #ebebeb;
    background-color: #8ce23dad !important;
    min-height: 43px;

}
.not-winner{
    border-left-color: #ff5760!important;
    border-left-style: solid;
    border-left-width: 5px;
    color: #ebebeb;
    padding: 12px;
    box-shadow: 0px 3px 0px 0px #ebebeb;
    background-color: #e67a7a;
    min-height: 43px;
}
.round-title{
    margin: 12px 6px;
    font-size: 26px;
    color: #074252!important;
    text-align: center;
}
.win-cube, .loss-cube{
    width: 40px;
    margin-left: 45px;
}

.win-cube-bef::before, .win-loss-bef::before{
    width: 33px;
    content: '';
    background-color: #e67a7a;
    height: 7px;
    position: absolute;
    margin: 7px;
}
.win-loss-bef::before{
    background-color: #8ce23dad
}
.info-win-loose{
    display:flex;
    justify-content: flex-end;
    padding-right: 25px;
}
 h5.r-title{
     margin: 12px 6px;
     text-align: center;
     border-bottom: 2px solid #548490 !important;
     padding: 12px;
     font-size: 20px
 }
