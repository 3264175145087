.standard-button {
  margin: 0;
  padding: 0;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background-color: var(--blot-light-green);
  color: var(--blot-white-1);
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bold;
}


.standard-button--cancel {
    background-color: var(--blot-gray);
    box-shadow:
            0 3px 3px 0 rgba(var(--blot-black), 0.16),
            inset 0 2px 0 0 rgba(var(--blot-white), 0.11);
}

.tab-special-button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--blot-white-1);
  font-size: 1.1rem;
  transition-duration: 0.2s;
  transition-property: font-weight;
  transition-timing-function: ease;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.tab-special-button--active {
  font-weight: bold;

  .tab-special-button__text {
    &::before {
      opacity: 1;
      width: 100%;
    }
  }
}

.tab-special-button__text {
  position: relative;
  text-transform: uppercase;

  &::before {
    content: "";
    position: absolute;
    opacity: 0;
    top: calc(100% + 5px);
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--blot-light-blue);
    transition-property: width;
    transition-duration: 0.5s;
    transition-timing-function: ease;
  }
}
