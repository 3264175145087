.type-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
}

.type-item {
  height: 22px;
  background-repeat: no-repeat;
  background-size: auto auto;
  background-position: center bottom;
  opacity: 0.7;
  transition: 0.2s;
}

.tour-info {
  .type-item {
    height: 20px;
    opacity: 0.7;
  }
}

.lobby__table-row--hover .type-item {
  opacity: 0.7;
}

.type-item:not(:last-child) {
  margin-right: 15px;
}

.type-item--without-4-cards {
  width: 35px;
  background-image: url("../../assets/mobile/img/without_4_cards_and_100.svg");
}

.type-item--dave-2-n {
  width: 15px;
  background-image: url("../../assets/img/dave.svg");
}

.type-item--dave-2-x {
  width: 15px;
  background-image: url("../../assets/mobile/img/Quansh 2x.svg");
}

.type-item--dave-4-x {
  width: 15px;
  background-image: url("../../assets/img/Quansh 4x.svg");
}

.type-item--dave-8-x {
  width: 15px;
  background-image: url("../../assets/mobile/img/Quansh 8x.svg");
}

.type-item--dave-16-x {
  width: 15px;
  background-image: url("../../assets/img/Quansh 16x.svg");
}


.type-item--rebelote-only {
  width: 29px;
  background-image: url("../../assets/img/belote_rebelote_only.svg");
}

.type-item--capot-2-x {
  width: 16px;
  background-image: url("../../assets/mobile/img/capot_2x.svg");
}

.type-item--no-trumps {
  width: 25px;
  background-image: url("../../assets/img/no_trumps.svg");
}

.type-item--private-game {
  width: 17px;
  background-image: url("../../assets/img/private_game.svg");
}

.type-item--point-diff {
  width: 20px;
  background-image: url("../../assets/mobile/img/point_difference.svg");
}
