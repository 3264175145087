.picky-date-time-calendar__body-container.xs {
     height: 150px!important;
}
.picky-date-time.visible{
    position: absolute;
    z-index: 10;
    background: white;
}
.ui.left.icon.input.date >input{
    padding: 0 1em 0 2.67em !important;
}
