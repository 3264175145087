@media (min-width: 1024px) {
  .players-information.players-information--special {
    position: fixed;
    width: 356px;
    top: 300px;
    bottom: 18px;
    right: 15px;
    z-index: 9;
    padding: 20px 18px;
    height: auto;
    border-radius: 13px;
    background-color: var(--blot-gray-v2);
  }

  .players-information--special {
    .players-information__item {
      color: var(--blot-white-07);
    }

    .players-information__title {
      margin-top: 0;
    }

    .players-information__item.players-information__item--header {
      color: var(--blot-white-08);
    }

    .players-information__statistics-list {
      margin-bottom: 13px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 1rem;
    }

    .player-condition__id {
      color: inherit;
    }

    .player-condition__number {
      color: inherit;
    }

    .player-condition__status {
      color: inherit;
    }
  }

  @media (max-height: 768px) {
    .players-information.players-information--special {
      top: 60px;
    }
  }

  .players-information__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 13px;
    height: 13px;
    padding: 0;
    border: 0;
    background-color: transparent;
    background-image: url("../../assets/img/close-thin.svg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 1;
  }
}


