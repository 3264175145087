@media (min-width: 1024px) {
  /* variables */
  :root {
    --blot-gray: 27, 31, 37;
    --blot-cyan: 31, 83, 99;
  }

  body {
    background-color: rgba(var(--blot-gray), 1);
    color: rgba(var(--blot-white), 1);
    height: 100% !important;
  }

  label:hover,
  button:hover {
    cursor: pointer;
  }

  .wrapper {
    height: 100vh;
  }

  * {
    scrollbar-color: rgba(0, 0, 0, 0.3) #18424e;
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #18424e;
  }

  .close-button {
    opacity: 0.8;
    width: 15px;
    height: 15px;
    border: none;
    background-color: transparent;
    background-image: url("../../assets/img/close-thin.svg");
  }

  /* header */

  //.header {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  z-index: 1000;
  //  display: flex;
  //  justify-content: space-between;
  //  height: 55px;
  //  padding-left: 20px;
  //  padding-right: 20px;
  //  background-color: rgba(255, 255, 255, 0.031);
  //  box-shadow: -4.494px 2.192px 3px 0px rgba(0, 0, 0, 0.16);
  //  font-size: 18px;
  //}

  .header__column {
    display: flex;
  }

  .header__column:not(.header__column--logo) {
    width: 340px;
  }

  .header__column:first-child {
    padding-left: 65px;
  }

  .header__column--logo {
    position: absolute;
    top: 8px;
  }

  .header__column:last-child {
    display: flex;
    justify-content: flex-end;
  }

  .popup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 4px;
    color: rgba(var(--blot-white), 0.7);
    font-weight: bold;
    transition: 0.2s;
  }

  .popup-button:not(:last-child) {
    margin-right: 32px;
  }

  .popup-button::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 11px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.7;
    transition: 0.2s;
    flex-shrink: 0;
  }

  .popup-button:hover {
    cursor: pointer;
    color: rgba(var(--blot-white), 1);
  }

  .popup-button:hover::before {
    opacity: 1;
  }

  .popup-button--history::before {
    background-image: url("../../assets/img/history.svg")
  }

  .popup-button--hot-to-play::before {
    background-image: url("../../assets/mobile/img/info-cyan.svg")
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-and-menu {
    min-width: 170px;
    padding: 5px;
    padding-right: 0;
    display: flex;
    align-self: center;
    border-radius: 15px;
    background-color: rgba(var(--blot-black), 0.3);
  }

  .user {
    display: flex;
  }

  .user__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-right: 7px;
    border-radius: 50%;
    background-color: rgba(var(--blot-cyan), 1);
  }

  .user__icon-img {
    max-width: 100%;
    max-height: 100%;
  }

  //.button {
  //  text-indent: -9999px;
  //  overflow: hidden;
  //  border: 0;
  //  background-color: transparent;
  //  background-size: auto 20px;
  //  background-position: center center;
  //  background-repeat: no-repeat;
  //  opacity: 0.7;
  //  transition: 0.2s;
  //  min-width: 51px;
  //}

  .button--sound {
    background-image: url("../../assets/mobile/img/sound.svg");
  }

  .button--sound-off {
    background-image: url("../../assets/img/sound-off.svg");
  }

  .button--full-screen {
    background-image: url("../../assets/mobile/img/full_screen.svg")
  }

  .button--full-screen-reverse {
    background-image: url("../../assets/mobile/img/full_screen_reverse.svg")
  }

  .button--menu {
    margin-left: auto;
    background-image: url("../../assets/mobile/img/menu.svg");
  }

  .button--menu.active {
    opacity: 1;
  }

  .button:hover {
    cursor: pointer;
    opacity: 1;
  }

  .user__amount {
    display: flex;
    align-items: center;
    padding-top: 2px;
    color: rgba(var(--blot-white), 0.5);
    font-size: 16px;
  }

  /* banner */

  .banner {
    display: none;
    position: relative;
    height: 310px;
  }

  .banner.active {
    display: block;
  }

  .banner__button {
    position: absolute;
    z-index: 2;
    top: 45%;
    text-indent: -9999px;
    overflow: hidden;
    width: 14px;
    height: 27px;
    background-color: transparent;
    border: 0;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    transition: 0.2s;
  }

  .banner__button:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  .banner__button--previous {
    left: 66px;
    background-image: url("../../assets/img/arrow_left.svg");
  }

  .banner__button--next {
    right: 66px;
    background-image: url("../../assets/img/arrow_right.svg");
  }

  .banner__list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .banner__item {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    //background-image: url("./img/banner-1@2x.png");
    background-position: bottom center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
  }

  //.banner__item:nth-child(1) {
  //    background-color: #06151a;
  //    background-size: auto 178%;
  //}
  //
  //.banner--open-belote .banner__item {
  //    background-image: url("./img/open-belote.png");
  //    background-size: 100% auto;
  //    background-position: center 14%;
  //}
  //
  //.fixed-banner__list {
  //    position: absolute;
  //    top: 0;
  //    right: 0;
  //    bottom: 0;
  //    left: 0;
  //    list-style-type: none;
  //    margin: 0;
  //    padding: 0;
  //}
  //
  //.fixed-banner__item {
  //    display: none;
  //    justify-content: center;
  //    align-items: center;
  //    width: 100%;
  //    height: 100%;
  //    font-size: 40px;
  //    font-weight: bold;
  //    text-transform: uppercase;
  //}
  //
  //.fixed-banner__item:nth-child(2) {
  //    background-image: url("./img/spin-blot-2x.jpg");
  //    background-size: 1920px auto;
  //    background-position: center -70px;
  //    background-color: #0a1d23;
  //}
  //
  //.fixed-banner__item:nth-child(3) {
  //    background-image: url(./img/tournaments.jpg);
  //    background-size: 1476px auto;
  //    background-position: calc(50% + 11px) -43px;
  //    background-color: #0a1d23;
  //}
  //
  //.fixed-banner__item:nth-child(4) {
  //    background-image: url(./img/leaderboard.jpg);
  //    background-size: auto calc(100% + 108px);
  //    background-position: center center;
  //    background-color: #0a1d23;
  //}
  //
  //.fixed-banner__item.active {
  //    display: block;
  //}
  //
  //@media (min-width: 1921px) {
  //    .fixed-banner__item:nth-child(2) {
  //        background-size: 100% auto;
  //    }
  //}


  .banner--disable-button .banner__button {
    display: none;
  }

  .banner__item.active {
    display: flex;
  }

  .lobby {
    position: relative;
    z-index: 2;
    display: none;
    height: calc(100vh - 261px);
    overflow: hidden;
    margin-top: -50px;
    padding-top: 50px;
    color: rgba(var(--blot-white), 0.7);
    font-size: 16px;
    font-weight: bold;
  }

  .lobby.active {
    display: flex;
  }

  .lobby__menu {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: flex-start;
    margin-top: -50px;
    font-size: 20px;
    font-weight: bold;
    padding-right: 12px;
  }

  .lobby__menu::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgb(27, 31, 37));
    height: 110px;
  }


  .lobby__menu-list {
    list-style-type: none;
    width: 82%;
    min-width: 732px;
    max-width: 921px;
    margin: 0;
    margin-left: 66px;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  .lobby__left {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .lobby__table {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 51px);
    overflow: hidden;
  }

  .lobby__table-body {
    flex-grow: 1;
    overflow-y: auto;
  }

  .lobby__right {
    width: 266px;
    margin-left: 6px;
    margin-right: 13px;
    height: 100%;
  }

  .lobby__menu-item-tab {
    position: relative;
    display: flex;
    padding-left: 40px;
    padding-bottom: 27px;
    color: rgba(var(--blot-white), 0.7);
    transition: .2s;
  }

  .lobby__menu-item-tab--classic::before {
    background-image: url("../../assets/img/classic.svg");
  }

  .lobby__menu-item-tab--open::before {
    background-image: url("../../assets/img/open.svg");
  }

  .lobby__menu-item-tab--spin::before {
    background-image: url("../../assets/mobile/img/spin.svg");
  }

  .lobby__menu-item-tab--tournaments::before {
    background-image: url("../../assets/img/tournaments.svg");
  }

  .lobby__menu-item-tab--leaderboard::before {
    background-image: url("../../assets/img/leaderboard.svg");
  }

  .lobby__menu-item-tab:not(.lobby__menu-item-tab--active):hover {
    cursor: pointer;
    color: rgba(var(--blot-white), 0.8);
  }

  .lobby__menu-item-tab::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: center center;
    opacity: 0.7;
    transition: 0.2s;
  }

  .lobby__menu-item-tab:not(.lobby__menu-item-tab--active):hover::before {
    opacity: 0.7;
  }

  .lobby__menu-item-tab::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    transition: 0.2s;
    background-color: rgba(var(--blot-white), 1);
  }

  .lobby__menu-item-tab--active {
    color: rgba(var(--blot-white), 1);
  }

  .lobby__menu-item-tab--active::before {
    opacity: 1;
  }

  .lobby__menu-item-tab--active::after {
    width: 100%;
  }

  .lobby__table-row {
    display: flex;
    padding-left: 56px;
    transition: 0.2s;
    align-items: flex-start;
  }

  .lobby__table-row--highlight {
    background-image: linear-gradient(90deg, rgba(40, 172, 212, 0.3), rgba(198, 133, 255, 0.3));
  }

  .lobby__table-cell {
    display: flex;
  }

  .lobby__table-cell--bet {
    width: 9%;
    justify-content: flex-start;
  }

  .lobby__table-cell--player-first {
    width: 10%;
    justify-content: flex-end;
  }

  .lobby__table-cell--score {
    width: 11%;
    justify-content: center;
  }

  .lobby__table-cell--player-two {
    width: 14%;
  }

  .lobby__table-cell--status {
    width: 11%;
  }

  .lobby__table-cell--scores {
    width: 10%;
  }

  .lobby__table-cell--timer {
    width: 9%;
  }

  .lobby__table-cell--type {
    width: calc(26% - 56px);
  }

  .lobby__table-head .lobby__table-cell--score .lobby__table-cell-element {
    position: relative;
  }

  .lobby__table-head .lobby__table-cell--score .lobby__table-cell-element::before,
  .lobby__table-head .lobby__table-cell--score .lobby__table-cell-element::after {
    content: "";
    display: block;
    width: 15px;
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(var(--blot-white), 0.2);
  }

  .lobby__table-row--header .lobby__table-cell--score .lobby__table-cell-element::before {
    right: calc(100% + 13px);
  }

  .lobby__table-row--header .lobby__table-cell--score .lobby__table-cell-element::after {
    left: calc(100% + 13px);
  }

  .lobby__table-cell-element {
    display: flex;
    padding: 14px 0;
  }

  .lobby__table-body .lobby__table-cell-element {
    padding: 8px 0;
  }

  .arrow-toggle {
    position: relative;
    padding-left: 22px;
    transition: 0.2s;
  }

  .arrow-toggle::before {
    content: "";
    position: absolute;
    top: 19px;
    left: 0;
    display: flex;
    width: 16px;
    height: 9px;
    background-image: url("../../assets/img/arrow_down.svg");
    opacity: 0.7;
    transition: 0.2s;
  }

  .arrow-toggle--right {
    padding-left: 0;
    padding-right: 22px;
  }

  .arrow-toggle--right::before {
    left: auto;
    right: 0;
  }

  .arrow-toggle.active {
    transform: scale(-1);
  }

  .lobby__table-body .lobby__table-row {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .lobby__table-body .lobby__table-row:nth-child(2n + 1) {
    background-color: rgba(3, 3, 3, 0.2);
  }

  .lobby__table-body .lobby__table-row.lobby__table-row--hover {
    background-color: rgba(var(--blot-white), 0.1);
    color: rgba(var(--blot-white), 0.6);
  }

  .lobby__table-body .lobby__table-cell--bet {
    color: #63894e;
    display: flex;
    flex-direction: column;
  }

  .lobby__table-body .lobby__table-cell--player-first .lobby__table-cell-element {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100px;
    border-radius: 6px;
    background-color: rgba(27, 31, 37, 0.6);
    font-weight: normal;
  }

  .lobby__table-body .lobby__table-cell--player-two .lobby__table-cell-element {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100px;
    border-radius: 6px;
    background-color: rgba(27, 31, 37, 0.6);
    font-weight: normal;
  }

  .lobby__table-body .lobby__table-row:nth-child(2n) .lobby__table-cell--player-first .lobby__table-cell-element {
    background-color: rgba(24, 27, 33, 0.6);
  }

  .lobby__table-body .lobby__table-row:nth-child(2n) .lobby__table-cell--player-two .lobby__table-cell-element {
    background-color: rgba(24, 27, 33, 0.6);
  }

  .lobby__table-cell--player-two:not(.with-button) .play-view {
    display: none;
  }

  .lobby__table-cell--player-two.with-button .lobby__table-cell-element:first-child {
    display: none;
  }

  .play-view {
    border: 0;
    color: rgba(var(--blot-white), 0.6);
    transition: 0.2s;
  }

  .lobby__table-body .lobby__table-cell--player-two .lobby__table-cell-element.play-view.play-view--play {
    background-color: rgba(23, 81, 96, 0.7);
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: bold;
  }

  .play-view--join {
    display: none;
    justify-content: center;
    min-width: 100px;
    border-radius: 6px;
    background-color: #63894e;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .play-view--join.blur {
    background-color: rgba(22, 25, 30, 0.6);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:hover {
    background-color: rgba(var(--blot-white), 0.1);
    color: rgba(var(--blot-white), 0.6);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined {
    background-color: #2c392a;
    color: rgba(var(--blot-white), 0.6);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined .special-orange {
    color: rgba(198, 167, 94, 0.9);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined .special-green {
    color: rgba(99, 137, 78, 1);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined .lobby__table-cell-pseudo-element {
    color: rgba(var(--blot-white), 0.7);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined .type-item {
    opacity: 0.7;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined .lobby__table-cell-timer {
    opacity: 0.7;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined .separator {
    background-color: rgba(var(--blot-white), 0.6);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined .status-game .status-game__span {
    display: none;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row.lobby__table-row--joined .status-game .status-game__span--joined {
    display: block;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:hover .play-view--join {
    display: flex;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:not(.lobby__table-row--joined):hover .status-game {
    display: none;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:not(.lobby__table-row--joined):hover .special-orange {
    color: rgba(198, 167, 94, 1);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:not(.lobby__table-row--joined):hover .lobby__table-cell--name.special-orange {
    color: rgba(198, 167, 94, 0.9);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:not(.lobby__table-row--joined):hover .special-green {
    color: rgba(99, 137, 78, 1);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:not(.lobby__table-row--joined):hover .lobby__table-cell-pseudo-element {
    color: rgba(var(--blot-white), 0.7);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:not(.lobby__table-row--joined):hover .type-item {
    opacity: 0.7;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:not(.lobby__table-row--joined):hover .lobby__table-cell-timer {
    opacity: 0.7;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-row:not(.lobby__table-row--joined):hover .separator {
    background-color: rgba(var(--blot-white), 0.6);
  }


  .play-view {
    height: 35px;
  }

  .play-view:hover {
    cursor: pointer;
  }

  .play-view span {
    display: none;
  }

  .play-view.play-view--play .play {
    display: block;
  }

  .play-view:not(.play-view--play) .view {
    display: block;
  }

  .comment {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    width: 17px;
    height: 16px;
    background-image: url("../../assets/mobile/img/comment_cloud.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }

  .no-comment {
    display: none;
  }

  .comment-text {
    position: absolute;
    top: -9px;
    right: calc(100% - 10px);
    min-width: 90px;
    min-height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/comment.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: rgba(254, 254, 255, 0.4);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.7px;
    clip-path: circle(0);
    transition: .2s;
  }

  .comment:hover {
    cursor: pointer;
  }

  .comment:hover .comment-text {
    clip-path: circle(100%);
  }

  .comment-text:hover {
    clip-path: circle(100%);
  }

  .status-game__span {
    position: relative;
    display: none;
    padding-left: 10px;
  }

  .status-game__span::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .status-game__span--waiting::before {
    background-color: #63894e;
  }

  .status-game__span--busy::before {
    background-color: #ea4432;
  }

  .status-game__span--registration::before {
    background-color: #63894e;
  }

  .status-game__span--finished::before {
    background-color: #ea4432;
  }

  .status-game__span--started::before {
    background-color: #4195ad;
  }

  .status-game__span--joined {
    color: #c6a75e;
  }

  .status-game__span--joined::before {
    background-color: #c6a75e;
  }

  .status-game:not(.status-game--waiting) .status-game__span--busy {
    display: block;
  }

  .status-game.status-game--waiting .status-game__span--waiting {
    display: block;
  }

  .status-game.status-game--registration .status-game__span--registration {
    display: block;
  }

  .status-game.status-game--finished .status-game__span--finished {
    display: block;
  }

  .status-game.status-game--started .status-game__span--started {
    display: block;
  }

  .status-game.status-game--joined .status-game__span--joined {
    display: block;
  }

  .lobby--tournaments .status-game__span::before {
    bottom: 8px;
    left: -2px;
    width: 7px;
    height: 7px;
  }

  .lobby__table-cell-element.type-list {
    padding: 0;
    padding-top: 4px;
    padding-bottom: 3px;
  }

  .type-list {
    list-style-type: none;
    align-items: center;
    margin: 0;
  }

  .type-item {
    height: 22px;
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: center bottom;
    opacity: 0.7;
    transition: 0.2s;
  }

  .lobby__table-row--hover .type-item {
    opacity: 0.7;
  }

  .type-item:not(:last-child) {
    margin-right: 15px;
  }

  .type-item--without-4-cards {
    width: 35px;
    background-image: url("../../assets/mobile/img/without_4_cards_and_100.svg");
  }

  .type-item--dave-2-n {
    width: 15px;
    background-image: url("../../assets/img/dave.svg");
  }

  .type-item--dave-2-x {
    width: 15px;
    background-image: url("../../assets/mobile/img/Quansh 2x.svg");
  }

  .type-item--dave-4-x {
    width: 15px;
    background-image: url("../../assets/img/Quansh 4x.svg");
  }

  .type-item--dave-8-x {
    width: 15px;
    background-image: url("../../assets/mobile/img/Quansh 8x.svg");
  }

  .type-item--dave-16-x {
    width: 15px;
    background-image: url("../../assets/img/Quansh 16x.svg");
  }


  .type-item--rebelote-only {
    width: 29px;
    background-image: url("../../assets/img/belote_rebelote_only.svg");
  }

  .type-item--capot-2-x {
    width: 16px;
    background-image: url("../../assets/mobile/img/capot_2x.svg");
  }

  .type-item--no-trumps {
    width: 25px;
    background-image: url("../../assets/img/no_trumps.svg");
  }

  .type-item--private-game {
    width: 17px;
    background-image: url("../../assets/img/private_game.svg");
  }

  .type-item--point-diff {
    width: 20px;
    background-image: url("../../assets/mobile/img/point_difference.svg");
  }

  .lobby__table-filter-button {
    position: absolute;
    top: 11px;
    right: 0;
    display: flex;
    align-items: center;
    padding-left: 27px;
    border: 0;
    background-color: transparent;
    color: rgba(var(--blot-white), 0.7);
    font-size: 16px;
    font-weight: bold;
    outline: 0;
    transition: 0.2s;
  }

  .lobby__table-filter-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 15px;
    height: 17px;
    background-image: url("../../assets/mobile/img/filter.svg");
    background-size: auto auto;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.7;
    transition: 0.2s;
  }

  .lobby__table-filter-button:hover {
    cursor: pointer;
    color: rgba(var(--blot-white), 0.6);
  }

  .lobby__table-filter-button:hover::before {
    opacity: 0.9;
  }

  .filter-container {
    position: absolute;
    top: 47px;
    right: 0;
    z-index: 10;
    padding-top: 10px;
    padding-bottom: 40px;
    border-radius: 3px;
    background-color: rgb(46, 51, 59);
    color: rgba(var(--blot-white), 0.7);
    font-size: 14px;
    transform: translateX(100%);
    transition: 0.2s;
    max-height: calc(100vh - 285px);
    overflow-y: auto;
  }

  .filter-container.active {
    transform: translateX(0);
  }

  .filter-container__title {
    margin-bottom: 14px;
    padding-left: 16px;
    color: rgba(var(--blot-white), 1);
    font-size: 18px;
  }

  .filter-container__row {
    display: flex;
    margin-bottom: 8px;
    padding-left: 27px;
    padding-right: 27px;
  }

  .filter-container__row--time {
    margin-bottom: 23px;
  }

  .filter-container__row--min-max {
    margin-bottom: 26px;
  }

  .filter-container__row--big-margin {
    margin-bottom: 13px;
  }

  .filter-container__row--blot-reblot {
    margin-bottom: 22px;
  }

  .filter-container__input-label {
    display: flex;
    flex-direction: column;
  }

  .filter-container__input-label:first-child {
    margin-right: 10px;
  }

  .filter-container__input-field {
    width: 120px;
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    background-color: rgba(var(--blot-black), 0.2);
    color: rgba(var(--blot-white), 0.7);
    font-size: 16px;
    font-weight: bold;
    outline: 0;
    color: #c7c4c4
  }

  .filter-container__input-field--left {
    border-radius: 4px 0 0 4px;
  }

  .filter-container__input-field--right {
    border-radius: 0 4px 4px 0;
  }

  .filter-container__label-radio-checkbox {
    display: flex;
    font-weight: normal;
  }

  .filter-container__label-radio-checkbox input[type="checkbox"],
  .filter-container__label-radio-checkbox input[type="radio"] {
    display: none;
  }

  .filter-container__label-radio-checkbox-decor {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    background-image: url("../../assets/img/check_box.svg");
    background-size: auto auto;
    background-position: center center;
    background-repeat: no-repeat;
    transition: 0.2s;
  }

  .filter-container__label-radio-checkbox:hover {
    cursor: pointer;
  }

  .filter-container__label-radio-checkbox input[type="checkbox"]:checked + .filter-container__label-radio-checkbox-decor,
  .filter-container__label-radio-checkbox input[type="radio"]:checked + .filter-container__label-radio-checkbox-decor {
    background-image: url("../../assets/mobile/img/bheck_box_2.svg");
  }

  .filter-container__button-close {
    position: absolute;
    top: 12px;
    right: 12px;
    overflow: hidden;
    width: 11px;
    height: 11px;
    text-indent: -9999px;
    background-color: transparent;
    background-image: url("../../assets/img/close.svg");
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: center center;
    border: 0;
    outline: 0;
  }

  .filter-container__button-close:hover {
    cursor: pointer;
  }

  .filter-container__button-reset {
    position: absolute;
    bottom: 14px;
    right: 14px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    font-size: 14px;
    color: rgba(var(--blot-white), 0.8);
    transition: 0.2s;
  }

  .filter-container__button-reset:hover {
    cursor: pointer;
    color: rgba(var(--blot-white), 1);
  }

  .filter-container__button-reset::before {
    content: "";
    display: block;
    width: 21px;
    height: 21px;
    margin-right: 10px;
    background-image: url("../../assets/img/reset.svg");
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.7;
    transition: 0.2s;
  }

  .filter-container__button-reset:hover::before {
    opacity: 0.9;
  }

  .filter-container__span {
    margin-left: 26px;
    margin-right: 13px;
    font-weight: normal;
  }

  .filter-container__row--time .filter-container__label-radio-checkbox:not(:last-child) {
    margin-right: 8px;
  }

  .filter-container__row--time .filter-container__label-radio-checkbox-decor {
    margin-right: 4px;
  }

  .filter-container__input-text {
    margin-bottom: 2px;
    padding-left: 5px;
    color: rgba(var(--blot-white), 0.7);
    font-size: 12px;
    font-weight: normal;
  }

  /* create table */

  .lobby__right-for-scroll {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
  }

  .create-table {
    padding-top: 9px;
    padding-left: 12px;
    padding-bottom: 15px;
    padding-right: 12px;
    border: 1px solid #282c32;
    border-radius: 10px;
    background-image: url("../../assets/mobile/img/create-table-background.png");
    background-position: 100% 0;
    background-size: auto auto;
    background-color: #252e32;
    color: rgba(var(--blot-white), 0.6);
    font-size: 14px;
    font-weight: normal;
  }

  .banner-special {
    flex-shrink: 0;
    height: 180px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #282c32;
    background-color: #252e32;
    background-image: url("../../assets/mobile/img/jackpot.jpg");
    background-position: center top;
    background-size: 120% auto;
  }

  .create-table__title {
    margin-bottom: 12px;
    color: rgba(var(--blot-white), 1);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .create-table__row {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .create-table__row--point-difference {
    margin-bottom: 19px;
  }

  .create-table__row--amount {
    margin-bottom: 23px;
  }

  .create-table__row--points-time {
    position: relative;
    z-index: 4;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 28px;
  }

  .create-table__row--radio-buttons {
    margin-bottom: 35px;
  }

  .create-table__row--points-time .create-table__row-left,
  .create-table__row--points-time .create-table__row-right {
    display: flex;
    width: calc(50% - 4px);
  }

  .create-table__row--for-margin {
    margin-bottom: 13px;
  }

  .create-table__label-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
   // user-select: none;
  }

  .create-table__label-checkbox:hover {
    cursor: pointer;
  }

  .create-table__label-checkbox-text {
    display: flex;
    align-items: center;
    transition: 0.2s;
  }

  .create-table__label-checkbox-text::before {
    content: "";
    display: block;
    width: 26px;
    height: 23px;
    margin-right: 9px;
    background-image: url("../../assets/mobile/img/point_difference.svg");
    background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
    transition: 0.2s;
  }

  .create-table__label-input {
    display: none;
  }

  .create-table__checkbox-decoration {
    position: relative;
    width: 32px;
    height: 17px;
    border-radius: 8px;
    background-color: rgba(var(--blot-black), 1);
    opacity: 0.4;
    transition: 0.2s;
  }

  .create-table__label-input:checked ~ .create-table__label-checkbox-text {
    color: rgba(var(--blot-white), 1);
  }

  .create-table__label-input:checked ~ .create-table__label-checkbox-text::before {
    opacity: 1;
  }


  .create-table__label-input:checked ~ .create-table__checkbox-decoration {
    opacity: 1;
  }

  .create-table__checkbox-decoration::before {
    position: absolute;
    top: 4px;
    left: 4px;
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: rgba(var(--blot-white), 1);
    transition: 0.2s;
  }

  .create-table__label-input:checked ~ .create-table__checkbox-decoration::before {
    left: calc(100% - 13px);
    background-color: #63894e;
  }

  .create-table__rule-info {
    position: absolute;
    top: 4px;
    left: 139px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 0;
    background-image: url("../../assets/img/info.svg");
    opacity: 0.5;
    transition: 0.2s;
    outline: 0;
  }

  .create-table__rule-info:hover {
    cursor: pointer;
    opacity: 1;
  }

  .create-table__input-label {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  .create-table__input-label-text {
    margin-left: 3px;
    margin-bottom: 2px;
    color: rgba(var(--blot-white), 1);
  }

  .create-table__input-label-rule {
    margin-left: 3px;
  }

  .create-table__input {
    height: 35px;
    margin-bottom: 2px;
    padding-right: 23px;
    padding-left: 11px;
    border: 0;
    border-radius: 5px;
    color: rgba(var(--blot-white), 0.5);
    background-color: rgba(var(--blot-black), 0.2);
    font-size: 18px;
    font-weight: bold;
    outline: 0;
  }

  .create-table__input::placeholder {
    color: rgba(var(--blot-white), 0.7);
    font-size: 16px;
  }

  .create-table__row--amount .create-table__input-label::before {
    position: absolute;
    top: 22px;
    right: 10px;
    color: rgba(var(--blot-white), 1);
    font-size: 18px;
  }

  .create-table__row--amount.AMD .create-table__input-label:before {
    content: '֏';
  }

  .create-table__row--amount.USD .create-table__input-label:before {
    content: '$';
  }

  .create-table__drop-down-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .create-table__drop-down-container--for-animate {
    z-index: 3;
    perspective: 1000px;
  }

  .create-table__label-text {
    margin-left: 3px;
    color: rgba(var(--blot-white), 1);
    font-size: 12px;
  }

  .create-table__label-text span:not(.active) {
    color: #a6a6a6;
  }

  .create-table__drop-down-button {
    position: relative;
    z-index: 10;
    border-radius: 5px 0 0 5px;
    height: 35px;
    padding-left: 11px;
    border: 0;
    background-color: rgba(var(--blot-black), 0.2);
    color: inherit;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    outline: 0;
  }

  .create-table__drop-down-button:hover {
    cursor: pointer;
  }

  .create-table__drop-down-button::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    width: 16px;
    height: 9px;
    margin-top: -4.5px;
    background-image: url("../../assets/img/arrow_down.svg");
    transition: 0.2s;
  }

  .create-table__drop-down-container.open .create-table__drop-down-button::before {
    transform: scale(-1);
  }

  .create-table__drop-down-button--right {
    border-radius: 0 5px 5px 0;
  }

  .create-table__drop-down-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 3;
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding: 0 12px;
    padding-top: 7px;
    padding-bottom: 3px;
    border-radius: 0 0 5px 5px;
    background-color: #004557;
    font-size: 16px;
    font-weight: bold;
    transform-origin: center top;
    transform: rotateX(-95deg);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 0.2s;
  }

  .create-table__drop-down-container.open .create-table__drop-down-list {
    transform: rotateX(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .create-table__drop-down-item {
    transition: 0.2s;
  }

  .create-table__drop-down-item:not(:last-child) {
    margin-bottom: 8px;
  }

  .create-table__drop-down-item--separator {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(var(--blot-black), 0.3);
  }

  .create-table__drop-down-item.active {
    color: rgba(var(--blot-white), 0.8);
  }

  .create-table__drop-down-item:not(.active):hover {
    cursor: pointer;
    color: rgba(var(--blot-white), 0.9);
  }

  .create-table__label-radio {
    display: flex;
  }

  .create-table__label-radio-input {
    display: none;
  }

  .create-table__label-radio-decor {
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    background-color: rgba(var(--blot-black), 0.4);
    border-radius: 50%;
  }

  .create-table__label-radio:not(:last-child) {
    margin-bottom: 16px;
  }

  .create-table__label-radio-decor::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #63894e;
    transition: 0.2s;
  }

  .create-table__label-radio-text {
    position: relative;
    transition: 0.2s;
  }

  .create-table__label-radio-input:checked ~ .create-table__label-radio-decor::before {
    width: 12px;
    height: 12px;
  }

  .create-table__label-radio:hover {
    cursor: pointer;
  }

  .create-table__label-radio-input:checked ~ .create-table__label-radio-text {
    color: rgba(var(--blot-white), 1);
  }

  .create-table__label-radio-text::after {
    content: "";
    position: absolute;
    left: 100%;
    top: -3px;
    display: block;
    width: 35px;
    height: 22px;
    margin-left: 12px;
    background-size: auto auto;
    background-position: left center;
    background-repeat: no-repeat;
    opacity: 0.6;
    transition: 0.2s;
  }

  .create-table__label-radio-text--4-cards::after {
    background-image: url("../../assets/mobile/img/without_4_cards_and_100.svg");
  }

  .create-table__label-radio-text--belote-rebelote::after {
    background-image: url("../../assets/img/belote_rebelote_only.svg");
  }

  .create-table__label-radio-input:checked ~ .create-table__label-radio-text::after {
    opacity: 0.9;
  }

  .create-table__label-checkbox--special + .create-table__rule-info {
    top: 4px;
    left: 87px;
  }

  .create-table__label-checkbox--no-trumps .create-table__label-checkbox-text::before {
    background-image: url("../../assets/img/no_trumps.svg");
  }

  .create-table__label-checkbox--private-games .create-table__label-checkbox-text::before {
    background-image: url("../../assets/img/private_game.svg");
    background-size: 17px auto;
  }

  .create-table__label-checkbox--capot-2x .create-table__label-checkbox-text::before {
    background-image: url("../../assets/mobile/img/capot_2x.svg");
    background-size: 16px auto;
  }

  .create-table__label-checkbox--dave-2x .create-table__label-checkbox-text::before {
    background-image: url("../../assets/img/dave.svg");
    background-size: 16px auto;
  }

  .create-table__drop-down-button--special {
    height: 28px;
    padding-left: 7px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
  }

  .create-table__row--special-row .create-table__drop-down-container {
    position: absolute;
    left: 28px;
    bottom: -2px;
    width: 115px;
    height: 28px;
    border-radius: 5px;
    clip-path: circle(0);
    transition: 0.2s;
  }

  .create-table__row--special-row.open-drop-down .create-table__drop-down-container {
    clip-path: circle(250%);
  }

  .create-table__drop-down-button--special::before {
    transform: scale(-1);
  }

  .create-table__row--special-row.open-drop-down .create-table__rule-info {
    left: 152px;
  }

  .create-table__row--special-row .create-table__drop-down-container .create-table__drop-down-list {
    top: auto;
    bottom: 100%;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: column-reverse;
    transform-origin: center bottom;
    transform: rotateX(95deg);
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }


  .create-table__row--special-row .create-table__drop-down-container.open .create-table__drop-down-list {
    transform: rotateX(0deg);
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
  }

  .create-table__row--special-row .create-table__drop-down-container.open .create-table__drop-down-button--special::before {
    transform: scale(1);
  }

  .create-table__row--special-row.open-drop-down .create-table__drop-down-container.open .create-table__drop-down-ite {
    margin-bottom: 0;
  }

  .create-table__drop-down-container--for-animate.open .create-table__drop-down-button--special {
    border-radius: 0 0 5px 5px;
  }

  .create-table__row--special-row.open-drop-down .create-table__drop-down-container.open .create-table__drop-down-item {
    margin-bottom: 8px;
  }

  .create-table__main-button {
    height: 40px;
    border: 0;
    border-radius: 9px;
    color: rgba(var(--blot-white), 1);
    background-color: #63894e;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .create-table__main-button:hover {
    cursor: pointer;
  }

  .lobby__table-cell--player-two:not(.with-button) .lobby__table-cell-element--transform-button {
    position: relative;
  }

  .lobby__table-body .lobby__table-cell--player-two:not(.with-button) .lobby__table-cell-element--transform-button .lobby__table-cell-element {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background-color: rgb(25, 29, 34);
    clip-path: circle(0);
    transition: 0.2s;
  }

  .lobby__table-body .lobby__table-row.lobby__table-row--hover .lobby__table-cell--player-two:not(.with-button) .lobby__table-cell-element--transform-button .lobby__table-cell-element {
    clip-path: circle(100%);
  }

  .lobby__table-head .lobby__table-cell-element.arrow-toggle {
    cursor: pointer;
  }

  .lobby__table-head .lobby__table-cell-element.arrow-toggle:hover {
    color: rgba(var(--blot-white), 0.6);
  }

  .lobby__table-head .lobby__table-cell-element.arrow-toggle:hover:before {
    opacity: 0.6;
  }

  .lobby__table-head .lobby__table-cell-element.arrow-toggle.sort::before {
    transform: scale(-1);
    /*opacity: 1;*/
    transition: 0.2s;
  }

  .lobby__table-head .lobby__table-cell-element.arrow-toggle.sort {
    /*color: rgba(var(--blot-white), 1);*/
  }

  /* spin */

  .lobby--spin .lobby__left {
    width: calc(100% - 375px);
  }

  .lobby--spin .lobby__right {
    width: 375px;
  }

  /* spin table cell width */


  .lobby--spin .lobby__table-body .lobby__table-row {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .lobby--spin .lobby__table-row {
    padding-right: 22px;
  }

  .lobby--spin .lobby__table-cell--maximum-win {
    width: 22%;
  }

  .lobby--spin .lobby__table-cell--bet {
    width: 11%;
  }

  .lobby--spin .lobby__table-cell--scores {
    width: 12%;
  }

  .lobby--spin .lobby__table-cell--type {
    width: 22%;
  }

  .lobby--spin .lobby__table-cell--players {
    display: flex;
    align-items: center;
    width: 21%;
  }

  .lobby--spin .lobby__table-cell--player-two {
    width: 12%;
  }


  .lobby--spin .lobby__table-cell--player-two {
    width: 12%;
  }

  .lobby--spin .lobby__table-cell--player-two.with-button .lobby__table-cell-element:first-child {
    display: block;
    margin-left: auto;
  }

  .lobby--spin .lobby__table-body .lobby__table-cell--maximum-win {
    color: rgba(198, 167, 94, 0.8);
  }

  .lobby--spin .lobby__table-cell--player-two.with-button .lobby__table-cell-element.disable:first-child {
    background-color: rgba(22, 25, 30, 0.6);
  }

  .lobby--spin .lobby__table-cell--player-two.with-button .lobby__table-cell-element.disable:first-child .play {
    display: none;
  }

  .lobby--spin .lobby__table-cell--player-two.with-button .lobby__table-cell-element:first-child .play {
    display: block;
  }

  .lobby--spin .lobby__table-cell--player-two.with-button .lobby__table-cell-element.disable:first-child .disable {
    display: block;
  }

  .lobby--spin .lobby__table-cell--player-two.with-button .lobby__table-cell-element:first-child .disable {
    display: none;
  }

  .spin-table-players {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .spin-table-player {
    width: 27px;
    height: 27px;
    margin-right: 20px;
    background-image: url("../../assets/mobile/img/user-icon.svg");
    background-size: 16px 18px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .spin-table-player.disable {
    background-image: url("../../assets/img/user-icon-white.svg");
    opacity: 0.7;
  }

  .spin-table-player.active {
    background-image: url("../../assets/img/user-icon-active.svg");
    background-size: 100% 100%;
  }

  .spin-table-player:not(:last-child) {
    margin-right: 4px;
  }

  .spin-table__wait {
    display: flex;
    align-items: center;
    clip-path: circle(0);
    transition: clip 0.2s;
    font-weight: normal;
  }

  .lobby--spin .lobby__table-body .lobby__table-row--hover .spin-table__wait {
    clip-path: circle(100%);
  }

  .spin-table__wait::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin-left: 8px;
    background-image: url("../../assets/mobile/img/time-icon.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.7;
    transition: 0.2s;
    transition-delay: 0.2s;
  }

  .lobby--spin .lobby__table-body .lobby__table-row--hover .spin-table__wait::after {
    opacity: 0.6;
  }
  //
  //.winners {
  //  display: flex;
  //  flex-direction: column;
  //  height: 100%;
  //  overflow: hidden;
  //}

  .winners__tabs {
    list-style-type: none;
    display: flex;
    margin: 0;
    margin-bottom: 6px;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
  }

  .winners__tabs-content {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
  }

  .winners__tab-content {
    display: none;
  }

  .winners__tab-content.active {
    display: block;
  }

  .winners__tab:not(:last-child) {
    margin-right: 30px;
  }

  .winners-tab__link {
    position: relative;
    display: inline-block;
    padding-top: 2px;
    padding-bottom: 8px;
    transition: 0.2s;
  }

  .winners-tab__link:not(.active):hover {
    cursor: pointer;
    color: rgba(var(--blot-white), 0.7);
  }

  .winners-tab__link::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    left: 0;
    bottom: 0;
    height: 2px;
    background-color: rgba(var(--blot-white), 0.2);
    transition: 0.2s;
  }

  .winners-tab__link.active {
    color: rgba(var(--blot-white), 1);
  }

  .winners-tab__link.active::before {
    width: 100%;
    background-color: rgba(var(--blot-white), 1);
  }

  .winners__player-winners {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .winners__player-winner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 62px;
    padding-right: 25px;
    padding-bottom: 20px;
    padding-left: 25px;
    background-color: #181c21;
    border: 1px solid #21252b;
    border-radius: 4px;
  }

  .winners__player-winner {
    color: rgba(99, 137, 78, 0.9);
  }

  .winners__player-winner.big-prize {
    color: rgba(198, 167, 94, 0.9);
  }

  .winners__player-winner.big-prize .winners__player-winners-title__win::before {
    background-image: url("../../assets/img/crown-orange.svg");
  }

  .winners__player-winner:not(:last-child) {
    margin-bottom: 3px;
  }

  .winners__player-detail-left {
    color: rgba(var(--blot-white), 0.7);
  }

  .winners__player-detail-right {
    color: rgba(var(--blot-white), 0.6);
  }

  .winners__player-winners-title {
    position: absolute;
    top: 6px;
    margin: 0;
    padding: 0;
    color: inherit;
    font-size: 18px;
    line-height: 1;
  }

  .winners__player-winners-title__win {
    display: flex;
    margin-bottom: 6px;
    align-items: flex-end;
  }

  .winners__player-winners-title__win::before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 20px;
    margin-right: 7px;
    background-image: url("../../assets/img/crown.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0.5;
  }
  .winners__player-winners-title__win-text {
    margin-bottom: -2px;
  }

  .winners__player-details {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
  }

  .winners__player-detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 1;
  }

  .winners__player-detail:not(:last-child) {
    margin-bottom: 13px;
  }

  .lobby--spin .lobby__right-for-scroll {
    padding-right: 3px;
  }

  /* Tournaments table cell width */

  .lobby--tournaments .lobby__table-body .lobby__table-row {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .lobby--tournaments .lobby__table-cell--name {
    width: 14%;
  }

  .lobby--tournaments .lobby__table-cell--buy-in {
    width: 9.5%;
  }

  .lobby--tournaments .lobby__table-cell--prize {
    width: 9.5%;
  }

  .lobby--tournaments .lobby__table-cell--start-date {
    width: 17%;
  }

  .lobby--tournaments .lobby__table-cell--players {
    width: 12%;
  }

  .lobby--tournaments .lobby__table-cell--type {
    width: 24%;
  }

  .lobby--tournaments .lobby__table-cell--status {
    width: 14%;
  }

  .lobby--tournaments .lobby__table-body .lobby__table-cell--status {
    padding-left: 4px;
  }

  .lobby--tournaments .lobby__table-body {
    font-size: 18px;
  }

  .special-orange {
    color: rgba(198, 167, 94, 0.5);
  }

  .special-green {
    color: rgba(99, 137, 78, 0.9);
  }

  .lobby--tournaments .lobby__table-body .lobby__table-cell--players .lobby__table-cell-element {
    padding-bottom: 10px;
    line-height: 1;
  }

  .separator {
    display: block;
    width: 1px;
    height: 15px;
    margin: 0 5px;
    background-color: rgba(var(--blot-white), 0.7);
  }

  .separator--large {
    height: 21px;
    margin: 0 8px;
  }

  .lobby__table-cell-element--center {
    align-items: center;
  }

  .lobby__table-cell-element--bottom {
    align-items: flex-end;
  }

  .lobby__table-cell-pseudo-element {
    margin-left: 5px;
    color: rgba(var(--blot-white), 0.7);
    font-size: 12px;
  }

  .lobby__table-cell-timer {
    display: block;
    width: 18px;
    height: 23px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.7;
  }

  .score__game-rule-list .lobby__table-cell-timer {
    height: 20px;
  }

  .lobby__table-cell-timer--twenty {
    background-image: url("../../assets/mobile/img/time-twenty.svg");
  }

  .lobby__table-cell-timer--thirty {
    background-image: url("../../assets/img/time-thirty.svg");
  }

  .lobby__table-cell-timer--forty {
    background-image: url("../../assets/img/time-forty.svg");
  }

  .lobby__table-cell-timer--fifty {
    background-image: url("../../assets/img/time-fifty.svg");
  }

  .lobby--tournaments .lobby__table-cell--type {
    align-items: center;
  }

  .lobby--tournaments .lobby__table-body {
    color: rgba(var(--blot-white), 0.7);
  }

  /* tournaments-info */

  .tournaments-info {
    padding-top: 26px;
    padding-right: 25px;
    padding-bottom: 14px;
    padding-left: 22px;
    height: 590px;
    border: 1px solid #23272d;
    border-radius: 10px;
    background-color: #181b21;
    background-image: url("../../assets/mobile/img/tournaments-info-wrapper-v2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: rgba(var(--blot-white), 0.35);
    font-size: 16px;
    font-weight: normal;
  }

  .tournaments-info__title {
    margin: 0;
    margin-bottom: 16px;
    padding: 0;
    color: rgb(198, 167, 94);
    font-size: 22px;
    line-height: 1;
    text-align: center;
  }

  .tournaments-info__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .tournaments-info__cell--right {
    font-weight: bold;
    color: rgba(var(--blot-white), 0.6);
  }

  .lobby--tournaments .tournaments-info .status-game--registration .status-game__span--registration {
    display: block;
  }

  .lobby--tournaments .tournaments-info .status-game--finished .status-game__span--finished {
    display: block;
  }

  .lobby--tournaments .tournaments-info .status-game--started .status-game__span--started {
    display: block;
  }

  .lobby--tournaments .tournaments-info .status-game--joined .status-game__span--joined {
    display: block;
    color: rgba(var(--blot-white), 0.6);
  }

  .lobby--tournaments .tournaments-info .status-game__span::before {
    bottom: 7px;
  }

  .tournaments-info__row--buy-in .tournaments-info__cell--right {
    color: #7db851;
  }

  .tournaments-info__row--prize .tournaments-info__cell--right {
    color: #c6a75e;
  }

  .lobby__table-cell-element--tournaments-info .type-item {
    opacity: 0.7;
  }

  .tournaments-info__row--type {
    margin-bottom: 9px;
  }

  .tournaments-info__row--timer {
    margin-bottom: 22px;
  }

  .tournaments-info__places-title {
    margin-bottom: 15px;
    color: rgba(var(--blot-white), 0.8);
    font-size: 18px;
    font-weight: bold;
  }

  .tournaments-info__row--places-list {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .tournaments-info__places-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .tournaments-info__places-item {
    margin-bottom: 2.4px;
  }

  .tournaments-info__places-item span {
    color: rgba(var(--blot-white), 0.7);
    font-weight: bold;
  }

  .tournaments-info__row--players .tournaments-info__cell-item {
    display: flex;
  }

  .tournaments-info__cell-item--top {
    margin-bottom: 2px;
  }

  .tournaments-info__row--players .text-bold {
    color: rgba(var(--blot-white), 0.7);
    font-weight: bold;
  }

  .see-players {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    margin-top: 4px;
    height: 33px;
    border: 0;
    border-radius: 6px;
    background-color: rgba(var(--blot-white), 0.25);
    color: rgba(var(--blot-white), 0.7);
    font-size: 14px;
    font-weight: bold;
  }

  .tournaments-info__row--players {
    margin-bottom: 29px;
  }

  .tournaments-info__start-button {
    display: block;
    width: calc(100% + 20px);
    min-height: 40px;
    margin-left: -10px;
    margin-right: -10px;
    border: 0;
    border-radius: 10px;
    background-color: #63894e;
    color: rgba(var(--blot-white), 1);
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .tournaments-info__start-button.disable {
    background-color: rgba(52, 57, 69, 0.6);

  }

  .tournaments-info__block {
    transform: translateY(-1500px);
    transition: 0.2s;
    height: 0;
    overflow: hidden;
  }

  .tournaments-info__block.active {
    height: auto;
    overflow: visible;
    transform: translateY(0px);
  }

  .tournaments-info__click-on {
    display: block;
    color: rgba(198, 167, 94, 0.9);
    font-size: 18px;
    font-weight: bold;
    width: 195px;
    margin: 0 auto;
    margin-top: 0;
    text-align: center;
  }

  .tournaments-info__click-on.active {
    margin-top: 60px;
  }

  .tournaments-info__click-on::before {
    content: "";
    display: block;
    width: 52px;
    height: 52px;
    margin: 0 auto;
    margin-bottom: 28px;
    background-image: url("../../assets/img/cup-orange.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media (max-width: 1300px) {
    .lobby__table {
      overflow-y: auto;
    }

    .lobby__menu-list {
      margin-left: 16px;
    }

    .lobby__table-row {
      padding-left: 16px;
    }

    .lobby__table-head .lobby__table-cell--score .lobby__table-cell-element::after,
    .lobby__table-head .lobby__table-cell--score .lobby__table-cell-element::before {
      display: none;
    }
  }

  .create-table__drop-down-item.inactive {
    opacity: 0.7;
    pointer-events: none;
  }


  @media (max-width: 1200px) {
    .lobby__table-cell--timer {
      display: none;
    }

    .lobby__table-cell--player-first {
      width: 13%;
    }

    .lobby__table-cell--status {
      width: 8%;
    }

    .lobby__table-head .lobby__table-cell--score .lobby__table-cell-element::after,
    .lobby__table-head .lobby__table-cell--score .lobby__table-cell-element::before {
      display: none;
    }

    .lobby__table-body .lobby__table-cell--status {
      padding-left: 21px;
      font-size: 0;
    }
  }

  .fixed-size {
    width: 1024px !important;
    height: 710px !important;
    margin: 0 auto;
    transform-origin: top center;
  }

  .for-center-game {
    position: relative;
    width: 800px;
    height: 100vh;
    margin: auto;
  }

  .fixed-size .blot-field {
    width: 100%;
    height: 100%;
  }

  .fixed-size .header {
    right: auto;
    left: auto;
    width: 1024px;
    margin: 0 auto;
  }


  .lobby--leader-board {
    display: flex;
    justify-content: space-between;
  }

  .lobby--leader-board .lobby__left {
    flex-grow: 0;
    width: calc(100% - 410px - 75px);
  }

  .lobby--leader-board .lobby__right {
    display: flex;
    width: 410px;
  }

  .lobby--leader-board .lobby__right-for-scroll {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .leader-board__create {
    width: 100%;
  }

  .leader-board__prizes-list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 12px;
    padding-bottom: 31px;
    padding-left: 68px;
  }

  .leader-board__prizes-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .leader-board__prizes-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 83px;
    height: 83px;
    margin-right: 15px;
    border-radius: 50%;
    box-shadow: -1.124px 2.78px 1.71px 1.29px rgba(0, 0, 0, 0.21), inset 0 1px 0 0 rgba(255, 255, 255, 0.36);
  }

  .leader-board__prizes-img {
    max-width: 100%;
    max-height: 100%;
  }

  .leader-board__prizes-right {
    padding-top: 8px;
  }

  .leader-board__prizes-id {
    margin-bottom: 11px;
    color: rgba(var(--blot-white), 1);
    font-size: 15px;
    font-weight: 700;
  }

  .leader-board__prizes-row {
    display: flex;
    align-items: center;
  }

  .lobby__table-cell-statistics {
    position: relative;
    width: 137px;
    height: 16px;
    margin-right: 7px;
    background-color: #13161a;
    border-radius: 7px;
  }

  .lobby__table-cell-statistics-container {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    background-color: #668a53;
    box-shadow: inset 0 2px 1px 0 rgba(255, 255, 255, 0.3);
    border-radius: inherit;
  }

  .lobby__table-cell-statistics-percent {
    color: rgba(255, 255, 255, 0.3);
    font-size: 16px;
    font-weight: 800;
  }

  .lobby--leader-board .lobby__table-body .lobby__table-row {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 68px;
  }

  .lobby__table-cell--player-id {
    width: 22.2%;
  }

  .lobby__table-cell--all-matches {
    width: 21%;
  }

  .lobby__table-cell--won-matches {
    width: 17%;
  }

  .lobby__table-cell--statistics {
    display: flex;
    align-items: center;
    width: 39.8%;
  }

  .lobby--leader-board .lobby__table-row {
    padding-left: 68px;
  }

  .lobby--leader-board .lobby__table-head .lobby__table-row {
    background-color: rgba(3, 3, 3, 0.2);
  }

  .lobby--leader-board .lobby__table-body .lobby__table-row:nth-child(2n + 1) {
    background-color: transparent;
  }

  .lobby--leader-board .lobby__table-body .lobby__table-row:nth-child(2n) {
    background-color: rgba(3, 3, 3, 0.2);
  }

  .lobby__table-body .lobby__table-cell-element--user-id {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 9px;
    padding-left: 26px;
    background-color: #181b21;
    border-radius: 15px 5px 5px 15px;
  }

  .lobby__table-body .lobby__table-cell-element--user-id::before {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translate(50%, -50%);
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #175160;
    background-image: url("../../assets/img/user-icon-dark.svg");
    background-size: 12px auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .lobby__table-body .lobby__table-row .gold .lobby__table-cell-element--user-id::before {
    background-color: #ffdc4a;
  }

  .lobby__table-body .lobby__table-row .silver .lobby__table-cell-element--user-id::before {
    background-color: #b7b7b7;
  }

  .lobby__table-body .lobby__table-row .bronze .lobby__table-cell-element--user-id::before {
    background-color: #cc8952;
  }

  .lobby--leader-board .lobby__table-body .lobby__table-cell--all-matches {
    color: rgba(255, 255, 255, 0.3)
  }

  .lobby--leader-board .lobby__table-body .lobby__table-cell--won-matches {
    color: rgba(99, 137, 78, 0.9);
  }

  .leader-board__create {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 66px;
    height: 569px;
    background-image: url("../../assets/img/leader-board-create-card.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .leader-board__create-table {
    width: 248px;
    min-height: 63px;
    border: none;
    border-radius: 15px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
    background-color: #63894e;
    color: rgba(var(--blot-white), 1);
    font-size: 28px;
    font-weight: 700;
    //user-select: none;
  }

  .leader-board__create-table {
    cursor: pointer;
  }

  .lobby--leader-board .lobby__table-body .lobby__table-cell--player-id {
    padding-left: 11px;
  }

  .lobby--open-belote .create-table {
    background-color: #4a5c6e;
    background-image: url("../../assets/img/create-open-table-background.png");
    background-size: cover;
  }

  .how-to-play {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
    justify-content: center;
    align-items: center;
  }

  .how-to-play--active {
    display: flex;
  }

  .how-to-play__container {
    position: relative;
    overflow: auto;
    width: 43%;
    min-width: 400px;
    max-height: 80vh;
    padding-top: 24px;
    padding-right: 44px;
    padding-bottom: 70px;
    padding-left: 44px;
    border: 2px solid #50b9d4;
    border-radius: 14px;
    background-color: #132b33;
  }

  .how-to-play__title {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
    font-size: 28px;
    text-align: center;
  }

  .how-to-play__text {
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.8);
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2;
  }

  .how-to-play__close-button {
    position: absolute;
    top: 17px;
    right: 17px;
  }

  .history {
    position: absolute;
    top: 55px;
    right: 0;
    bottom: 0;
    left: 0;
    //background-color: #132b33;
    z-index: 999;
    transform: translateX(-440%);
    transition: transform 0.2s;
  }

  .history--active {
    transform: translateX(0);
  }

  .history__columns {
    display: flex;
    height: 100%;
  }

  .history__column--tables {
    overflow-y: auto;
    width: calc(100% - 600px);
    max-height: 100%;
    padding-top: 20px;
    font-size: 16px;
    font-weight: bold;
  }

  .history__row:not(:last-child) {
    margin-bottom: 15px;
  }

  .history__column--video {
    overflow-y: auto;
    width:100% ;
    height: 100%;
    padding-left: 4px;
    padding-right: 6px;
  }

  .history__row-date {
    text-align: center;
  }

  .history__row-date-text {
    display: inline-block;
    padding-top: 7px;
    padding-right: 12px;
    padding-bottom: 7px;
    padding-left: 12px;
    border-radius: 17px;
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.7);
  }

  .history__table-row {
    display: flex;
  }

  .history__table-row {
    padding-left: 24px;
    padding-right: 6px;
  }

  .history__table-row--header {
    color: rgba(255, 255, 255, 0.7);
  }

  .history__table-row--header .history__table-cell {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .history__table-cell {
    display: flex;
    align-items: center;
  }

  .history__table-cell--game-type {
    width: calc(20% - 20px);
  }

  .history__table-cell--status {
    width: calc(16% - 20px);
  }

  .history__table-cell--game-start {
    width: calc(18% - 20px);
  }

  .history__table-cell--score {
    width: calc(16% - 20px);
  }

  .history__table-cell--type {
    width: calc(30% - 20px);
  }

  .history__table-cell--button {
    width: 100px;
  }

  .history__table-rows--body {
    color: rgba(255, 255, 255, 0.7);
  }

  .history__table-rows--body .history__table-cell--game-type {
    color: rgba(255, 255, 255, 0.6);
  }

  .history__table-rows--body .history__table-cell--status.history__table-cell-status--win {
    color: #63894e;
  }

  .history__table-rows--body .history__table-cell--status.history__table-cell-status--lose {
    color: rgba(173, 146, 83, 1);
  }

  .history__game-info-row.history__game-info-row--special.history__game-info-cell-with-arrow--close .history__game-info-cell--with-arrow:after {
    background-image: url("../../assets/img/arrow_down.svg");
  }

  .history__game-info-row.history__game-info-row--special.history__game-info-cell-with-arrow--close .history__game-info-row-pseudo-row.history__game-info-row-pseudo-row--right {
    display: none;
  }

  .history__table-rows .history__table-row:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.07);
  }

  .history__table-rows .history__table-row:nth-child(2n + 1) {
    background-color: rgba(62, 61, 61, 0.15);
  }

  .history__table-row {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .history__table-button-more {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    min-height: 35px;
    border-radius: 5px;
    background-color: rgba(23, 81, 96, 0.7);
    color: rgba(var(--blot-white), 1);
  }

  .history__table-button-more:hover {
    cursor: pointer;
  }

  .history__table-row.history__table-row--active:nth-child(n) {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.95);
  }

  .history__table-row.history__table-row--active:nth-child(n) .history__table-cell--game-type {
    color: rgba(255, 255, 255, 0.95);
  }

  .history__table-row.history__table-row--active:nth-child(n) .history__table-button-more {
    visibility: hidden;
  }

  .history__table-row.history__table-row--active:nth-child(n) .type-item {
    opacity: 0.6;
  }

  .history__player-screen {
    position: relative;
    width: 100%;
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .history__player-screen::before {
    content: "";
    display: block;
    padding-top: 58.2%;
  }

  .history__player-tab {
    display: flex;
    justify-content: space-between;
    min-height: 42px;
    background-color: rgba(255, 255, 255, 0.03);
  }

  .history__player-tab-cell {
    display: flex;
    align-items: stretch;
  }

  .history__player-tab-cell--rewind {
    min-width: 182px;
  }

  .history__player-button {
    width: 58px;
    background-repeat: no-repeat;
    background-color: transparent;
    border: 0;
  }

  .history__player-button:hover {
    cursor: pointer;
  }

  .history__player-button--rewind {
    width: 62px;
    background-size: 21px 14px;
  }

  .history__player-button--inactive {
    opacity: 20%;
    cursor: default !important;
  }

  .history__player-button--rewind-prev {
    background-image: url("../../assets/img/rewind-prev.svg");
    background-position: right 20px center;
  }

  .history__player-button--rewind-next {
    background-image: url("../../assets/img/rewind-next.svg");
    background-position: left 20px center;
  }

  .history__player-button--play {
    background-image: url("../../assets/img/rewind-play.svg");
    background-size: 17px 23px;
    background-position: center center;
  }

  .history__player-button--rewind-prev-item {
    background-image: url("../../assets/img/prev-item.svg");
    background-position: right 20px center;
  }

  .history__player-button--rewind-next-item {
    background-image: url("../../assets/img/next-item.svg");
    background-position: right 20px center;
  }

  .history__player-tab-time {
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .history__player-tab-button--full-screen {
    width: 55px;
    border: 0;
    background-color: transparent;
    background-image: url("../../assets/mobile/img/full_screen.svg");
    background-position: center center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
  }

  .history__player-tab-button--full-screen:hover {
    cursor: pointer;
  }

  .history__game-info-row {
    display: flex;
    justify-content: space-between;
    padding-right: 17px;
    padding-left: 17px;
  }

  .history__game-info-row:nth-child(2n) {
    background-color: #16191e;
  }

  .history__game-info-row:nth-child(2n + 1) {
    background-color: #152026;
  }

  .history__game-info-cell {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .history__game-info-cell:first-child {
    color: rgba(255, 255, 255, 0.35);
  }

  .history__game-info-cell:last-child {
    color: rgba(255, 255, 255, 0.6);
  }

  .history__game-info-cell-opacity-eight {
    color: rgba(255, 255, 255, 0.8);
  }

  .history__game-info-cell--green:nth-child(n) {
    color: #63894e;
  }

  .history__game-info-cell--orange:nth-child(n) {
    color: rgba(173, 146, 83, 0.6);
  }

  .history__game-info-cell--with-arrow::after {
    content: "";
    display: inline-block;
    width: 19px;
    height: 10px;
    margin-left: 7px;
    background-image: url("../../assets/img/arrow_up.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .history__game-info-cell--bold {
    font-weight: bold;
  }

  .history__game-info-row--special {
    display: flex;
    flex-direction: column;
  }

  .history__game-info-row-pseudo-row {
    display: flex;
    justify-content: space-between;
  }

  .history__game-info-row-pseudo-row--right {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .history__game-spec-row {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .history__game-spec-row--header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .history__game-spec-row {
    display: flex;
    width: 70%;
    padding-right: 20px;
  }

  .history__game-spec-cell {
    width: calc(100% / 3);
    color: rgba(255, 255, 255, 0.3);
  }

  .history__game-spec-cell--status {
    text-align: right;
  }

  .special-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    transition: transform 0.2s;
    z-index: 1000;
  }

  .special-popup--active {
    transform: translateY(0);
  }

  .special-popup__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5)
  }

  .special-popup__container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #50b9d4;
    border-radius: 25px;
    min-width: 320px;
    min-height: 140px;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 18px;
    font-weight: bold;
    background-image: url('../../assets/img/blot-field/wrapper-cyan-sepia.jpg');
  }

  .special-popup__container-button {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
    border: 0;
    background-image: url("../../assets/mobile/img/blot-field/close.svg");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .special-popup__container-button:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    html {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }
  }

  @media (min-width: 1024px) and (max-width: 1366px),
  (min-width: 1024px) and (max-height: 768px) {
    .wrapper--slide-1 {
      background-image: linear-gradient(180deg, #111b1f 0, #111b1f 219px, transparent 219px, transparent 219px, transparent);
      background-size: auto 225px;
      background-position: top right;
      background-repeat: no-repeat;
    }

    .lobby__table {
      overflow-y: auto;
      height: calc(100vh - 183px);
    }

    .lobby {
      height: calc(100vh - 189px)
    }

    .banner {
      height: 225px;
      width: calc(100% - 279px);
    }

    .lobby__left {
      width: calc(100% - 279px);
      flex-grow: 0;
    }

    .lobby__menu {
      width: calc(100% - 279px);
    }

    .lobby__right {
      position: fixed;
      top: 60px;
      left: calc(100vw - 285px);
      z-index: 100;
      height: calc(100vh - 66px);
    }

    .lobby__table-filter-button {
      position: fixed;
      top: auto;
      bottom: 15px;
      left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      height: 59px;
      margin: 0;
      padding: 0;
      border-radius: 50%;
      background-color: #175160;
      font-size: 0;
    }

    .lobby__table-filter-button::before {
      position: static;
      width: 20px;
      height: 23px;
    }

    .lobby__menu {
      margin-top: -42px;
      font-size: 18px;
    }

    .lobby__menu-item-tab {
      padding-bottom: 20px;
    }

    .lobby__menu-list {
      min-width: 712px;
    }

    .lobby__table-cell--bet {
      width: 11%;
    }

    .lobby__table-cell--player-first {
      width: 12%;
    }

    .lobby__table-cell--score {
      width: 13%;
    }

    .lobby__table-cell--player-two {
      width: 16%;
    }

    .lobby__table-cell--status {
      width: 13%;
    }

    .lobby__table-cell--scores {
      width: 12%;
    }

    .lobby__table-cell--timer {
      width: 8%;
    }

    .lobby__table-cell--type {
      width: 15%;
    }

    .banner__list::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -85px;
      bottom: 0;
      width: 167px;
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0, rgb(14, 27, 31), transparent);
    }

    .fixed-banner__list::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -85px;
      bottom: 0;
      width: 167px;
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0, rgb(14, 27, 31), transparent);
    }

    .lobby--spin .lobby__right {
      width: 275px;
    }

    .lobby--spin .lobby__left {
      width: calc(100% - 285px);
    }
  }

  // banners appearance
  .banner__item {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    opacity: 0;
    transition: 0.2s;
  }

  .banner__item.active {
    opacity: 1;
  }

  .banner__item-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner__item-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner__item--0 {
    background-color: #06151a;
  }

  .banner__item--1 {
    background-color: #06151a;
  }

  .banner__item--2 {
    background-color: #0a1d23;
  }

  .banner__item--3 {
    background-color: #0a1d23;
  }

  .banner__item--4 {
    background-color: #0a1d23;
  }

  .create-table--game-field {
    position: relative;
    border: none;
  }

  .create-table--game-field .create-table__label-checkbox {
    justify-content: flex-start;
  }

  .create-table--game-field .create-table__label-checkbox-text {
    margin-right: 60px;
  }

  .create-table--game-field .create-table__row-three-two-cell {
    display: flex;
  }

  .create-table--game-field .create-table__row--amount {
    width: 44%;
    padding-right: 8px;
  }

  .create-table--game-field .create-table__row--points-time {
    width: 56%;
  }

  .create-table--game-field .create-table__row--amount .create-table__input-label::before {
    right: 15px;
  }

  .create-table--game-field .create-table__row--radio-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 17px;
  }

  .create-table--game-field .create-table__label-radio {
    width: 100%;
  }

  .create-table--game-field .create-table__row-radio-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .create-table--game-field .create-table__row-radio-buttons .create-table__row--for-margin {
    width: calc(50% - 50px);
  }

  .create-table--game-field .create-table__row-radio-buttons .create-table__label-checkbox {
    justify-content: space-between;
  }

  .create-table--game-field .create-table__main-button {
    width: 260px;
    margin: 0 auto;
  }

  .create-table--game-field .create-table__main-button {
    height: 35px;
  }


  .field-main__create-table-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 6px;
  }

  .field-main__create-table-container-pseudo {
    width: 90%;
    max-width: 578px;
    height: auto;
    overflow: auto;
  }

  .popup__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    background-image: url(../../assets/img/close-thin.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/img/loading-background.jpg);
    background-size: cover;
  }

  .loading__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading__percent {
    margin-top: 22px;
    position: relative;
    width: 203px;
    height: 24px;
    border-radius: 13px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .loading__percent-container {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: flex;
    align-items: stretch;
  }

  .loading__percent-container-load {
    width: 100%;
    border-radius: 10px;
    box-shadow: inset 0 2px 1px 0 rgba(255, 255, 255, 0.3);
    background-color: #50b9d4;
    transition: width 0.2s;
  }

  .logo-by {
    position: absolute;
    left: 50%;
    bottom: 18px;
    transform: translateX(-50%);
  }

  .new-error-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6000;
  }

  .new-error {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 586px;
    min-height: 328px;
    background-color: #132b33;
    border: 2px solid #50b9d4;
    border-radius: 25px;
  }

  .new-error__container::before {
    content: "";
    display: block;
    width: 84px;
    height: 74px;
    margin-bottom: 31px;
    background-image: url("../../assets/img/error-icon.svg");
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .popup__close--thin {
    top: 24px;
    right: 24px;
    background-image: url("../../assets/img/close-thin.svg");
  }

  .new-error__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .new-error__title {
    font-size: 28px;
    font-weight: 700;
  }

  .new-error__description {
    margin-top: 30px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 22px;
    font-weight: 700;
  }

  .popup__close {
    top: 18px;
    right: 24px;
  }

  @keyframes rotate-animation {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .scale-init {
    display: none;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    animation-fill-mode: forwards;
  }

  .scale-init--animation {
    animation-name: rotate-animation;
    animation-duration: 0.3s;
  }

  .scale-init--active {
    display: flex;
    transform: scale(1);
  }

  .disconnect-timer {
    position: absolute;
    width: 109px;
    height: 109px;
    background-color: rgba(41, 86, 97, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
    font-weight: 700;
  }

  .blot-field__user-icon {
    font-size: 15px;
  }

  .score__game-rule-list .lobby__table-cell-to {
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .history__player-screen--special-size {

    .blot-field {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;

      &::before {
        width: 65px;
        height: 77px;
      }
    }

    .opponent-cards-container {
      .card-field {
        width: 81px;
        height: 112.5px;
        border-radius: 5px;

        &::before,
        &::after {
          border-radius: 5px;
        }
      }
    }

    .card-field {
      width: 81px;
      height: 112.5px;
      border-radius: 5px;

      &::before,
      &::after {
        border-radius: 5px;
      }
    }

    .blot-field__cards-score {
      width: 100%;
      min-height: auto;
      max-width: 1200px;
      height: 200px;
      flex-grow: 1;
    }

    .blot-field__user {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      width: 72.5%;
      min-height: 50px;
      height: 50px;
    }

    .blot-field__user--me {
      background-position: center top;

      .blot-field__user-icon {
        bottom: calc(100% - 150px);
      }

      .blot-field__user-cards {
        margin-bottom: -72px;
      }
    }

    .blot-field__user--you {
      background-position: center bottom;
    }

    .blot-field__user-icon {
      top: calc(100% - 150px);
      right: auto;
      left: 30px;
    }

    .blot-field__user-icon-container {
      width: 65px;
      height: 65px;
    }

    .blot-field__user-id {
      margin-bottom: 5px;
    }

    .blot-field__user--me .blot-field__user-id {
      margin-top: 5px;
    }

    .blot-field__user-icon-btn-message-add {
      width: 17px;
      height: 17px;
    }

    .all-cards .card-field {
      border-radius: 5px;

      &::before,
      &::after {
        border-radius: 5px;
      }
    }

    .blot-filed__user-icon-img {
        width: 55px;
        height: 55px;
    }

    .blot-field__user--you {
      z-index: 200;
      .blot-field__user-icon--trump::before,
      .blot-field__user-icon--trump::after {
        top: calc(100% - 38px);
        bottom: auto;
      }
    }

    .blot-field__user-icon--trump::before {
      border-width: 2px;
    }

    .blot-field__user-icon--trump::before,
    .blot-field__user-icon--trump::after {
      left: calc(100% - 107px);
      bottom: calc(100% - 84px);
      width: 45px;
      height: 45px;
    }

    .blot-field__user-icon--trump::after {
      background-size: 20px 20px;
    }

    .blot-field__cards,
    .blot-field__score {
      border: none;
    }

    .blot-field__score {
      padding-top: 33px;
      padding-bottom: 33px;
    }

    .score-text {
      font-size: 14px;
    }

    .score__key,
    .score__value {
      font-size: 11px;
    }

    .blot-field__say-terz {
      font-size: 14px;
    }

    .blot-field__user-icon-message-add-popup {
      background-image: none;
      background-color: #132b33;
      border-radius: 15px;
      right: 0;
      transform: translateX(100%);
    }

    .disconnect-timer {
      width: 65px;
      height: 65px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1440px) {
    .lobby--tournaments .lobby__table-body {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1366px) {
    // header
    .header {
      font-size: 14px;
    }

    .lobby {
      font-size: 14px;
    }

    .arrow-toggle {
      padding-left: 15px;
    }

    .arrow-toggle::before {
      width: 10px;
      height: 5.625px;
    }

    // Lobby classic
    .lobby--tournaments .lobby__table-body {
      font-size: 12px;
    }

    .lobby--classic .lobby__table-cell--scores {
      display: none !important;
    }

    .lobby--classic .lobby__table-cell--status {
      display: none !important;
    }

    .lobby--classic .lobby__table-cell--type {
      width: 30%;
    }

    .lobby--classic .lobby__table-cell--bet {
      width: 25%;
    }

    // Lobby open
    .lobby--open-belote .lobby__table-cell--scores {
      display: none !important;
    }

    .lobby--open-belote .lobby__table-cell--status {
      display: none !important;
    }

    .lobby--open-belote .lobby__table-cell--type {
      width: 30%;
    }

    .lobby--open-belote .lobby__table-cell--bet {
      width: 25%;
    }

    // Lobby spin
    .lobby--spin .lobby__table-cell--scores {
      display: none !important;
    }

    .lobby--spin .lobby__table-cell--type {
      width: 36%;
    }

    // Lobby tournaments
    .lobby--tournaments .lobby__table-cell--status {
      display: none !important;
    }

    .lobby--tournaments .lobby__table-cell--type {
      width: 38%;
    }

    .history__column--tables {
      font-size: 12px;
    }

    .history__table-rows .lobby__table-cell--type {
      width: calc(26% - 10px);
    }

    .history__column--video {
      font-size: 12px;
    }

    .history__table-button-more {
      width: 75px;
    }

    .history__table-cell--status {
      width: calc(16% - 5px);
    }

    .history__table-cell--game-start {
      width: calc(18% - 25px)
    }

    .history__table-cell--button {
      width: 75px;
    }
  }


  @media (min-width: 1366px) {
    .special-join-button {
      display: none;
    }
  }

  @media (max-width: 1366px) {
    .lobby--tournaments .lobby__table-row {
      position: relative;
    }

    .special-join-button {
      position: absolute;
      display: flex;
      right: 17px;
      top: 50%;
      transform: translateY(-50%);
      padding: 5px 8px;
      justify-content: center;
      min-width: 75px;
      border-radius: 6px;
      background-color: #63894e;
      color: rgba(var(--blot-white), 1);
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      border: 0;
    }

    .special-join-button.blur {
      background-color: rgba(22, 25, 30, 0.6);
    }
  }

  .tournaments-info__row--players {
    position: relative;
  }

  .knockout.knockout--tournaments {
    position: absolute;
    bottom: calc(100% + 10px);
    top: auto;
    left: -20px;
    right: -20px;
    height: 350px;
    width: auto;
    padding-top: 26px;
    background: #1b1f25;
    border: 1px solid rgba(255, 255, 255, 0.4)
  }

  .knockout.knockout--tournaments .knockout__close-btn {
    width: 16px;
    height: 16px;
    background-image: url('../../assets/img/icon-close.svg')
  }

  .knockout.knockout--tournaments {
    display: none;
  }

  .knockout.knockout--tournaments.active {
    display: block;
  }

  .knockout.knockout--tournaments .knockout-header__btn:first-of-type {
    margin-right: 25px;
  }

  .knockout.knockout--tournaments .player-condition__list {
    padding-bottom: 20px;
  }
}
