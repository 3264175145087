.prizepool {
  color: var(--blot-white-05);
  font-size: 1.2rem;
  font-weight: bold;
}

.prizepool__title {
  margin-bottom: 10px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 1.1rem;
  color: var(--blot-white-07);
}

.prizepool__row {
  display: flex;
  padding-top: 10px;
  padding-right: 33px;
  padding-bottom: 10px;
  padding-left: 11px;
  background-color: var(--blot-black-01);

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.prizepool__cell--position {
  width: 42%;
  color: var(--blot-white-07);
}

.prizepool__cell--percent {
  width: 27%;
}

.prizepool__cell--amount {
  width: 31%;
  text-align: right;
}
