@media (min-width: 1024px) {
  .history__player--full-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .history__player-screen {
      position: absolute;
    }

    .blot-field {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(100vh - 97px);
    }

    .history__player-tab {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
     // background-color: rgb(19, 43, 51);
    }
  }
}
