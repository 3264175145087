.flag-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home {
    width: 100%;
}

.menu {
    background-color: #085265 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    margin: 0 !important;
}

.flex {
    display: flex;
    min-height: 100vh;
}

.right-side {
    padding: 10px 20px;
    flex-basis: 84vw;
    position: relative;
    left: 205px;
    min-width: 80vw;
}

.right-side label, .right-side h2, .right-side .label {
    color: rgba(255, 255, 255, 0.93)
}

.right-side.home {
    background-image: url("./img/banner-1@2x-transparent.png");
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    min-width: 90vw;
    padding: 10px 130px 10px 20px;
}

a {
    color: black !important;
    text-decoration: none;
    display: block;
    padding: 9px;
}

.menu a {
    color: white !important;
}

 a.active {
    color: #303132;
    text-decoration: none;
    /*background-color: #9a9a9a7d !important;*/
    background-color:  rgba(11, 57, 68, 0.49) !important;

}
a:hover{
    background-color: rgba(11, 57, 68, 0.49) !important;
}

.items {
    display: flex;
    justify-content: space-between;
}

.create-user.btn {
    height: 38px;
}

.pointer {
    cursor: pointer;
}

.ui.table thead tr:first-child > th:first-child {
    width: 50px!important;
}

.ui.vertical.menu {
    position: fixed;
    width: 15rem;
    height: 100vh;
    z-index: 15;
}

.input {
    margin-right: 12px;
}

.input-box, .check-box, .select-blackList {
    padding: 13px;
}

.add-own, .remove-own {
    padding: 8px 12px;
    color: slategray;
    cursor: pointer;
}

i.add-own, i.remove-own{
    color: #21ba45;
    display: inline-block!important;
    margin: 0 0 0 57px !important;
    width: 39px!important;
    height: 35px!important;
    position: relative;
    left: 88px;
}

i.remove-own {
    color: #c10a0a!important;
}
.inline-flex{
    display: inline-flex;
}

.select-blackList .default.text {
    color: black !important;
}

.none {
    display: none
}

.input-box, .acl-edit-res {
    display: flex;
}


.error-mes {
    display: block;
    font-size: 10px;
    text-indent: 12px;
    color: red;
}

.req-mess {
    display: block;
    font-size: 14px;
    text-indent: 12px;
    color: #6aff1b;
    margin: 12px;
    text-align: center;
    font-weight: 800;
    height: 20px;
}

.req-mess.err {
    color: red
}

.modal.req-mess.err {
    color: red !important;
    text-align: center !important;
}

.modal.req-mess {
    text-align: center !important;
}

.ui.table td {
    padding: .2em .7em !important;
}

tr {
    padding: 8px 2px !important;
}

.filter-btn {
    padding: 9px !important;
    vertical-align: middle !important;
}

.filter-div {
    margin: 10px 0 0 10px;
}
.filter-div.game_ {
   display: flex;
   margin: 20px 0 0;
}
.page-title {
    display: flex;
    justify-content: space-between;
}

.page-title h2 {
    display: block;
    flex-basis: 40%
}

.accordion-own {
    display: flex !important;
    justify-content: space-between !important;
}

.margin-l {
    margin-left: 8px !important
}
.margin-l-24 {
    margin-left: 24px !important
}
.margin-l-20 {
    margin-left: 20px !important
}

.margin-r {
    margin-right: 12px
}
.margin-32{
    margin: 32px 0;
}
.margin-top-20{
    margin: 20px 0 0;
}

.margin-l.btn {
    width: 70%;
}

.content.own {
    display: flex !important;
    justify-content: space-between !important;
    padding: 9px;
}

.flex-b {
    flex-basis: 80%;
}

/* AutoComplete  CSS*/
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.App-loader-own {
    min-height: 100vh;
    background-color: #adacac !important;
}

.user-list {
    display: flex;
}

.default.text {
    color: black !important;
}

.log-out-a {
    cursor: pointer;
    position: absolute !important;
    display: block !important;
    bottom: 10px;
    width: 100%;
}

.ui.vertical.menu .dropdown.item:not(.upward) .menu {
    top: 14px !important;
    position: relative;
    left: 0 !important;
    background-color: transparent !important;
    border: none;
}

.transition.own {
    display: none !important;
}
.transition.own.own-spin-item, .menu.transition.visible.visible.item.active.own{
    display: block !important;
}
.transition.own.own-tournament-item{
    display: block !important;
}

.ui.scrolling.modal.edit-or-copy {
    width: auto !important;
}

.ui.scrolling.modal.edit-or-copy .template-name.abs {
    right: 124px;
}

.visible.req-mess.err {
    position: absolute;
    top: 40%;
}

.ui.sortable.table thead th {
    cursor: pointer;
    white-space: pre-wrap !important;
}

.color-red {
    color: #d43c3c
}

.ui.small.table.tournament-table {
    font-size: 0.7em !important;
}

.ui.table.tournament-table td {
    padding: .5em .35em !important;
    text-align: inherit;
}

.ui.button.belote {
    background: #085265 !important;
    color: rgb(255, 255, 255) !important;
}

.ui.button.belote-green {
    background: #63894e !important;
    color: rgb(255, 255, 255) !important;
}

.belote-logo {
    width: 92px;
    height: 100px;
    background-image: url("./img/logo-field.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: calc(27% - 50px);
    left: calc(50% - 50px);
}

.ui.table {
    background: rgba(97, 156, 171, 0.58) !important;
    color: rgba(255, 255, 255, 0.87) !important;
}

.ui.segment {
    background: #4f757e !important;
}

.ui.segment.login {
    background: #fff !important;
}

.ui.styled.accordion, .ui.styled.accordion .accordion {
    background: #236576 !important;
}

.ui.positive.button, .ui.positive.buttons .button {
    background-color: #63894e !important;
}

td h5 {
    color: #94d86e !important;
}

.ui.selection.dropdown {
    min-height: 0.51428571em !important;
    padding: .6666em 2.1em .6666em .55em !important;
    margin: 2px 0 0;
}
.blurring.dimmable > .dimmer, .ui.dimmer{
    background: rgba(3, 46, 58, 0.8) !important;
}

.ui.black.button, .ui.black.buttons .button {
    background: #0b3944 !important;
}

.ui.modal.transition.visible.active .header,
.ui.modal.transition.visible.active .content,
.ui.modal.transition.visible.active .actions {
    background: rgba(103, 142, 152, 0.46) !important
}

.ui.vertical.menu .item, .ui.menu .active.item, .ui.menu .ui.dropdown .menu>.item.own{
    color: white!important;
}
.ui.vertical.menu .item:hover{
    color: white!important;
    background-color: rgba(11, 57, 68, 0.49) !important;
}
.menu.transition.visible.own > a.active.item.own,
.menu.transition.own > a.active.item.own,
.ui.menu .ui.dropdown .menu.own>.active.item{
    background-color: rgba(11, 57, 68, 0.49) !important;
    font-weight: 400!important;
    /*color: rgb(0, 39, 50)!important;*/
    color: white!important;
}
.ui.menu .ui.dropdown .menu.own>.item:hover {
    background-color: rgba(11, 57, 68, 0.49) !important;
    color: white!important;
}
.ui.menu .ui.dropdown .menu>.item:hover {
    background-color: rgba(11, 57, 68, 0.49) !important;
    color: white!important;
}
.ui.dropdown .menu>.item{
    color: white!important;
}
.ui.dropdown .menu>.item.selected{
    color: rgba(0,0,0,.95)!important;
}
i.icon.settings:before {
   color: white;
}
.ui.label.label{
    color: rgb(0, 39, 50)!important
}
a.id-hover:hover{
    color: #a6e981!important
}
.table-and-pg{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.title-tab-table{
    margin:3px;
    text-align: center;
    }
.pagi-own.menu{
    margin: auto!important;
}
.registrationDate-manual.date-flex{
    margin-left: 36px!important;
}
.p-left{
    padding-left: 27px!important;
}
.p-left-39{
    padding-left: 39px!important;
}
.p-left-16{
    padding-left: 16px!important;
}
.p-top-0{
    padding-top: 0px!important;
}
.ui.vertical.menu .item.dropdown:hover {
    color: white!important;
    /*background-color: rgb(61, 120, 134) !important;*/
}
.menu.transition.visible.own{
    width: 200px!important;
    left:-1px!important;
}
.ui.vertical.menu .dropdown.item:not(.upward) .menu {
    top: 14px !important;
    position: relative;
    left: -16px !important;
    background-color: #3c7885 !important;
    border: none;
    width: 208px!important;
    line-height: 1.3!important;
}

.ui.menu .ui.dropdown .menu.active.own>.item:hover {
    color: white!important;
    background-color: rgba(11, 57, 68, 0.49) !important;
}
.ui.menu .ui.dropdown .menu.visible.own>.active.item{
    color: white!important;
    background-color: rgba(11, 57, 68, 0.49) !important;
    line-height: 1.3!important;
    left: -2px!important;
    width: 212px!important;
}

.ui.menu .ui.dropdown.item .menu .item:not(.filtered) {
    display: block;
    line-height: 1.3!important;
    left: -2px!important;
    width: 212px!important;
    text-indent: 17px;
}

.ui.vertical.menu .active.item {
    background: rgb(9, 70, 84)!important;
    }

.ui.vertical.menu .active.dropdown.item{
    background-color: rgba(11, 57, 68, 0.49) !important;
}
.ui.vertical.menu .active.item.not-active{
    background-color: transparent!important;
}

.ui.vertical.menu .item{
    width: 209px!important;
}
.lighted-yes{
    background-color: rgba(193, 255, 66, 0.29);
}
.dropdown.icon.clear{
    display: block!important;
}
.date-flex.v-align.black-own, .black-own{
    color: rgb(64, 64, 64)
}
.own-confirm .actions .ui.button{
    background: #0b3944 !important;
    color: white!important;
}
.own-confirm .actions .ui.button.primary {
    background:#63894e !important;
    color: white!important;
}
.select-wrapper {
        width: 450px!important;
}
.user-timezone{
    /*position: absolute;*/
    /*color: #69c935;*/
    /*z-index: 100;*/
    /*right: 30px;*/
    /*top: 25px;*/
    /*font-size: 14px;*/
    /*font-family: monospace*/
    position: relative;
    color: #69c935;
    font-size: 14px;
    font-family: monospace;
}
.user-timezone .name{
    color:white;
    font-size: 13px;
    text-align: end;
}
.action-buttons{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 17px;
}
.profile-content .user-timezone,
.right-side.home .user-timezone,
.player-content .user-timezone,
.player-info-content .user-timezone{
    position: absolute!important;
    right: 30px!important;
    top: 25px!important;
}
.right-side.home .user-timezone{
    right: 133px!important;
    top: 24px!important
}
.tr-spin-bg-color{
    background-color:rgba(148, 216, 110, 0.73)
}

.loader.db_loader{
    margin: 15% 0 0!important;
    color: #e6eef0;
}
.active.tab{
    min-height: 400px!important;;
}
.active.tab h5 {
    font-size: 18px!important;
}
.basic-single{
width: 350px!important;

}
.basic-single .select:hover{
    border-color: transparent!important;
    outline: none!important;
}
.basic-single .select {
    box-shadow: none !important;
    outline: none !important;
}
.select {
    border-color: transparent!important;
}
.selected.item span{
    display: block!important;
}
