@media (min-width: 1024px) {
    .spinner-score {
        position: fixed;
        z-index: 101;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 588px;
        height: 123px;

        padding-top: 9px;
        padding-bottom: 9px;

        background-image: url('../../assets/img/spinner-amount.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        display: flex;
        align-items: center;
    }

    .spinner-score__list {
        list-style: none;
        margin: 0;

        padding-right: 22px;
        padding-left: 30px;

        width: 86.63%;
        height: 100%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .spinner-score__number-container-value {
        font-size: 4rem;
        font-weight: 700;
        color: #000;
        text-shadow: -1px 0 #e79f49, 0 1px #e79f49, 1px 0 #e79f49, 0 -1px #e79f49;
    }

    .spinner-score__item:nth-of-type(1) .spinner-score__number-container {
        animation-delay: 240ms;
    }

    .spinner-score__item:nth-of-type(2) .spinner-score__number-container {
        animation-delay: 200ms;
    }

    .spinner-score__item:nth-of-type(3) .spinner-score__number-container {
        animation-delay: 160ms;
    }

    .spinner-score__item:nth-of-type(4) .spinner-score__number-container {
        animation-delay: 120ms;
    }

    .spinner-score__item:nth-of-type(5) .spinner-score__number-container {
        animation-delay: 80ms;
    }

    .spinner-score__item:nth-of-type(6) .spinner-score__number-container {
        animation-delay: 40ms;
    }

    .spinner-score__item:nth-of-type(7) .spinner-score__number-container {
        animation-delay: 0ms;
    }

    .spinner-score__number-container {
        position: relative;
        top: -578px;

        animation-name: spinner-score-animation;
        animation-fill-mode: forwards;
        /*animation-timing-function: linear;*/
        animation-iteration-count: infinite;
        animation-duration: 2s;
    }

    .spinner-score__number-container-value {
        /*background: red;*/
        /*border: 1px solid transparent;*/
        display: flex;
        align-items: center;
        justify-content: center;

        height: 105px;
        /*background: red;*/
    }

    .spinner-score-animation-empty .spinner-score__number-container {
        animation-name: spinner-score-animation-empty;
        animation-iteration-count: 1;
    }

    .spinner-score__item--0 .spinner-score__number-container {
        animation-name: spinner-score-animation-0;
        animation-iteration-count: 1;
    }

    .spinner-score__item--1 .spinner-score__number-container {
        animation-name: spinner-score-animation-1;
        animation-iteration-count: 1;
    }

    .spinner-score__item--2 .spinner-score__number-container {
        animation-name: spinner-score-animation-2;
        animation-iteration-count: 1;
    }

    .spinner-score__item--3 .spinner-score__number-container {
        animation-name: spinner-score-animation-3;
        animation-iteration-count: 1;
    }

    .spinner-score__item--4 .spinner-score__number-container {
        animation-name: spinner-score-animation-4;
        animation-iteration-count: 1;
    }

    .spinner-score__item--5 .spinner-score__number-container {
        animation-name: spinner-score-animation-5;
        animation-iteration-count: 1;
    }

    .spinner-score__item--6 .spinner-score__number-container {
        animation-name: spinner-score-animation-6;
        animation-iteration-count: 1;
    }

    .spinner-score__item--7 .spinner-score__number-container {
        animation-name: spinner-score-animation-7;
        animation-iteration-count: 1;
    }

    .spinner-score__item--8 .spinner-score__number-container {
        animation-name: spinner-score-animation-8;
        animation-iteration-count: 1;
    }

    .spinner-score__item--9 .spinner-score__number-container {
        animation-name: spinner-score-animation-9;
        animation-iteration-count: 1;
    }

    @keyframes spinner-score-animation {
        0% {
            top: -578px;
        }
        100% {
            top: 578px;
        }
    }

    @keyframes spinner-score-animation-empty {
        0% {

        }
        100% {
            top: 578px;
        }
    }

    @keyframes spinner-score-animation-0 {
        0% {

        }
        100% {
            top: 473px;
        }
    }

    @keyframes spinner-score-animation-1 {
        0% {

        }
        100% {
            top: 368px;
        }
    }

    @keyframes spinner-score-animation-2 {
        0% {

        }
        100% {
            top: 263px;
        }
    }

    @keyframes spinner-score-animation-3 {
        0% {

        }
        100% {
            top: 158px;
        }
    }

    @keyframes spinner-score-animation-4 {
        0% {

        }
        100% {
            top: 53px;
        }
    }

    @keyframes spinner-score-animation-5 {
        0% {

        }
        100% {
            top: -53px;
        }
    }

    @keyframes spinner-score-animation-6 {
        0% {

        }
        100% {
            top: -158px;
        }
    }

    @keyframes spinner-score-animation-7 {
        0% {

        }
        100% {
            top: -263px;
        }
    }

    @keyframes spinner-score-animation-8 {
        0% {

        }
        100% {
            top: -368px;
        }
    }

    @keyframes spinner-score-animation-9 {
        0% {

        }
        100% {
            top: -473px;
        }
    }
}


