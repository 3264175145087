.player-info-content{
    width: 88%;
    margin: 12px 0;
}

.ui.small.table.blacklist{
    padding: 4px!important;
}

.player-info-content  .ui.table {
    padding: 8px 1px !important;
}

/*.game-info-content .bold-data{*/
/*    font-size: 13px;*/
/*}*/