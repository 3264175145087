@media (min-width: 1024px) {
  :root {
    --main-width: 0;
    --main-height: 0;
  }

  button:focus {
    outline: none;
  }

  label:hover,
  button:hover {
    cursor: pointer;
  }

  .wrapper--blot-field {
    padding-top: 55px;
  }

  .wrapper--blot-field .header__column--logo {
    display: none;
  }

  /*header buttons*/
  .header__column--tabs {
    align-self: flex-end;

  }

  .header-tabs {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
  }

  .header-tab {

  }

  .header-tab__link {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: inherit;
    text-transform: uppercase;
    text-decoration: none;
    height: 47px;
    justify-content: center;
    padding: 0 28px;
    min-width: unset;
    border-radius: 0;
    color: rgba(var(--blot-white), 0.3);
    background: none;
  }

  .header-tab__link.active {
    justify-content: space-between;
    padding: 0 18px;
    min-width: 214px;
    border-radius: 5px 5px 0 0;
    color: rgba(var(--blot-white), 0.8);
    background-color: rgba(67, 114, 125, 0.3);
    background-repeat: no-repeat;
    background-size: 15px;
    background-origin: content-box;
    background-position: right center;
  }

  //.menu {
  //  position: absolute;
  //  top: 55px;
  //  right: -7000px;
  //
  //  width: 300px;
  //
  //  background-color: rgb(19, 43, 51);
  //
  //  border-bottom-right-radius: 14px;
  //  border-bottom-left-radius: 14px;
  //
  //  padding-top: 24px;
  //  padding-bottom: 130px;
  //
  //  transition: 250ms;
  //}

  //.menu.active {
  //  right: 0;
  //}

  .menu__title {
    font-weight: 700;
    font-size: 1rem;
    color: rgba(var(--blot-white), .6);
    text-align: center;

    margin-bottom: 7px;
  }

  .menu__title.active {
    color: rgba(var(--blot-white), .8);
    font-size: 1.125rem;
  }

  .menu-flag {
    margin-bottom: 33px;
  }

  .menu-flag__list {
    list-style: none;
    /*margin: 0;*/
    margin: 0 auto;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    /*flex-wrap: wrap;*/

    width: 77.6%;
  }

  .menu-flag__item {
    padding: 4px;

    border: 2px solid transparent;
    border-radius: 3px;
  }

  .menu-flag__item.active {
    border: 2px solid rgb(138, 214, 96);
  }

  .menu-flag__btn {
    display: block;
    width: 40px;
    height: 25px;

    padding: 0;
    border: none;
    border-radius: 3px;

    outline: none;
    cursor: pointer;
  }

  .menu-flag__armenia {
    background-image: url("../../assets/mobile/img/menu/flag-armenia.svg");
  }

  .menu-flag__russia {
    background-image: url("../../assets/img/menu/flag-russia.svg");
  }

  .menu-flag__britain {
    background-image: url("../../assets/img/menu/flag-britain.svg");
  }

  .menu-avatar {
    margin-bottom: 31px;
  }

  .menu-avatar__slider {
    position: relative;

    width: 89.3%;
    margin: 0 auto;
  }

  .menu-avatar__slider-btn {
    position: absolute;
    top: 50%;
    margin-top: -8px;

    display: block;
    width: 9px;
    height: 16px;
    padding: 0;
    border: none;
    background-color: transparent;

    background-size: cover;

    outline: none;
  }

  .menu-avatar__slider-btn-prev {
    left: 0;
    background-image: url("../../assets/img/icon-prev.svg");
  }

  .menu-avatar__slider-btn-next {
    right: 0;
    background-image: url("../../assets/img/icon-next.svg");
  }

  .menu-avatar__slider-list {
    list-style: none;
    padding: 0;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    width: 95.14%;
    margin: 0 auto;
  }

  .menu-avatar__slider-item {
    width: 74px;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 3px;
    border-radius: 50%;
    border: 2px solid transparent;
  }

  .menu-avatar__slider-item.active {
    border: 2px solid rgb(138, 214, 96);
  }


  .menu-avatar__slider-item:not(:nth-child(3n)) {
    margin-right: 16px;
  }

  .menu-avatar__slider-item:nth-child(-n + 3) {
    margin-bottom: 5px;
  }

  .menu-avatar__slider-item__btn {
    display: block;
    width: 62px;
    height: 62px;

    padding: 0;
    border: none;
    border-radius: 50%;

    background-color: rgb(38, 61, 89);
    background-size: cover;

    outline: none;
    cursor: pointer;
  }

  .menu-avatar__slider-item__btn-avatar1 {
    background-image: url("../../assets/mobile/img/menu/Avatar_1.svg");
  }
  .menu-avatar__slider-item__btn-avatar2 {
    background-image: url("../../assets/mobile/img/menu/Avatar_2.svg");
  }
  .menu-avatar__slider-item__btn-avatar3 {
    background-image: url("../../assets/img/menu/Avatar_3.svg");
  }
  .menu-avatar__slider-item__btn-avatar4 {
    background-image: url("../../assets/mobile/img/menu/Avatar_4.svg");
  }
  .menu-avatar__slider-item__btn-avatar5 {
    background-image: url("../../assets/mobile/img/menu/Avatar_5.svg");
  }
  .menu-avatar__slider-item__btn-avatar6 {
    background-image: url("../../assets/img/menu/Avatar_6.svg");
  }
  .menu-avatar__slider-item__btn-avatar7 {
    background-image: url("../../assets/mobile/img/menu/Avatar_7.svg");
  }
  .menu-avatar__slider-item__btn-avatar8 {
    background-image: url("../../assets/img/menu/Avatar_8.svg");
  }
  .menu-avatar__slider-item__btn-avatar9 {
    background-image: url("../../assets/mobile/img/menu/Avatar_9.svg");
  }
  .menu-avatar__slider-item__btn-avatar10 {
    background-image: url("../../assets/img/menu/Avatar_10.svg");
  }
  .menu-avatar__slider-item__btn-avatar11 {
    background-image: url("../../assets/mobile/img/menu/Avatar_11.svg");
  }
  .menu-avatar__slider-item__btn-avatar12 {
    background-image: url("../../assets/img/menu/Avatar_12.svg");
  }
  .menu-avatar__slider-item__btn-avatar13 {
    background-image: url("../../assets/mobile/img/menu/Avatar_13.svg");
  }
  .menu-avatar__slider-item__btn-avatar14 {
    background-image: url("../../assets/mobile/img/menu/Avatar_14.svg");
  }
  .menu-avatar__slider-item__btn-avatar16 {
    background-image: url("../../assets/mobile/img/menu/Avatar_16.svg");
  }
  .menu-avatar__slider-item__btn-avatar17 {
    background-image: url("../../assets/img/menu/Avatar_17.svg");
  }
  .menu-avatar__slider-item__btn-avatar18 {
    background-image: url("../../assets/img/menu/Avatar_18.svg");
  }
  .menu-avatar__slider-item__btn-avatar19 {
    background-image: url("../../assets/img/menu/Avatar_19.svg");
  }
  .menu-avatar__slider-item__btn-avatar20 {
    background-image: url("../../assets/mobile/img/menu/Avatar_20.svg");
  }

  .menu-table {
    margin-bottom: 29px;
  }

  .menu-table__list {
    list-style: none;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 87.3%;
    margin: 0 auto;
  }

  .menu-table__item {
    padding: 4px;
    border: 2px solid transparent;
    border-radius: 10px;
  }

  .menu-table__item.active {
    border: 2px solid rgb(138, 214, 96);
  }

  .menu-table__btn {
    display: block;
    width: 71px;
    height: 50px;

    padding: 0;
    border: none;
    background-color: transparent;
    border-radius: 7px;

    background-size: cover;

    outline: none;
    cursor: pointer;
  }

  .menu-table__btn-blue {
    background-image: url("../../assets/mobile/img/menu/table-blue.png");
  }

  .menu-table__btn-yellow {
    background-image: url("../../assets/img/menu/table-yellow.png");
  }

  .menu-table__btn-green {
    background-image: url("../../assets/img/menu/table-green.png");
  }

  .menu-card__list {
    list-style: none;
    padding: 0;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 84%;
  }

  .menu-card__item {
    padding: 4px;
    border-radius: 7px;
    border: 2px solid transparent;
  }

  .menu-card__item.active {
    border: 2px solid rgb(138, 214, 96);
  }

  .menu-card__btn {
    display: block;
    width: 62px;
    height: 86px;

    padding: 0;
    border: none;

    background-color: transparent;
    background-size: cover;

    outline: none;
    cursor: pointer;
  }

  .menu-card__btn-green {
    background-image: url("../../assets/img/blot-field/card-wrapper.png");
  }

  .menu-card__btn-brown {
    background-image: url("../../assets/img/blot-field/card-wrapper-brown.png");
  }

  .menu-card__btn-pink {
    background-image: url("../../assets/img/blot-field/card-wrapper-pink.png");
  }

  .blot-field {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100vh - 55px);
    background-image: radial-gradient(transparent, rgba(0, 0, 0, 0.5)),
    url("../../assets/img/blot-field/wrapper-cyan-sepia.jpg");
  }

  .blot-field--brown {
    background-image: radial-gradient(transparent, rgba(0, 0, 0, 0.5)),
    url("../../assets/mobile/img/blot-field/wrapper-brown.png");
  }

  .blot-field--green {
    background-image: radial-gradient(transparent, rgba(0, 0, 0, 0.5)),
    url("../../assets/mobile/img/blot-field/wrapper-green.png");
  }

  .blot-field::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 131px;
    height: 153px;
    background-image: url("../../assets/mobile/img/blot-field/logo-field.png");
    background-size: 99% auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .blot-field__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .blot-field__user {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 72.5%;
    height: calc(31% - 10px);
    max-height: 320px;
    min-height: 137px;
    background-repeat: no-repeat;
  }

  /*my version*/
  /*.blot-field__user--you::before {*/
  /*    content: "";*/
  /*    display: block;*/
  /*    position: absolute;*/
  /*    top: 60px;*/
  /*    width: 100%;*/
  /*    bottom: 0;*/
  /*    border: 8px solid rgba(0, 0, 0, 0.15);*/
  /*    border-bottom: none;*/
  /*}*/

  /*.blot-field__user--me::before {*/
  /*    content: "";*/
  /*    display: block;*/
  /*    position: absolute;*/
  /*    top: 0;*/
  /*    bottom: 60px;*/
  /*    width: 100%;*/
  /*    border: 8px solid rgba(0, 0, 0, 0.15);*/
  /*    border-top: none;*/
  /*}*/

  .blot-field__user--you {
    align-items: flex-start;
    background-image: url("../../assets/img/blot-field/part-field-top.svg");
    background-position: center bottom;
  }

  .blot-field__user--me {
    align-items: flex-end;
    background-image: url("../../assets/img/blot-field/part-field-bottom.svg");
    background-position: center top;
  }

  .blot-field__cards-score {
    width: 89%;
    height: 38%;
    min-height: 250px;
    display: flex;
    justify-content: space-between;
  }

  .blot-field__user--you .blot-field__user-cards {
    margin-top: -72px;
  }

  .opponent-cards-container .card-field {
    background-image: none;
    transform-style: preserve-3d;
  }

  .opponent-cards-container .card-field::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 15px;
    transform: rotateY(-180deg);
    backface-visibility: hidden;
    background-size: contain;
  }

  /* hearts */

  .opponent-cards-container .card-field--heart-7::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-7.png")
  }

  .opponent-cards-container .card-field--heart-8::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-8.png")
  }

  .opponent-cards-container .card-field--heart-9::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-9.png")
  }

  .opponent-cards-container .card-field--heart-10::before {
    background-image: url("../../assets/img/blot-field/heart-10.png")
  }

  .opponent-cards-container .card-field--heart-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-jack.png")
  }

  .opponent-cards-container .card-field--heart-queen::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-queen.png")
  }

  .opponent-cards-container .card-field--heart-king::before {
    background-image: url("../../assets/img/blot-field/heart-king.png")
  }

  .opponent-cards-container .card-field--heart-ace::before {
    background-image: url("../../assets/img/blot-field/heart-ace.png")
  }
  /* diamonds */

  .opponent-cards-container .card-field--diamond-7::before {
    background-image: url("../../assets/img/blot-field/diamond-7.png")
  }

  .opponent-cards-container .card-field--diamond-8::before {
    background-image: url("../../assets/img/blot-field/diamond-8.png")
  }

  .opponent-cards-container .card-field--diamond-9::before {
    background-image: url("../../assets/img/blot-field/diamond-9.png")
  }

  .opponent-cards-container .card-field--diamond-10::before {
    background-image: url("../../assets/img/blot-field/diamond-10.png")
  }

  .opponent-cards-container .card-field--diamond-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/diamond-jack.png")
  }

  .opponent-cards-container .card-field--diamond-queen::before {
    background-image: url("../../assets/mobile/img/blot-field/diamond-queen.png")
  }

  .opponent-cards-container .card-field--diamond-king::before {
    background-image: url("../../assets/mobile/img/blot-field/diamond-king.png")
  }

  .opponent-cards-container .card-field--diamond-ace::before {
    background-image: url("../../assets/img/blot-field/diamond-ace.png")
  }

  /* clubs */

  .opponent-cards-container .card-field--club-7::before {
    background-image: url("../../assets/img/blot-field/club-7.png")
  }

  .opponent-cards-container .card-field--club-8::before {
    background-image: url("../../assets/mobile/img/blot-field/club-8.png")
  }

  .opponent-cards-container .card-field--club-9::before {
    background-image: url("../../assets/mobile/img/blot-field/club-9.png")
  }

  .opponent-cards-container .card-field--club-10::before {
    background-image: url("../../assets/img/blot-field/club-10.png")
  }

  .opponent-cards-container .card-field--club-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/club-jack.png")
  }

  .opponent-cards-container .card-field--club-queen::before {
    background-image: url("../../assets/img/blot-field/club-queen.png")
  }

  .opponent-cards-container .card-field--club-king::before {
    background-image: url("../../assets/mobile/img/blot-field/club-king.png")
  }

  .opponent-cards-container .card-field--club-ace::before {
    background-image: url("../../assets/img/blot-field/club-ace.png")
  }

  /* spades */

  .opponent-cards-container .card-field--spade-7::before {
    background-image: url("../../assets/img/blot-field/spade-7.png")
  }

  .opponent-cards-container .card-field--spade-8::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-8.png")
  }

  .opponent-cards-container .card-field--spade-9::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-9.png")
  }

  .opponent-cards-container .card-field--spade-10::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-10.png")
  }

  .opponent-cards-container .card-field--spade-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-jack.png")
  }

  .opponent-cards-container .card-field--spade-queen::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-queen.png")
  }

  .opponent-cards-container .card-field--spade-king::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-king.png")
  }
  .opponent-cards-container .card-field--spade-ace::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-ace.png")
  }

  .opponent-cards-container .card-field::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/img/blot-field/card-wrapper.png");
    background-size: 100% 100%;
    z-index: 1;
    backface-visibility: hidden;
  }

  .opponent-cards-container .card-field--brown::after {
    background-image: url("../../assets/img/blot-field/card-wrapper-brown.png");
  }

  .opponent-cards-container .card-field--pink::after {
    background-image: url("../../assets/img/blot-field/card-wrapper-pink.png");
  }

  .all-cards .card-field {
    background-image: none;
  }

  .all-cards .card-field::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: rotateY(-180deg);
    z-index: 1;
    border-radius: 15px;
    backface-visibility: hidden;
  }

  .all-cards .card-field::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/img/blot-field/card-wrapper.png");
    background-size: 100% 100%;
    z-index: 1;
    backface-visibility: hidden;
  }

  .all-cards .card-field::before {
    background-size: contain;
  }

  .all-cards .card-field--spade-queen::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-queen.png")
  }

  /* hearts */

  .all-cards .card-field--heart-7::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-7.png")
  }

  .all-cards .card-field--heart-8::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-8.png")
  }

  .all-cards .card-field--heart-9::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-9.png")
  }

  .all-cards .card-field--heart-10::before {
    background-image: url("../../assets/img/blot-field/heart-10.png")
  }

  .all-cards .card-field--heart-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-jack.png")
  }

  .all-cards .card-field--heart-queen::before {
    background-image: url("../../assets/mobile/img/blot-field/heart-queen.png")
  }

  .all-cards .card-field--heart-king::before {
    background-image: url("../../assets/img/blot-field/heart-king.png")
  }

  .all-cards .card-field--heart-ace::before {
    background-image: url("../../assets/img/blot-field/heart-ace.png")
  }

  /* diamonds */

  .all-cards .card-field--diamond-7::before {
    background-image: url("../../assets/img/blot-field/diamond-7.png")
  }

  .all-cards .card-field--diamond-8::before {
    background-image: url("../../assets/img/blot-field/diamond-8.png")
  }

  .all-cards .card-field--diamond-9::before {
    background-image: url("../../assets/img/blot-field/diamond-9.png")
  }

  .all-cards .card-field--diamond-10::before {
    background-image: url("../../assets/img/blot-field/diamond-10.png")
  }

  .all-cards .card-field--diamond-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/diamond-jack.png")
  }

  .all-cards .card-field--diamond-queen::before {
    background-image: url("../../assets/mobile/img/blot-field/diamond-queen.png")
  }

  .all-cards .card-field--diamond-king::before {
    background-image: url("../../assets/mobile/img/blot-field/diamond-king.png")
  }

  .all-cards .card-field--diamond-ace::before {
    background-image: url("../../assets/img/blot-field/diamond-ace.png")
  }

  /* clubs */

  .all-cards .card-field--club-7::before {
    background-image: url("../../assets/img/blot-field/club-7.png")
  }

  .all-cards .card-field--club-8::before {
    background-image: url("../../assets/mobile/img/blot-field/club-8.png")
  }

  .all-cards .card-field--club-9::before {
    background-image: url("../../assets/mobile/img/blot-field/club-9.png")
  }

  .all-cards .card-field--club-10::before {
    background-image: url("../../assets/img/blot-field/club-10.png")
  }

  .all-cards .card-field--club-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/club-jack.png")
  }

  .all-cards .card-field--club-queen::before {
    background-image: url("../../assets/img/blot-field/club-queen.png")
  }

  .all-cards .card-field--club-king::before {
    background-image: url("../../assets/mobile/img/blot-field/club-king.png")
  }

  .all-cards .card-field--club-ace::before {
    background-image: url("../../assets/img/blot-field/club-ace.png")
  }

  /* spades */

  .all-cards .card-field--spade-7::before {
    background-image: url("../../assets/img/blot-field/spade-7.png")
  }

  .all-cards .card-field--spade-8::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-8.png")
  }

  .all-cards .card-field--spade-9::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-9.png")
  }

  .all-cards .card-field--spade-10::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-10.png")
  }

  .all-cards .card-field--spade-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-jack.png")
  }

  .all-cards .card-field--spade-queen::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-queen.png")
  }

  .all-cards .card-field--spade-king::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-king.png")
  }

  .all-cards .card-field--spade-ace::before {
    background-image: url("../../assets/mobile/img/blot-field/spade-ace.png")
  }

  .all-cards .card-field--brown::after {
    background-image: url("../../assets/img/blot-field/card-wrapper-brown.png");
  }

  .all-cards .card-field--pink::after {
    background-image: url("../../assets/img/blot-field/card-wrapper-pink.png");
  }

  .blot-field__user--me .blot-field__user-cards {
    margin-bottom: -72px;
  }

  .blot-field__user-card--selectable {
    transition: transform 0.2s;
  }

  .blot-field__user--me .blot-field__user-card--selectable:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }

  .player-cards-container .card-field--selectable:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }

  .player-cards-container .card-field--active-red {
    border: 3px solid rgba(233, 105, 104, 0.85);
  }

  .player-cards-container .card-field--active-red::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(189, 32, 32, 0.1);
  }

  .player-cards-container .card-field--active {
    transform: translateY(-25px);
  }

  .player-cards-container .card-field--active:hover {
    transform: translateY(-25px);
  }

  .blot-field__user--me .blot-field__user-card--active {
    transform: translateY(-25px);
  }

  .blot-field__user--me .blot-field__user-card--active:hover {
    transform: translateY(-25px);
  }

  .blot-field__user-card--active-red .card-field {
    position: relative;
    border: 3px solid rgba(233, 105, 104, 0.85);
    overflow: hidden;
    background-color: rgba(233, 105, 104, 0.85);
  }

  .blot-field__user-card--active-red .card-field::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(189, 32, 32, 0.1);
  }

  .blot-field__user-cards-show-combination {
    position: absolute;
    bottom: calc(100% + 46px);
    left: 50%;
    padding: 0;
    border: none;
    border-radius: 18px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 232px;
    min-height: 40px;
    margin-left: -116px;
    background-color: #74ab57;
    color: rgba(254, 254, 255, 1);
    font-weight: 700;
    font-size: 16px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
  }

  .blot-field__user-cards-container--show-combination .blot-field__user-cards-show-combination {
    display: flex;
  }

  .blot-field__user-cards-show-combination::before {
    content: "";
    display: block;
    width: 28px;
    height: 21px;
    margin-right: 10px;
    background-image: url("../../assets/mobile/img/blot-field/show-combination.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
  }

  .blot-field__user-cards {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
  }

  .blot-field__user-card {
    width: 162px;
    height: 225px;
  }

  .card-field {
    width: 162px;
    height: 225px;
    border-radius: 15px;
    background-image: url("../../assets/img/blot-field/card-wrapper.png");
    transform: translate3d(0, 0, 0);
    box-shadow: -2px 0 5px 1px rgba(0, 0, 0, 0.23);
    transform-style: preserve-3d;
    background-size: contain;
  }


  .card-field--brown {
    background-image: url("../../assets/img/blot-field/card-wrapper-brown.png");
  }

  .card-field--pink {
    background-image: url("../../assets/img/blot-field/card-wrapper-pink.png");
  }

  /* hearts */

  .card-field--heart-7 {
    background-image: url("../../assets/mobile/img/blot-field/heart-7.png")
  }

  .card-field--heart-8 {
    background-image: url("../../assets/mobile/img/blot-field/heart-8.png")
  }

  .card-field--heart-9 {
    background-image: url("../../assets/mobile/img/blot-field/heart-9.png")
  }

  .card-field--heart-10 {
    background-image: url("../../assets/img/blot-field/heart-10.png")
  }

  .card-field--heart-jack {
    background-image: url("../../assets/mobile/img/blot-field/heart-jack.png")
  }

  .card-field--heart-queen {
    background-image: url("../../assets/mobile/img/blot-field/heart-queen.png")
  }

  .card-field--heart-king {
    background-image: url("../../assets/img/blot-field/heart-king.png")
  }

  .card-field--heart-ace {
    background-image: url("../../assets/img/blot-field/heart-ace.png")
  }

  /* diamonds */

  .card-field--diamond-7 {
    background-image: url("../../assets/img/blot-field/diamond-7.png")
  }

  .card-field--diamond-8 {
    background-image: url("../../assets/img/blot-field/diamond-8.png")
  }

  .card-field--diamond-9 {
    background-image: url("../../assets/img/blot-field/diamond-9.png")
  }

  .card-field--diamond-10 {
    background-image: url("../../assets/img/blot-field/diamond-10.png")
  }

  .card-field--diamond-jack {
    background-image: url("../../assets/mobile/img/blot-field/diamond-jack.png")
  }

  .card-field--diamond-queen {
    background-image: url("../../assets/mobile/img/blot-field/diamond-queen.png")
  }

  .card-field--diamond-king {
    background-image: url("../../assets/mobile/img/blot-field/diamond-king.png")
  }

  .card-field--diamond-ace {
    background-image: url("../../assets/img/blot-field/diamond-ace.png")
  }

  /* clubs */

  .card-field--club-7 {
    background-image: url("../../assets/img/blot-field/club-7.png")
  }

  .card-field--club-8 {
    background-image: url("../../assets/mobile/img/blot-field/club-8.png")
  }

  .card-field--club-9 {
    background-image: url("../../assets/mobile/img/blot-field/club-9.png")
  }

  .card-field--club-10 {
    background-image: url("../../assets/img/blot-field/club-10.png")
  }

  .card-field--club-jack {
    background-image: url("../../assets/mobile/img/blot-field/club-jack.png")
  }

  .card-field--club-queen {
    background-image: url("../../assets/img/blot-field/club-queen.png")
  }

  .card-field--club-king {
    background-image: url("../../assets/mobile/img/blot-field/club-king.png")
  }

  .card-field--club-ace {
    background-image: url("../../assets/img/blot-field/club-ace.png")
  }

  /* spades */

  .card-field--spade-7 {
    background-image: url("../../assets/img/blot-field/spade-7.png")
  }

  .card-field--spade-8 {
    background-image: url("../../assets/mobile/img/blot-field/spade-8.png")
  }

  .card-field--spade-9 {
    background-image: url("../../assets/mobile/img/blot-field/spade-9.png")
  }

  .card-field--spade-10 {
    background-image: url("../../assets/mobile/img/blot-field/spade-10.png")
  }

  .card-field--spade-jack {
    background-image: url("../../assets/mobile/img/blot-field/spade-jack.png")
  }

  .card-field--spade-queen {
    background-image: url("../../assets/mobile/img/blot-field/spade-queen.png")
  }

  .card-field--spade-king {
    background-image: url("../../assets/mobile/img/blot-field/spade-king.png")
  }

  .card-field--spade-ace {
    background-image: url("../../assets/mobile/img/blot-field/spade-ace.png")
  }

  .card-field-transition {
    transition: transform .2s;
  }

  .blot-field__user-card:not(:last-child) {
    margin-right: -119px;
  }

  .old-left-hand {
    opacity: 0;
  }

  .blot-field__user-container {
    position: relative;
  }

  .blot-field__user-icon {
    position: absolute;
    top: calc(100% - 100px);
    right: calc(100% + 60px);
    display: flex;
    flex-direction: column;
  }

  .blot-field__popup-background {
    background-image: url("../../assets/mobile/img/popup-background.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .blot-field__user-icon-container-outer {
    position: relative;
  }

  .blot-field__user-icon-btn {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;

    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;

    box-shadow: 0 3px 2px rgba(0, 0, 0, .22);
    cursor: pointer;
  }

  .blot-field__user-icon-btn-message-add {
    top: 1px;
    left: -10px;
    width: 34px;
    height: 34px;
    background-image: url("../../assets/img/icon-message-add.svg");
  }

  .blot-field__user-icon-btn-message-add.active + .blot-field__user-icon-message-add-popup {
    clip-path: circle(100%);
  }

  /*.blot-field__user-icon-message-add-popup:hover {*/
  /*    clip-path: circle(100%);*/
  /*}*/

  .blot-field__user-icon-message-add-popup {
    position: absolute;
    top: 40px;
    right: calc(-55.4%);
    z-index: 2;

    transition: .2s;
    clip-path: circle(0);

    width: 300px;
    min-height: 240px;
    padding-top: 25px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;

    background-image: url("../../assets/img/popup-background-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .blot-field__user-icon-message-add-popup-title {
    font-size: 0.75rem;
    color: rgba(var(--blot-white), .6);
    text-align: right;

    margin-bottom: 13px;
  }

  .blot-field__user-icon-message-add-popup-form {
    margin-bottom: 14px;
  }

  .blot-field__user-icon-message-add-popup-sub-title {
    font-size: 0.875rem;
    font-weight: 700;
    color: rgba(var(--blot-white), .7);

    margin-bottom: 5px;
  }

  .blot-field__user-icon-message-add-popup-form-textarea {
    resize: none;
    outline: none;
    width: 100%;
    height: 55px;
    border-radius: 5px;
    padding: 6px;
    border: none;
    background-color: rgb(37, 67, 77);
    color: rgba(var(--blot-white), 1);
  }

  .blot-field__user-icon-message-add-popup-form-textarea::placeholder {
    color: rgba(var(--blot-white), .4);
    font-size: 0.75rem;
  }

  .blot-field__user-icon-message-add-popup-color-container {
    margin-bottom: 18px;
  }

  .blot-field__user-icon-message-add-popup-color-choice-list {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
  }

  .blot-field__user-icon-message-add-popup-color-choice-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border: 2px solid transparent;
    border-radius: 50%;
  }

  .blot-field__user-icon-message-add-popup-color-choice-item:not(:last-of-type) {
    margin-right: 6px;
  }

  .blot-field__user-icon-message-add-popup-color-choice-item.active {
    border: 2px solid rgba(var(--blot-white), 1);
  }

  .blot-field__user-icon-message-add-popup-color-choice-btn {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none;
  }

  .blot-field__user-icon-message-add-popup-color-choice-btn-cancel {
    background-image: url("../../assets/mobile/img/icon-cancel.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .blot-field__user-icon-message-add-popup-color-choice-btn-green {
    background-color: rgba(138, 214, 96, 1);
  }

  .blot-field__user-icon-message-add-popup-color-choice-btn-red {
    background-color: rgba(233, 78, 77, 1);
  }

  .blot-field__user-icon-message-add-popup-color-choice-btn-yellow {
    background-color: rgba(255, 197, 54, 1);
  }

  .blot-field__user-icon-message-add-popup-color-choice-btn-blue {
    background-color: rgba(19, 114, 138, 1);
  }

  .lobby__table-cell--player-first .lobby__table-cell-element.green {
    box-shadow: inset 0 0 0 2px rgba(138, 214, 96, 1);
  }

  .lobby__table-cell--player-first .lobby__table-cell-element.red {
    box-shadow: inset 0 0 0 2px rgba(233, 78, 77, 1);
  }

  .lobby__table-cell--player-first .lobby__table-cell-element.yellow {
    box-shadow: inset 0 0 0 2px rgba(255, 197, 54, 1);
  }

  .lobby__table-cell--player-first .lobby__table-cell-element.blue {
    box-shadow: inset 0 0 0 2px rgba(19, 114, 138, 1);
  }

  .lobby__table-cell--player-two .play-view--play.green {
    box-shadow: inset 0 0 0 2px rgba(138, 214, 96, 1);
  }

  .lobby__table-cell--player-two .play-view--play.red {
    box-shadow: inset 0 0 0 2px rgba(233, 78, 77, 1);
  }

  .lobby__table-cell--player-two .play-view--play.yellow {
    box-shadow: inset 0 0 0 2px rgba(255, 197, 54, 1);
  }

  .lobby__table-cell--player-two .play-view--play.blue {
    box-shadow: inset 0 0 0 2px rgba(19, 114, 138, 1);
  }

  .blot-field__user-icon-message-add-popup-btn-container {
    display: flex;
    height: 30px;
    justify-content: space-between;
  }

  .blot-field__user-icon-message-add-popup-btn {
    border: 0;
    padding: 0;
    font-weight: 700;
    color: rgba(254, 254, 255, .8);
    font-size: 0.875rem;
    cursor: pointer;
    outline: none;

    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
    /*background-image: linear-gradient(90deg, rgb(255,255,255) 0%, rgb(0,0,0) 46%, rgb(255,255,255) 94%);*/
  }

  .blot-field__user-icon-message-add-popup-btn-cancel {
    width: 38.46%;
    background-color: rgba(33, 66, 77, 1);
    border-radius: 14px 3px 3px 14px;
    padding: 0 18px;
    text-align: right;

    background-image: url("../../assets/mobile/img/icon-close.svg");
    background-size: 11px 11px;
    background-origin: content-box;
    background-position: left center;
    background-repeat: no-repeat;
  }

  .blot-field__user-icon-message-add-popup-btn-ok {
    border-radius: 3px 14px 14px 3px;
    font-size: 1rem;
    width: 57.6%;
    background-color: rgba(116, 171, 87, 1);
  }

  .blot-field__user-icon-btn-message {
    /*position: relative;*/
    top: 50%;
    left: -25px;
    transform: translateY(-50%);

    width: 31px;
    height: 31px;
    background-image: url("../../assets/img/icon-message-notification.svg");
  }

  .blot-field__user-icon-btn-message:hover + .blot-field__user-icon-message-popup {
    clip-path: circle(100%);
  }

  .blot-field__user-icon-message-popup:hover {
    clip-path: circle(100%);
  }

  .blot-field__user-number {
    position: absolute;
    top: 50%;
    right: 3px;
    font-size: 1.12rem;
    margin-top: -11px;
    color: rgba(var(--blot-white), 1);
    font-weight: 700;
  }

  .blot-field__user-icon-message-popup {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 30px);

    min-width: 165px;
    min-height: 90px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-right: 17px;

    clip-path: circle(0);
    transition: .2s;
  }

  .blot-field__user-icon-message-name {
    color: rgba(138, 214, 96, 1);
    font-size: 1.125rem;
    font-weight: 700;
  }

  .blot-field__user-icon-message-popup-btn {
    position: absolute;
    right: 35px;
    bottom: 7px;
    padding: 0;

    cursor: pointer;

    min-width: 42px;

    color: rgba(237, 237, 237, .8);
    font-size: 0.875rem;
    font-weight: 700;
    text-align: right;

    border: none;
    background-color: transparent;
    background-image: url("../../assets/img/icon-pen.svg");
    background-size: 14px 13px;
    background-position: left center;
    background-repeat: no-repeat;
  }

  .blot-field__user-icon-btn-color {
    top: auto;
    bottom: 6px;
    width: 27px;
    height: 27px;
    display: none;
  }

  //.blot-field__user-icon-btn-color.blot-field__user-icon-btn-color-cancel {
  //    display: none;
  //}

  .blot-field__user-icon-btn-color.blot-field__user-icon-btn-color-green {
    background-color: rgba(138, 214, 96, 1);
    display: block;
  }

  .blot-field__user-icon-btn-color.blot-field__user-icon-btn-color-red {
    background-color: rgba(233, 78, 77, 1);
    display: block;
  }

  .blot-field__user-icon-btn-color.blot-field__user-icon-btn-color-yellow {
    background-color: rgba(255, 197, 54, 1);
    display: block;
  }

  .blot-field__user-icon-btn-color.blot-field__user-icon-btn-color-blue {
    background-color: rgba(19, 114, 138, 1);
    display: block;
  }

  .blot-field__user-icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 134px;
    height: 134px;
    border-radius: 50%;
    background-color: #50b9d4;
    box-shadow: -1.124px 2.782px 1.71px 1.29px rgba(0, 0, 0, 0.21), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.36);
  }

  .blot-field__user-icon-container .blot-field__timer {
    display: none;
  }

  .blot-field__user-icon-container--with-timer {
    background-color: #8ad660;
  }

  .blot-field__user-icon-container--with-timer .blot-field__timer {
    display: block;
  }

  .blot-field__timer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotate(-88deg);
  }

  .blot-field__timer-svg {
    fill: transparent;
    stroke-width: 4px;
    stroke: #1b3c47;
    stroke-linecap: round;
  }

  .blot-filed__user-icon-img {
    border-radius: 50%;
    overflow: hidden;
    width: 109px;
    height: 109px;
  }

  .blot-field__user--me .blot-field__user-icon {
    top: auto;
    bottom: calc(100% - 100px);
    flex-direction: column-reverse;
  }

  .blot-field__user-id {
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
  }

  .blot-field__user-id--hidden {
    visibility: hidden;
  }

  .blot-field__user--me .blot-field__user-id {
    margin-bottom: 0;
    margin-top: 10px;
  }

  .blot-field__cards {
    position: relative;
    min-width: 262px;
  }

  .blot-field__score {
    display: flex;
    flex-direction: column;
    padding-top: 19px;
    padding-right: 10px;
    padding-bottom: 19px;
    padding-left: 10px;
    min-width: 262px;
    font-weight: 700;
  }

  .blot-field__cards,
  .blot-field__score {
    border: 8px solid rgba(0, 0, 0, 0.15);
    border-radius: 35px;
  }

  .score {
    color: rgba(255, 255, 255, 0.6);
  }

  .score__row:not(:last-child) {
    width: 64%;
    min-width: 160px;
    margin: 0 auto;
    margin-bottom: 4px;
  }

  .score__row--first:first-child {
    align-items: center;
    margin-bottom: 14px;
    padding-right: 11px;
  }

  .score__row.score__row--bet {
    margin-bottom: 13px;
  }

  .score-text {
    width: 40px;
    font-size: 19px;
  }

  .score__row--bet .score__value {
    color: #7db851;
  }

  .score-vs {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
  }

  .score-vs__dotted {
    display: inline-block;
    margin: 0 1px;
  }

  .score__row {
    display: flex;
    justify-content: space-between;
  }

  .score__line-decoration {
    align-self: center;
    width: 5px;
    margin-bottom: 4px;
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.17);
  }

  .score__key,
  .score__value {
    font-size: 14px;
    font-weight: 700;
  }

  .score-text {
    text-transform: uppercase;
  }

  .score__game-rule-list {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .score__game-rule-item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 35px;
    height: 22px;
  }

  .score__game-rule-item img {
    max-width: 100%;
    max-height: 100%;
    opacity: 0.6;
  }

  /*.score__game-rule-item:nth-child(2) img {*/
  /*    width: 16px;*/
  /*    height: 19px;*/
  /*}*/

  /*.score__game-rule-item:nth-child(3) img {*/
  /*    width: 29px;*/
  /*    height: 21px;*/
  /*}*/

  /*.score__game-rule-item:nth-child(4) img {*/
  /*    width: 25px;*/
  /*    height: 19px;*/
  /*}*/

  /*control buttons*/

  .blot-field__user-control-btn-container {
    position: absolute;
    bottom: 40px;
    left: calc(100% - 100px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 261px;
  }

  .blot-field__user-control-btn-line {
    display: inline-flex;
    align-items: center;
  }

  .blot-field__user-control-btn-line {
    margin-bottom: 7px;
  }

  .blot-field__user-control-btn {
    border: none;
    padding: 10px 20px;
    font-weight: 700;
    color: rgba(254, 254, 255, .75);
    border-radius: 18px;
    font-size: 1rem;
    cursor: pointer;

    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
    background-color: rgba(56, 161, 188, 1);;
  }

  .blot-field__user-control-btn.disabled {
    opacity: 0.5;
    background-color: slategrey;
    cursor: default;
  }

  .blot-field__user-control-btn-line.quansh .blot-field__user-control-btn {
    /*width: 18px;*/
    /*height: 22px;*/
    /*width: 140px;*/
    /*text-align: right;*/
    padding-left: 59px;
    padding-right: 27px;

    background-image: url("../../assets/img/card-2-n.svg");
    background-size: 18px 22px;
    background-position: left 27px center;
    background-repeat: no-repeat;
    /*background-origin: content-box;*/
  }

  .blot-field__user-control-btn-line.throw-your-cards .blot-field__user-control-btn {
    padding-left: 65px;

    background-image: url("../../assets/mobile/img/card-many-hidden.svg");
    background-size: 33px 21px;
    background-position: left 20px center;
    background-repeat: no-repeat;
  }

  .blot-field__user-control-btn-line.throw-my-cards .blot-field__user-control-btn {
    padding-left: 65px;

    background-image: url("../../assets/mobile/img/card-many-show.svg");
    background-size: 33px 21px;
    background-position: left 20px center;
    background-repeat: no-repeat;
  }

  .blot-field__user-control-hint-btn {
    margin-left: 20px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    background-image: url("../../assets/img/icon-hint.svg");

    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .all-cards {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
  }

  .all-cards__item {
    position: absolute;
  }

  .all-cards__item:first-child {
    transform: translate(5px, 5px);
  }

  .blot-field--waiting-2nd-player {
    position: relative;
  }

  .blot-field--waiting-2nd-player .waiting-2nd-player {
    display: flex;
  }

  .waiting-2nd-player {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.35);
  }

  .waiting-2nd-player::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7220px;
    display: block;
    background-position: center center;
    opacity: 0.35;
    background-repeat: repeat;
  }

  .webp-unsupported .waiting-2nd-player {
    background-image: url("../../assets/mobile/img/blot-field/blink.png");
  }

  .webp-supported .waiting-2nd-player {
    background-image: url("../../assets/img/blinks.webp");
  }

  .waiting-2nd-player--with-animation::before {
    animation-name: waiting2nd;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes waiting2nd {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-3610px);
    }
  }

  .waiting-2nd-player__container {
    position: relative;
    z-index: 998;
    /*display: none;*/
    /*  add now  */
  }

  .waiting-2nd-player__content.no-player * {
    display: none;
  }

  .waiting-2nd-player__title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 700;
  }
  .loader-round {
    width: 23px;
    height: 23px;
    margin-right: 5px;
    animation-name: rotate-loader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .loader-path {
    animation-name: dash;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }

  @keyframes rotate-loader {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .waiting-2nd-player__content {
    width: 583px;
    min-height: 333px;
    padding-top: 20px;
    border-radius: 25px;
    background-image: url("../../assets/img/blot-field/wrapper-cyan.png");
  }

  .waiting-2nd-player__content--brown {
    background-image: url("../../assets/img/blot-field/wrapper-brown.jpg");
  }

  .waiting-2nd-player__content--green {
    background-image: url("../../assets/mobile/img/blot-field/wrapper-green.png");
  }

  .waiting-2nd-player__content .waiting-2nd-player__title {
    margin-bottom: 18px;
    font-size: 28px;
  }

  .second-player {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 21px;
  }

  .second-player__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 162px;
    margin-right: 30px;
    border-radius: 50%;
  }

  .second-player__icon-img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }

  .second-player__id,
  .second-player__statistics {
    display: flex;
  }

  .visual-statistics {
    display: flex;
    align-items: stretch;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 2px;
    border-radius: 14px;
    width: 210px;
  }

  .visual-statistics__loader {
    border-radius: 12px;
    background-color: #50b9d4;
    box-shadow: inset 0 2px 1px 0 rgba(255, 255, 255, 0.3);
  }

  .second-player__id {
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 700;
  }

  .second-player__id-key {
    margin-right: 4px;
  }

  .second-player__statistics {
    margin-bottom: 11px;
    font-size: 20px;
  }

  .second-player__statistics-key {
    margin-right: 5px;
    color: rgba(125, 184, 81, 0.8);
  }

  .second-player__statistics-value {
    color: rgba(255, 255, 255, 0.8);
  }

  .waiting-2nd-player__control {
    display: flex;
    justify-content: center;
  }

  .waiting-2nd-player__control-button {
    min-width: 92px;
    min-height: 42px;
    border: none;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
    color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    font-weight: 700;
    background-color: #21424d;
    transition: 0.2s;
  }

  .waiting-2nd-player__control-button--no {
    border-radius: 4px 17px 17px 4px;
  }

  .waiting-2nd-player__control-button--yes {
    margin-right: 15px;
    border-radius: 17px 4px 4px 17px;
  }

  .waiting-2nd-player__control-button:hover {
    cursor: pointer;
  }

  .waiting-2nd-player__control-button--no:hover {
    background-color: rgb(210, 98, 97);
  }

  .waiting-2nd-player__control-button--yes:hover {
    background-color: rgb(116, 171, 87);
  }

  .blot-field__user-tooltip {
    display: none;
    position: absolute;
    top: calc(100% + 19px);
    left: 50%;
    width: 160px;
    margin-left: -80px;
    min-height: 70px;
    border-radius: 13px;
    background-color: #132b33;
  }

  .blot-field__user-tooltip::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 100%;
    display: block;
    margin-left: -16px;
    border: 16px solid #132b33;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .blot-field__user-icon--with-tooltip .blot-field__user-tooltip {
    display: flex;
    justify-content: center;
  }

  .blot-field__user-tooltip--with-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blot-field__user-tooltip--with-loader .blot-field__user-tooltip-loader {
    display: flex;
  }

  .blot-field__user--me .blot-field__user-tooltip--with-loader {
    justify-content: center;
    display: flex;
    transform: rotate(180deg);
    top: calc(-75% + 19px);
  }

  .blot-field__user-tooltip-loader {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 37px;
  }

  .blot-field__take {
    display: none;
  }

  .blot-field__dont-take {
    display: none;
  }

  .blot-field__user-tooltip--with-dont-take {
    width: 245px;
    margin-left: -122.5px;
  }

  .blot-field__user-tooltip--with-take .blot-field__take {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
  }

  .blot-field__user-tooltip--with-dont-take .blot-field__dont-take {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
  }

  .blot-field__user-tooltip-loader-item {
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }

  .blot-field__user-tooltip-loader-item:nth-child(1) {
    background-color: rgba(138, 214, 96, 1);
    animation-name: typing-animation;
    animation-iteration-count: infinite;
    animation-duration: 0.6s; /*3*/
    animation-delay: 0.1s; /*1*/

  }

  .blot-field__user-tooltip-loader-item:nth-child(2) {
    background-color: rgba(138, 214, 96, 0.8);
    animation-name: typing-animation;
    animation-iteration-count: infinite;
    animation-duration: 0.6s; /*3*/
    animation-delay: 0.2s; /*1*/
  }

  .blot-field__user-tooltip-loader-item:nth-child(3) {
    background-color: rgba(138, 214, 96, 0.6);
    animation-name: typing-animation;
    animation-iteration-count: infinite;
    animation-duration: 0.6s; /*3*/
    animation-delay: 0.3s; /*2*/
  }

  @keyframes typing-animation {
    0% {
      transform: translateY(0);
      background-color: rgba(138, 214, 96, 1);
    }
    /*11% {*/
    /*    transform: translateY(-15px);*/
    /*    background-color: rgba(138, 214, 96, 0.8);*/
    /*}*/
    /*22% {*/
    /*    transform: translateY(0);*/
    /*    background-color: rgba(138, 214, 96, 0.6);*/
    /*}*/
    /*33% {*/
    /*    transform: translateY(0);*/
    /*    background-color: rgba(138, 214, 96, 0.6);*/
    /*}*/
    40% {
      transform: translateY(-9px);
      background-color: rgba(138, 214, 96, 0.6);
    }
    100% {
      transform: translateY(0);
      background-color: rgba(138, 214, 96, 0.6);
    }
  }

  .blot-field__user-control-button {
    position: absolute;
  }

  .my-control {
    position: absolute;
    bottom: calc(100% + 19px);
    display: none;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 406px;
    padding: 20px 18px;
    background-color: #132b33;
    color: rgba(255, 255, 255, 0.6);
    border-radius: 13px;
  }

  .my-control--with-height {
    min-height: 130px;
  }

  .my-control::before {
    content: "";
    position: absolute;
    left: 68px;
    top: 100%;
    display: block;
    margin-left: -16px;
    border: 16px solid #132b33;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .blot-field__user-control-button .my-control {
    display: flex;
  }

  .my-control__button {
    min-height: 38px;
    background-color: #1b3740;
    border: 0;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
    color: #ffffff;
    font-weight: 700;
    border-radius: 18px;
  }

  .my-control__button--wont {
    color: rgba(255, 255, 255, 0.85);
    width: 150px;
    flex-grow: 1
  }

  .my-control__button--trump {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px;
    margin-left: auto;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-bottom: 14px;
    background-color: #74ab57;
    color: rgba(255, 255, 255, 0.85);
  }

  .my-control__button--trump::before {
    content: "";
    display: block;
    width: 19px;
    height: 19px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    opacity: 1;
    margin-right: 11px;
  }

  .my-control__button--trump-heart::before {
    background-image: url("../../assets/img/blot-field/heart-red.svg");
  }

  .my-control__button--trump-diamond::before {
    background-image: url("../../assets/mobile/img/blot-field/diamond.svg");
  }

  .my-control__button--trump-club::before {
    background-image: url("../../assets/img/blot-field/club.svg");
  }

  .my-control__button--trump-spade::before {
    background-image: url("../../assets/img/blot-field/spade.svg");
  }

  .my-control__button--trump-ace::before {
    background-image: url("../../assets/img/blot-field/a.svg");
  }

  .my-control.cant-pass {
    justify-content: center;
  }

  .my-control.cant-pass .my-control__button--trump {
    border-radius: 18px 18px 18px 18px;
    margin-left: 0;
  }

  .my-control.cant-pass .my-control__button--wont {
    display: none;
  }

  .my-control.cant-pass .my-control__button--take {
    margin-left: 0;
  }

  .my-control__button--take {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #74ab57;
  }

  .my-control__button--take::before {
    content: "";
    display: block;
    width: 16px;
    height: 23px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    background-image: url("../../assets/img/blot-field/take-a-trump-icon.svg");

    opacity: 1;
    margin-right: 11px;
  }

  .my-control__trump {
    width: 100%;
    display: none;
    margin-bottom: 12px;
  }

  .my-control__trump-title {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .my-control--with-trump .my-control__trump {
    display: block;
  }

  .my-control__trump-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  .my-control__trump-item {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .my-control__trump-item img {
    width: 29px;
    height: 29px;
  }

  /*.my-control__trump-item:not(:first-child):not(:nth-child(3)) img {*/
  /*    filter: grayscale(100%);*/
  /*}*/

  .my-control__trump-item:not(.my-control__trump-item--active):hover {
    cursor: pointer;
  }

  .my-control__trump-item.my-control__trump-item--active {
    padding: 6px;
    background-color: #ffffff;
    background-clip: content-box;
    border: 2px solid rgba(255, 255, 255, 0.8);
  }

  .my-control__trump-item.my-control__trump-item--active img {
    filter: grayscale(0%);
  }

  .my-control__trump-item--diamond img {
    width: 27px;
    height: 29px;
  }

  .my-control__trump-item--diamond img {
    width: 24px;
    height: 29px;
  }

  .blot-field__user-card-declare-combination {
    display: flex;
    min-width: 232px;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 156px;
    bottom: calc(100% + 45px);
    background-color: #74ab57;
    color: #ffffff;
    border: none;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
  }

  .blot-field__user-card-declare-combination::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url("../../assets/mobile/img/blot-field/speaker.svg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
  }


  .blot-field__user-card-declare-combination--cancel {
    background-color: #21424c;
    display: none;
  }

  .blot-field__combination-container--with-all .blot-field__user-card-declare-combination--cancel {
    display: flex;
  }

  .blot-field__user-card-declare-combination--cancel::before {
    background-image: url("../../assets/mobile/img/blot-field/close.svg");
    width: 13px;
    height: 13px;
  }

  .blot-field__combination-container.blot-field__combination-container--with-all {
    display: none;
  }

  .blot-field__user-cards-container--declare-combination .blot-field__combination-container.blot-field__combination-container--with-all {
    display: flex;
  }

  .blot-field__user-cards-container--declare-combination .blot-field__combination-container.blot-field__combination-container--with-all {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    width: 264px;
    left: 136px;
    bottom: calc(100% + 45px);
  }

  .blot-field__combination-container--with-all .blot-field__user-card-declare-combination {
    position: static;
    min-width: 125px;
  }

  .blot-field__user-card-declare-combination span {
    margin-left: 4px;
  }

  .blot-field__combination-container--with-all .blot-field__user-card-declare-combination span {
    display: none;
  }

  .blot-field__combination-container-title {
    display: none;
  }

  .blot-field__combination-container--with-all .blot-field__combination-container-title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    font-size: 22px;
    font-weight: 700;
  }

  .say-terz__text {
    font-size: 18px;
  }

  .say-terz {
    display: none;
    min-width: 110px;
    padding: 10px;
    min-height: 60px;
    justify-content: center;
    align-items: center;
    margin-left: -85px;
    position: absolute;
    left: 50%;
    bottom: calc(100% + 19px);
    background-color: #132b33;
    color: rgba(255, 255, 255, 0.6);
    border-radius: 13px;
    z-index: 1;
    text-align: center;
  }

  .say-terz::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    display: block;
    margin-left: -16px;
    border: 16px solid #132b33;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .say-terz__text {
    font-size: 18px;
    font-weight: 700;
  }

  .blot-field__user-icon--terz .say-terz {
    display: flex;
  }

  .blot-field__user-icon--trump::before,
  .blot-field__user-icon--trump::after {
    width: 86px;
    height: 86px;
    left: calc(100% - 50px);
    bottom: calc(100% - 30px);
  }

  .blot-field__user-icon--trump::before {
    content: "";
    position: absolute;
    display: block;
    border: 8px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }

  .blot-field__user--you .blot-field__user-icon--trump::before {
    content: "";
    bottom: auto;
    top: calc(100% - 30px);
  }

  .blot-field__user--you .blot-field__user-icon--trump::after {
    content: "";
    position: absolute;
    bottom: auto;
    top: calc(100% - 30px);
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .blot-field__user-icon--trump::after {
    content: "";
    position: absolute;
    bottom: calc(100% - 30px);
    display: block;
    width: 86px;
    height: 86px;
    background-size: 37px 37px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .blot-field__user-icon--trump-spade::after {
    background-image: url("../../assets/img/blot-field/spade-black.svg");
  }

  .blot-field__user-icon--trump-club::after {
    background-image: url("../../assets/img/blot-field/club-black.svg");
  }

  .blot-field__user-icon--trump-heart::after {
    background-image: url("../../assets/img/blot-field/heart-red.svg");
  }

  .blot-field__user-icon--trump-diamond::after {
    background-image: url("../../assets/mobile/img/blot-field/diamond.svg");
  }

  .blot-field__user-icon--trump-no_trump::after {
    background-image: url("../../assets/img/blot-field/a.svg");
  }

  .my-terz-higher {
    display: none;
    justify-content: center;
    align-items: center;
    width: 245px;
    margin-left: -77px;
    min-height: 90px;
    position: absolute;
    left: 50%;
    bottom: calc(100% + 19px);
    padding: 20px 18px;
    background-color: #132b33;
    color: rgba(255, 255, 255, 0.6);
    border-radius: 13px;
    font-size: 16px;
    font-weight: 700;
  }

  .my-terz-higher::before {
    content: "";
    position: absolute;
    left: 78px;
    top: 100%;
    display: block;
    margin-left: -16px;
    border: 16px solid #132b33;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .blot-field__user-icon--my-terz-higher .my-terz-higher {
    display: flex;
  }

  .blot-field__say-terz {
    display: none;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(100% + 19px);
    left: 50%;
    background-color: #132b33;
    border-radius: 13px;
    margin-left: -85px;
    min-width: 110px;
    padding: 10px;
    min-height: 60px;
    font-size: 18px;
    z-index: 1;
    font-weight: 700;
    text-align: center;
  }

  .blot-field__say-terz::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 100%;
    display: block;
    margin-left: -16px;
    border: 16px solid #132b33;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .blot-field__say-terz-with-high-value::after {
    content: "";
    display: block;
    width: 36px;
    height: 49px;
    margin-right: 26px;
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .blot-field__say-terz--ten::after {
    /*background-image: url("./img/blot-field/say-ten.svg");*/
  }

  .blot-field__say-terz--jack::after {
    background-image: url("../../assets/img/blot-field/say-jack.svg");
  }

  .blot-field__say-terz--queen::after {
    /*background-image: url("./img/blot-field/say-queen.svg");*/
  }

  .blot-field__say-terz--king::after {
    /*background-image: url("./img/blot-field/say-king.svg");*/
  }


  .blot-field__user-icon--say-terz .blot-field__say-terz {
    display: flex;
  }

  .blot-field__user-control-hint-btn--with-tool-tip {
    position: relative;
  }

  .blot-field__user-control-hint-btn--with-tool-tip:hover .blot-field__user-control-hint-btn-tool-tip {
    display: flex;
  }

  .blot-field__user-control-hint-btn-tool-tip {
    position: absolute;
    bottom: calc(100% + 19px);
    left: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    min-width: 170px;
    margin-left: -85px;
    border-radius: 13px;
    background-color: #132b33;
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
    min-height: 90px;
    padding: 0 18px;
  }

  .blot-field__user-control-hint-btn-tool-tip::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    display: block;
    margin-left: -16px;
    border: 16px solid #132b33;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .blot-field__cards--throw-cards .all-cards {
    transition: 0.5s;
    opacity: 0;
  }

  .blot-field__cards--throw-cards .throw-cards {
    display: flex;
    align-items: center;
    width: 64%;
    max-width: 170px;
    height: 100%;
    margin: 0 auto;
  }

  .throw-cards {
    position: relative;
    display: none;
  }

  .throw-cards__list {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  .throw-cards__button {
    position: absolute;
    top: 12px;
    right: calc(100% + 9px);
    background-color: #132b33;
    width: 28px;
    height: 28px;
    border: none;
    border-radius: 50%;
    background-image: url("../../assets/img/blot-field/close-other.svg");
    background-size: 12px 12px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .throw-cards__item {
    width: calc(100% / 4 - 15px);
  }

  .throw-cards__item:not(:nth-child(4n + 4)) {
    margin-right: 15px;
  }

  .throw-cards__item:nth-last-child(n + 5) {
    margin-bottom: 15px;
  }

  .throw-cards__item::before {
    content: "";
    display: block;
    padding-top: 135%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }

  .throw-cards__item.throw-cards__item-spade-ace:before {
    background-image: url("../../assets/img/blot-field/throw-cards/spade-ace.svg");
  }

  .throw-cards__item.throw-cards__item-club-ace:before {
    background-image: url("../../assets/img/blot-field/throw-cards/club-ace.svg");
  }

  .throw-cards__item.throw-cards__item-heart-ace:before {
    background-image: url("../../assets/img/blot-field/throw-cards/heart-ace.svg");
  }

  .throw-cards__item.throw-cards__item-diamond-ace:before {
    background-image: url("../../assets/mobile/img/blot-field/throw-cards/diamond-ace.svg");
  }

  .throw-cards__item.throw-cards__item-spade-king:before {
    background-image: url("../../assets/img/blot-field/throw-cards/spade-king.svg");
  }

  .throw-cards__item.throw-cards__item-club-king:before {
    background-image: url("../../assets/img/blot-field/throw-cards/club-king.svg");
  }

  .throw-cards__item.throw-cards__item-heart-king:before {
    background-image: url("../../assets/img/blot-field/throw-cards/heart-king.svg");
  }

  .throw-cards__item.throw-cards__item-diamond-king:before {
    background-image: url("../../assets/img/blot-field/throw-cards/diamond-king.svg");
  }

  .throw-cards__item.throw-cards__item-spade-queen:before {
    background-image: url("../../assets/img/blot-field/throw-cards/spade-queen.svg");
  }

  .throw-cards__item.throw-cards__item-club-queen::before {
    background-image: url("../../assets/mobile/img/blot-field/throw-cards/club-queen.svg");
  }

  .throw-cards__item.throw-cards__item-heart-queen::before {
    background-image: url("../../assets/img/blot-field/throw-cards/heart-queen.svg");
  }

  .throw-cards__item.throw-cards__item-diamond-queen::before {
    background-image: url("../../assets/img/blot-field/throw-cards/diamond-queen.svg");
  }

  .throw-cards__item.throw-cards__item-spade-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/throw-cards/spade-jack.svg");
  }

  .throw-cards__item.throw-cards__item-club-jack::before {
    background-image: url("../../assets/img/blot-field/throw-cards/club-jack.svg");
  }

  .throw-cards__item.throw-cards__item-heart-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/throw-cards/heart-jack.svg");
  }

  .throw-cards__item.throw-cards__item-diamond-jack::before {
    background-image: url("../../assets/mobile/img/blot-field/throw-cards/diamond-jack.svg");
  }

  .throw-cards__item.throw-cards__item-spade-10::before {
    background-image: url("../../assets/img/blot-field/throw-cards/spade-10.svg");
  }

  .throw-cards__item.throw-cards__item-club-10::before {
    background-image: url("../../assets/mobile/img/blot-field/throw-cards/club-10.svg");
  }

  .throw-cards__item.throw-cards__item-heart-10::before {
    background-image: url("../../assets/img/blot-field/throw-cards/heart-10.svg");
  }

  .throw-cards__item.throw-cards__item-diamond-10::before {
    background-image: url("../../assets/mobile/img/blot-field/throw-cards/diamond-10.svg");
  }

  .throw-cards__item.throw-cards__item-spade-9::before {
    background-image: url("../../assets/img/blot-field/throw-cards/spade-9.svg");
  }

  .throw-cards__item.throw-cards__item-club-9::before {
    background-image: url("../../assets/img/blot-field/throw-cards/club-9.svg");
  }

  .throw-cards__item.throw-cards__item-heart-9::before {
    background-image: url("../../assets/mobile/img/blot-field/throw-cards/heart-9.svg");
  }

  .throw-cards__item.throw-cards__item-diamond-9::before {
    background-image: url("../../assets/img/blot-field/throw-cards/diamond-9.svg");
  }

  .throw-cards__item.throw-cards__item--with-opacity::before {
    opacity: 0.5;
  }

  .blot-field__user-icon--choose-button .choose-button-yes-no {
    display: flex;
  }

  .choose-button-yes-no {
    position: absolute;
    left: -26px;
    bottom: calc(100% + 19px);
    width: 245px;
    min-height: 90px;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #132b33;
    color: rgba(255, 255, 255, 0.6);
    border-radius: 13px;
  }

  .choose-button-yes-no::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    display: block;
    margin-left: -46px;
    border: 16px solid #132b33;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .choose-button-yes__button {
    width: 92px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
    border: none;
    background-color: #21424d;
    color: rgba(255, 255, 255, 0.9);
    font-size: 18px;
    font-weight: 700;
  }

  .choose-button-yes__button--no {
    border-radius: 18px 4px 4px 18px;
    margin-right: 15px;
  }

  .choose-button-yes__button--yes {
    border-radius: 4px 18px 18px 4px;
    background-color: #74ab57;
  }

  .all-cards__all-cards {
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #38a1bc;
    margin-top: 61px;
    width: 126px;
    min-height: 35px;
    border: none;
    border-radius: 21px;
    color: rgba(254, 254, 254, 0.75);
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
  }

  .all-cards--with-button .all-cards__all-cards {
    display: flex;
  }


  /*knockout*/
  .knockout.active {
    display: block;
  }

  .knockout-container {
    position: absolute;
    top: 45px;
    right: 3.6%;
    z-index: 20;

    width: 263px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .knockout-btn__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .knockout-btn__top {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 8px;
  }

  .knockout-btn__icon-container {
    background: none;
    border: none;
    appearance: none;
    padding: 0;
    cursor: default !important;
    width: 78px;
    height: 78px;

    margin-bottom: 10px;
  }

  .knockout-btn__icon {
    max-width: 100%;
    max-height: 100%;
  }

  .knockout-btn__title {
    font-size: 1.125rem;
    color: #fefeff;
    font-weight: 600;
    text-transform: capitalize;
  }

  .knockout-btn__more-container {

  }

  .knockout-btn {
    padding: 11px 36px;
    border: none;
    border-radius: 20px;
    background-color: #38a1bc;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), inset 0 1px 0 0 rgba(255, 255, 255, 0.21);

    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
  }

  .knockout {
    position: relative;
    z-index: 1;
    top: -43px;
    display: none;

    width: 263px;
    height: 470px;
    border-radius: 8px;
    overflow: hidden;

    background-image: url("../../assets/img/knockout-bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    padding-top: 18px;
    /*padding-right: 15px;*/
    /*padding-left: 15px;*/
    font-size: 15px;
  }

  .knockout__close-btn__container {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .knockout__close-btn {
    width: 26px;
    height: 26px;

    border-radius: 50%;
    overflow: hidden;
    border: none;
    cursor: pointer;

    padding: 0;

    background-color: #14272f;
    background-image: url('../../assets/mobile/img/knockout-close-icon.svg');
    background-size: cover;
  }

  .knockout-header {
    padding-right: 15px;
    padding-left: 15px;
  }

  .knockout-header__btn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    font-size: 1.125rem;
    color: rgba(var(--blot-white), .15);
    font-weight: 600;

    padding-bottom: 3px;
    border-bottom: 2px solid transparent;

    margin-bottom: 25px;
  }

  .knockout-header__btn:first-of-type {
    margin-right: 40px;
  }

  .knockout-header__btn.active {
    color: rgba(var(--blot-white), 1);
    border-bottom: 2px solid rgba(var(--blot-white), .8);
  }

  .knockout-desc__container {

  }

  .knockout-desc {
    display: none;
  }

  .knockout-desc.active {
    display: block;
  }

  .prize {
    padding-right: 15px;
    padding-left: 15px;
  }

  .prize__list {
    list-style: none;
    padding: 0;
    margin: 0;

    margin-bottom: 45px;
  }

  .prize__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .prize__name {
    color: rgba(var(--blot-white), .35);
  }

  .prize__value {
    color: rgba(var(--blot-white), .6);
    font-weight: 600;
  }

  .winning__container {
    border-bottom: 1px solid transparent;
  }

  .winning__caption {
    color: rgba(var(--blot-white), .8);
    font-size: 1.125rem;
    font-weight: 600;

    margin-top: 0;
    margin-bottom: 14px;
  }

  .winning {
    list-style: none;
    padding: 0;
    margin: 0;

    margin-bottom: 105px;
  }

  .winning__item {
    display: flex;
    align-items: center;
  }

  .winning__number {
    color: rgba(var(--blot-white), .35);
  }

  .winning__value {
    display: inline-flex;
    align-items: center;

    color: rgba(var(--blot-white), .7);
    font-weight: 600;
  }

  .winning__value::before {
    content: "-";
    color: rgba(var(--blot-white), .7);
    margin-right: 4px;
    margin-left: 4px;
  }

  /*player*/
  .player {

  }

  .player-list {
    list-style: none;
    margin: 0;
    padding: 0;

    margin-bottom: 35px;
    padding-right: 40px;
    padding-left: 15px;
  }

  .player-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .player-item:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .player-name {
    color: rgba(var(--blot-white), .35);
  }

  .player__value {
    color: rgba(var(--blot-white), .6);
    font-weight: 600;
  }

  .player-condition__list {
    list-style: none;
    padding: 0;
    margin: 0;

    max-height: 320px;
    overflow-y: auto;

    margin-right: 8px;
    margin-left: 4px;
    /*max-height: 320px;*/
    /*overflow-y: auto;*/
  }

  /* width */
  .player-condition__list::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  .player-condition__list::-webkit-scrollbar-track {
    background: rgba(var(--blot-cyan), 1);
  }

  /* Handle */
  .player-condition__list::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.031);
  }

  .player-condition__item {
    display: flex;
    align-items: center;

    padding-top: 7px;
    padding-right: 5px;
    padding-bottom: 7px;
    padding-left: 15px;
  }

  .player-condition__item:nth-of-type(even) {
    background-color: rgba(3, 3, 3, .2);
  }

  .player-condition__item:first-of-type {
    margin-bottom: 10px;
  }

  .player-condition__item:last-of-type {
    margin-bottom: 4px;
  }

  .player-condition__number {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba(254, 254, 255, 0.302);

    width: 70px;
    flex-shrink: 0;
    /*padding-left: 20px;*/
  }

  .player-condition__id {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba(254, 254, 255, 0.302);

    width: 80px;
    flex-shrink: 0;
  }

  .player-condition__status {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba(254, 254, 255, 0.302);

    flex-grow: 1;
  }

  .player-condition__item:first-of-type .player-condition__number {
    font-weight: 600;
    color: rgba(254, 254, 255, 0.502);
    font-size: 0.875rem;
  }

  .player-condition__item:first-of-type .player-condition__id {
    font-weight: 600;
    color: rgba(254, 254, 255, 0.502);
    font-size: 0.875rem;
  }

  .player-condition__item:first-of-type .player-condition__status {
    font-weight: 600;
    color: rgba(254, 254, 255, 0.502);
    font-size: 0.875rem;
  }

  .open-blot__cards {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .open-blot__couple-cards {
    position: relative;
    width: 97px;
    height: 141px;
  }

  .open-blot__couple-cards:not(:last-child) {
    margin-right: 6px;
  }

  .open-blot__card {
    position: absolute;
    width: 90px;
    height: 133px;
    border: 0;
    background-color: transparent;
    outline: 0;
    transition: 0.2s;
  }

  .open-blot__card:hover {
    cursor: pointer;
  }

  .open-blot__card:disabled:hover {
    cursor: default;
  }

  .open-blot__card:nth-child(1) {
    top: 0;
    right: 0;
  }

  .open-blot__card:nth-child(2) {
    bottom: 0;
    left: 0;
  }

  .open-blot__card--rotate:nth-child(n) {
    transform: rotateY(-180deg);
    transform-style: preserve-3d;
  }

  .open-blot__card-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: 100% 100%;
  }

  .open-blot__card-wrapper--back {
    transform: rotateY(-180deg);
  }

  .open-blot__card-wrapper--back {
    background-image: url("../../assets/img/blot-field/card-wrapper.png")
  }

  .open-blot__card-wrapper--front {
    background-image: url("../../assets/mobile/img/blot-field/heart-7.png");
    backface-visibility: hidden;
  }

  .blot-field__wrapper--open .blot-field__user--you .blot-field__user-container {
    padding-top: 50px;
  }

  .blot-field__wrapper--open .blot-field__user--me .blot-field__user-container {
    padding-bottom: 50px;
  }

  .my-control--two-button {
    width: 230px;
  }

  .my-control--two-button .my-control__button--trump {
    width: 100%;
    border-radius: 18px;
  }

  .my-control--one-button {
    width: 230px;
  }

  .my-control--one-button .my-control__button--trump {
    width: 100%;
    border-radius: 18px;
    margin-bottom: 0;
  }

  @media (max-width: 1365px), (max-height: 845px) {
    .blot-field__user {
      width: 80%;
      max-width: 785px;
      max-height: 194px;
    }

    .blot-field__user-icon {
      top: calc(100% - 142px);
      right: calc(100% + 50px);
    }

    .blot-field__user--me .blot-field__user-icon {
      bottom: calc(100% - 142px);
    }

    .blot-filed__user-icon-img {
      width: 95px;
      height: 95px;
    }

    .blot-field__user-icon-container {
      width: 115px;
      height: 115px;
    }

    .card-field {
      width: 138px;
      height: 193px;
      background-size: 100% auto;
    }

    .blot-field__cards {
      min-width: 190px;
    }

    .blot-field__score {
      min-width: 190px;
    }

    .blot-field__user-control-btn {
      font-size: 13px;
    }

    .blot-field__user-control-hint-btn {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }

    .blot-field__user-control-btn-line.quansh .blot-field__user-control-btn {
      background-size: 15px 18px
    }
    .blot-field__user-control-btn-line.throw-your-cards .blot-field__user-control-btn {
      background-size: 28px 17px
    }
    .blot-field__user-control-btn-line.throw-my-cards .blot-field__user-control-btn {
      background-size: 28px 17px
    }

    .blot-field__cards,
    .blot-field__score {
      border-width: 6px;
    }

    .blot-field__cards-score {
      width: 96%;
      max-width: 1200px;
      height: 34%;
    }

    .score-vs {
      font-size: 12px;
    }

    .score-text {
      font-size: 14px;
    }

    .score-text:last-of-type {
      text-align: right;
    }

    .score__game-rule-item {
      width: 26px;
      height: 16px;
    }

    .score__key,
    .score__value {
      font-size: 11px;
    }

    .score__row:not(:last-child) {
      min-width: 142px;
    }

    .waiting-2nd-player__title {
      font-size: 15px;
    }

    .without-transition {
      transition: 0s !important;
    }

    .waiting-2nd-player__content .waiting-2nd-player__title {
      font-size: 22px;
    }

    .waiting-2nd-player__content {
      width: 455px;
      min-height: 269px;
    }

    .second-player__id {
      font-size: 17px;
    }

    .second-player__statistics {
      font-size: 15px;
    }

    .waiting-2nd-player__control-button {
      min-width: 71px;
      min-height: 32px;
      font-size: 15px;
    }

    .second-player__icon {
      width: 126px;
      height: 126px;
    }

    .second-player__icon-img {
      width: 100px;
      height: 100px;
    }

    .blot-field__user-tooltip {
      width: 140px;
      margin-left: -70px;
    }

    .blot-field__user-id {
      font-weight: bold;
    }

    .blot-field__user-cards-show-combination {
      min-height: 35px;
    }

    .blot-field__user-icon--trump::before {
      border-width: 6px;
    }

    .say-terz {
      margin-left: -66px;
    }

    .blot-field__user-control-hint-btn-tool-tip {
      min-width: 138px;
      margin-left: -69px;
      padding: 0 13px;
      min-height: 74px;
      font-size: 13px;
    }

    .all-cards__item:first-child {
      transform: translate(4px, 4px);
    }

    .all-cards__all-cards {
      width: 109px;
      min-height: 30px;
      font-size: 13px;
    }

    .throw-cards__item:nth-last-child(n + 5) {
      margin-bottom: 10px;
    }

    .throw-cards__item:not(:nth-child(4n + 4)) {
      margin-right: 10px;
    }

    .throw-cards__item {
      width: calc(100% / 4 - 10px);
    }

    .throw-cards__button {
      top: 18px;
      right: calc(100% + 8px);
      width: 21px;
      height: 21px;
      background-size: 10px 10px;
    }

    .blot-field__user-icon-message-add-popup {
      right: calc(-92px);
    }

    .blot-field__user-icon-message-popup {
      top: 116%;
      right: calc(100% - 81px);
      min-height: 107px;
      z-index: 111;
      background-image: url("../../assets/img/popup-background-3.png");
    }

    //.menu {
    //  padding-bottom: 28px;
    //}

    .blot-field__user-icon--trump::before,
    .blot-field__user-icon--trump::after {
      width: 65px;
      height: 65px;
    }

    .blot-field__user-icon--trump::before {
      border-width: 5px;
    }

    .blot-field__user-icon--trump::after {
      background-size: 27px 27px
    }

    .say-terz__text {
      font-size: 18px;
    }

    .blot-field__user-icon--trump::before,
    .blot-field__user-icon--trump::after {
      left: calc(100% - 39px);
      bottom: calc(100% - 28px);
    }

    .blot-field__user--you .blot-field__user-icon--trump::before,
    .blot-field__user--you .blot-field__user-icon--trump::after {
      content: "";
      bottom: auto;
      top: calc(100% - 28px);
    }
  }

  @media (max-width: 1440px) {
    .score__line-decoration-score
    .score__line-decoration-score__row {
      font-size: 12px;
      font-weight: normal;
    }
  }

  @media (min-width: 1366px) and (max-width: 1439px) and (min-height: 846px) {
    .blot-field__user--you {
      background-position: center bottom 14px;
    }

    .blot-field__user--me {
      background-position: center top 14px;
    }

    .blot-field__user-icon {
      top: calc(100% - 130px);
      right: calc(100% + 85px);
    }

    .blot-field__user--me .blot-field__user-icon {
      bottom: calc(100% - 130px);
    }

    .blot-field__user-icon-container {
      width: 125px;
      height: 125px;
    }

    .blot-field__timer {
      top: 50%;
      right: auto;
      bottom: 50%;
      left: 50%;
      width: 104%;
      height: 104%;
      transform: translate(-50%, -50%) rotate(-88deg)
    }

    .blot-field__user-icon-container {
      width: 126px;
      height: 126px;
    }

    .blot-filed__user-icon-img {
      width: 102px;
      height: 102px;
    }

    .blot-field__cards,
    .blot-field__score {
      min-width: 233px;
    }

    .blot-field__cards-score {
      min-height: 320px;
    }
  }

  @media (min-width: 1920px) and (min-height: 846px) {
    .blot-field__user {
      width: 1038px;
    }

    .blot-field__user--you {
      background-position: center bottom 61px;
    }

    .blot-field__user--me {
      background-position: center top 61px;
    }

    .blot-field__user-card:not(:last-child) {
      margin-right: -113px;
    }

    .blot-field__user-icon {
      right: calc(100% + 85px);
    }

    .blot-field__cards-score {
      width: 1500px;
      height: 325px;
    }

    .blot-field__score {
      min-width: 248px;
    }

    .blot-field__cards {
      min-width: 248px;
    }

    .blot-field__user--me .blot-field__user-cards {
      margin-bottom: -40px;
    }
  }

  #root {
    overflow: hidden;
  }

  /* fireworks */
  .fireworks {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /*background-color: rgba(0, 0, 0, 0.8);*/
    /*  add now  */
  }

  #canvas-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }

  canvas {
    cursor: crosshair;
    display: block;
    position: relative;
    z-index: 3;
  }

  canvas:active {
    cursor: crosshair;
  }

  #skyline {
    bottom: 0;
    height: 135px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  #mountains1 {
    bottom: 0;
    height: 200px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  #mountains2 {
    bottom: 0;
    height: 250px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  #gui {
    right: 0;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: 0.2s;
  }

  #gui.hidden {
    transform: translateX(100%);
  }

  .blot-field__user-cards {
    width: 505px;
  }

  .score__line-main {
    position: relative;
    align-self: center;
    display: flex;
    flex-grow: 1;
    width: 100%;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.17);
    margin-bottom: 4px;
    overflow: hidden;
  }

  .score__line-main::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    left: calc(50% - 6px);
    width: 5px;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }

  @-moz-document url-prefix() {
    .score__line-main::before {
      left: calc(50% - 7px);
    }
  }

  .score__line-decoration-score {
    flex-grow: 1;
    overflow-y: auto;
  }

  .score__line-decoration-score::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .score__line-decoration-score::-webkit-scrollbar-track {
    box-shadow: none;
  }

  .score__line-decoration-score::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.6);
  }

  .score__line-decoration-score__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-right: 7px;
    font-size: 13px;
  }

  .score__line-decoration-score__row:first-child .score__line-decoration-score__text {
    color: #7db851;
  }

  .score__line-decoration-score__cell {
    position: relative;
    width: calc(50% - 10px);
    padding-bottom: 6px;
    text-align: center;
    white-space: nowrap;
  }

  .score__line-decoration-score__cell::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 2px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .score__line-decoration-score__text--with-opacity {
    opacity: 0.6;
  }

  .waiting-2nd-player--info .waiting-2nd-player__content {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    border: 2px solid #50b9d4;
  }

  .waiting-2nd-player--info .waiting-2nd-player__close-button {
    position: absolute;
    top: 0;
    left: 93%;
    margin-top: 20px;
  }

  .waiting-2nd-player--info .waiting-2nd-player__title {
    margin: 0;
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .waiting-2nd-player--info.multiline .waiting-2nd-player__title {
    white-space: nowrap;
    text-align: initial;
  }

  .waiting-2nd-player--info .waiting-2nd-player__control {
    margin: 30px 0;
    display: none;
  }

  .waiting-2nd-player--info.waiting-2nd-player--info__with-buttons .waiting-2nd-player__control {
    display: block;
  }

  .waiting-2nd-player--info.waiting-2nd-player--info__with-buttons .waiting-2nd-player__close-button {
    display: none;
  }

  .spin-blot {
    position: absolute;
    top: 54px;
    right: 9.8%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    font-weight: 700;
    font-size: 18px;
  }

  .spin-blot__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
  }

  .spin-blot__text::before {
    content: "";
    width: 72px;
    height: 69px;
    margin-bottom: 10px;
    background-image: url('../../assets/img/spin-belote.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .spin-blot__prize-amount {
    color: #7db851;
  }

  .winner-loser-popup {
    position: fixed;
    top: 55px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    align-items: flex-start;
    display: none;
  }

  .winner-loser-popup--active {
    display: flex;
    animation: zoom-in;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  @keyframes zoom-in {
    0% {
      transform: scale(0.01);
    }
    100% {
      transform: scale(1);
    }
  }

  .game-status-popup {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    background-image: url("../../assets/img/blinks.webp");
    background-size: auto auto;
    background-position: 0 center;
    animation-name: backgroundStatus;
    animation-duration: 50s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
  }

  @keyframes backgroundStatus {
    0% {
      background-position: 0 center;
    }
    100% {
      background-position: 100% center;
    }
  }

  .game-status-popup__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12vh;
    font-size: 30px;
    font-weight: 700;
  }

  .game-status-popup__header {
    width: 565px;
    height: 74px;
    padding-top: 5px;
    background-image: url("../../assets/img/tape-orange.svg");
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
  }

  .game-status-popup__header--winner {
    background-image: url("../../assets/img/tape-green.svg");
  }

  .game-status-popup__score {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 42px;
    margin-top: -18px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #223c43;
    font-size: 22px;
  }

  .game-status-popup__score--green {
    background-color: #36695e;
  }

  .game-status-popup__score--gray {
    background-color: #2c3742;
  }

  .game-status-popup__place {
    margin-bottom: 5px;
    font-size: 22px;
    color: #e5e5e5;
  }

  .game-status-popup__prize {
    font-size: 22px;
  }

  .game-status-popup__prize-span {
    color: #e3b752;
  }

  .game-status-popup__prize {
    margin-bottom: 35px;
    color: #e5e5e5;
  }

  .game-status__popup-banner {
    width: 723px;
    height: 123px;
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .game-status__last-choose {
    margin-top: 40px;
  }

  .game-status__last-choose {
    display: flex;
    justify-content: space-around;
    width: 760px;
  }

  .game-status__last-choose__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 285px;
    height: 410px;
    padding-top: 20px;
    padding-bottom: 15px;
    border-radius: 20px;
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .game-status__last-choose__container--create-table {
    background-image: url("../../assets/img/choose-banner-1.png");
  }

  .game-status__last-choose__container--rematch {
    background-image: url("../../assets/img/choose-banner-2.png");
  }

  .game-status__last-choose__container-title {
    margin-left: -24px;
    padding-left: 24px;
    padding-top: 8px;
    height: 68px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url("../../assets/img/ribbon-winner.svg");
    color: rgba(255, 255, 255, 0.75);
    font-size: 20px;
    text-align: center;
  }

  .game-status__last-choose__container-controls-row {
    display: flex;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 18px;
  }

  .game-status__button-core {
    border-radius: 20px;
    min-height: 40px;
    border: 0;
  }

  .game-status__last-choose__container-controls-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), inset 0 1px 0 0 rgba(255, 255, 255, 0.21);
    background-color: #74ab57;
    color: rgba(254, 254, 254, 0.9);
    font-size: 16px;
  }

  .game-status__little-button {
    width: calc(50% - 3px);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
    background-image: url("../../assets/img/background-noise-dark.png");
    color: rgba(232, 232, 232, 0.8);
    font-size: 18px;
    transition: 0.2s;
  }

  .game-status__little-button:hover {
    cursor: pointer;
    background-image: url("../../assets/img/background-noise-light.png");
    color: rgba(232, 232, 232, 1);
  }

  .game-status__little-button.active {
    color: burlywood;
  }

  .game-status__little-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .game-status__little-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .game-status-popup--with-controls .game-status-popup__prize,
  .game-status-popup--with-controls .game-status-popup__place,
  .game-status-popup--with-controls .game-status__popup-banner {
    display: none;
  }

  .game-status__last-choose__container-controls-row:not(:last-child) {
    margin-bottom: 12px;
  }

  .game-status__last-choose__container-controls-input-label {
    width: calc(100% - 70px);
    padding-right: 28px;
  }

  .game-status__last-choose__button-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 63px;
    background-color: #74ab57;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .game-status__last-choose__button-input::before {
    content: "";
    display: block;
    width: 25px;
    height: 26px;
    background-image: url("../../assets/img/gear.svg");
    opacity: 0.75;
  }

  .game-status__last-choose__container-controls-input-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url("../../assets/img/background-noise-dark.png");
    color: rgba(232, 232, 232, 0.5);
    border-radius: 20px 0 0 20px;
    font-size: 18px;
    padding-left: 19px;
    font-weight: normal;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
  }

  .game-status__last-choose__container-controls-input {
    flex-grow: 1;
    text-align: right;
    background-color: transparent;
    border: 0;
    color: rgba(232, 232, 232, 0.7);
    width: 100px;
    font-size: inherit;
    font-weight: bold;
    padding-left: 15px;
  }

  .game-status__last-choose__container-controls-input::placeholder {
    color: rgba(232, 232, 232, 0.7);
  }


  .lobby__table-body .lobby__table-cell--player-first .lobby__table-cell-element {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100px;
    border-radius: 6px;
    background-color: rgba(27, 31, 37, 0.6);
    font-weight: normal;
  }

  .lobby__table-cell--player-two:not(.with-button) .play-view {
    display: none;
  }

  .lobby__table-cell--player-two.with-button .lobby__table-cell-element:first-child {
    display: none;
  }

  .lobby__table-body .lobby__table-cell--player-two .lobby__table-cell-element.play-view.play-view--play {
    background-color: rgba(23, 81, 96, 0.7);
    color: white;
    font-size: 18px;
    font-weight: bold;
  }

  .lobby__table-body .lobby__table-cell--player-first .lobby__table-cell-element {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100px;
    border-radius: 6px;
    background-color: rgba(27, 31, 37, 0.6);
    font-weight: normal;
  }

  .lobby__table-body .lobby__table-row:nth-child(2n) .lobby__table-cell--player-first .lobby__table-cell-element {
    background-color: rgba(24, 27, 33, 0.6);
  }

  .lobby__table-cell--player-first:not(.with-button) .play-view {
    display: none;
  }

  .lobby__table-cell--player-first.with-button .lobby__table-cell-element:first-child {
    display: none;
  }

  .lobby__table-body .lobby__table-cell--player-first .lobby__table-cell-element.play-view.play-view--play {
    background-color: rgba(23, 81, 96, 0.7);
    color: white;
    font-size: 18px;
    font-weight: bold;
  }

  .lobby__table-cell--player-first:not(.with-button) .lobby__table-cell-element--transform-button {
    position: relative;
  }

  .lobby__table-body .lobby__table-cell--player-first:not(.with-button) .lobby__table-cell-element--transform-button .lobby__table-cell-element {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background-color: #191d22;
    clip-path: circle(0);
    transition: 0.2s;
  }

  .lobby__table-body .lobby__table-row.lobby__table-row--hover .lobby__table-cell--player-first:not(.with-button) .lobby__table-cell-element--transform-button .lobby__table-cell-element {
    clip-path: circle(100%);
  }

  .lobby--spin .lobby__table-cell--player-first {
    width: 12%;
  }

  .lobby--spin .lobby__table-cell--player-first {
    width: 12%;
  }

  .lobby--spin .lobby__table-cell--player-first.with-button .lobby__table-cell-element:first-child {
    display: block;
    margin-left: auto;
  }

  .lobby--spin .lobby__table-cell--player-first.with-button .lobby__table-cell-element.disable:first-child {
    background-color: rgba(22, 25, 30, 0.6);
  }

  .lobby--spin .lobby__table-cell--player-first.with-button .lobby__table-cell-element.disable:first-child .play {
    display: none;
  }

  .lobby--spin .lobby__table-cell--player-first.with-button .lobby__table-cell-element:first-child .play {
    display: block;
  }

  .lobby--spin .lobby__table-cell--player-first.with-button .lobby__table-cell-element.disable:first-child .disable {
    display: block;
  }

  .lobby--spin .lobby__table-cell--player-first.with-button .lobby__table-cell-element:first-child .disable {
    display: none;
  }

  .lobby__table-cell--player-first {
    width: 16%;
  }

  .lobby__table-cell--player-first .play-view--play.green {
    box-shadow: inset 0 0 0 2px #8ad660;
  }

  .lobby__table-cell--player-first .play-view--play.red {
    box-shadow: inset 0 0 0 2px #e94e4d;
  }

  .lobby__table-cell--player-first .play-view--play.yellow {
    box-shadow: inset 0 0 0 2px #ffc536;
  }

  .lobby__table-cell--player-first .play-view--play.blue {
    box-shadow: inset 0 0 0 2px #13728a;
  }

  .lobby__table-cell--player-first:not(.with-button) .play-view {
    display: none;
  }

  .lobby__table-cell--player-first.with-button .lobby__table-cell-element:first-child {
    display: none;
  }

  .lobby__table-body .lobby__table-cell--player-first .lobby__table-cell-element.play-view.play-view--play {
    background-color: rgba(23, 81, 96, 0.7);
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
}
