.cms-content {
    margin: 12px 0;
}
.ui.vertical.menu.cms{
    background-color: transparent!important;
    border: none!important;
}
.ui.menu .item.cms{
    padding: 15px!important;
}
.ui.left.cms.pointing.dropdown>.menu {
    top: 0!important;
    left: 100%!important;
    right: auto!important;
    margin: 0 0 0 1em!important;
}
.ui.vertical.menu .dropdown.item.upward.cms .menu{
    top: 0!important;
}
.left-cms{
    width: 70vw;

}
.flex-wrap.cms{
    position: relative;
    left: 100%;
}

.ui.card.own{
    width: 100%!important;
    background: #4f757e!important;
}
.img-cms{
    width: 110px;
    background-size: cover;
    display: block;
    background-position: center;
    height:80px;
    margin:auto;
}
.extra-cms{
    display: flex;
    flex-wrap: wrap;
}
.div-card{
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    border: thin solid lightgray;
    margin: 8px;
}
.align-right{
    margin: 3px 86%;
}
.pri-width{
    width: 70px;
}
.m-auto{
    margin:auto
}
.pri-cms-inp{
    display: flex;
    flex-direction: column
}
.margin-6-12{
    margin: 6px 12px;
}
.flex-end{
    display: flex;
    justify-content: flex-end;
}
.block{
    display: block;
}