.game-content {
    margin: 12px 0;
}
.date-from,
.date-to,
.filter-inputs {
    display: flex;
}
.date-err {
    margin: 7px 0;
    font-size: 11px;
}
.game-info-content {
    width: 88%;
    margin: 12px 0;
}
.ui.selection.dropdown.own {
    min-width: 2em !important;
}
.row-count {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: right;
}
.row-count .label {
    font-size: 10px;
    text-indent: 3px;
    margin: 0;
}
.game-content {
    width: 100%;
}
.input-box.game {
    padding: 0 13px 0 0;
}
.live {
    width: 83px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 67px;
}

.margin-left {
    margin-left: 9%;
}

.hover-cell:hover {
    background-color: #386e7b !important;
    color: #fff !important;
}
