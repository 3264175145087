.registration-day-manual {
    display: flex;
    justify-content: space-around;
}

.mini-input {
    margin: 0px 8px;
    width: 27px;
}
.mini-input-finished {
    margin: 0px 8px;
    width: 54px;
}

.margin-registration {
    margin: 4px 5px;
}

.min-height-16 {
    min-height: 16px;
}
.margin-12-top{
    margin:12px 0;
}
.min-height-250 {
    min-height: 250px;
}
.min-height-550 {
    min-height: 550px;
}
.tournament-content  .page-title.min-width{
    min-width:70vw;
}
.create-tournament-date {
    flex-direction: column;
}

.create-tournament-date .margin-l.v-align {
    margin: 0 0 15px 0;
}

.min-width-100 {
    min-width: 117px;
    max-width: 200px;
    margin-right: 10px;
}
.fl-left{
    float: left
}

.min-width-50 {
    min-width: 60px;
    max-width: 200px;
    margin-right: 10px;
}

.min-width-50.own {
    min-width: 85px;
    max-width: 200px;
    margin: 0;
    width: 50px;
}

.date-flex{
    display: flex;
}
.rem-flex{
    display: inline-flex!important;
}
.float-r{
    float:right
}
.game-params, .places {
    display: flex;
    flex-direction: column;
}

.float {
    float: left;
}

.max-height {
    max-height: 34px;
}
.date-flex.max-height{
    max-height: 32px;
}

.column {
    flex-direction: column;
}

.v-align {
    align-items: center
}
.date-flex.v-align{
    color:whitesmoke;
}

.v-align-b {
    align-items: baseline;
}
.between{
    justify-content: space-between;
}
.border-vert {
    padding: 15px;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    margin-bottom: 20px;
}

.border-bottom {
    padding: 15px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
}

.winner-range {
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    border-bottom: thin solid #d4d4d5;
    padding: 8px 0;
    min-height: 61px;
}

.places {
    padding: 12px;
    border: thin solid #d4d4d5;
    border-radius: 6px;
    margin: 12px;
    min-width:550px;
    height: fit-content;
}
.places .error-mes{
    font-size: 12px;
    color: red;
    text-align: center;
    min-height: 20px;
}
.width-60{
    width: 60%;
}
.width-50{
    width: 50%;
}
.width-60.float{
   float:left;
}

.color-own {
    color: #21ba45;
    font-size: 13px;
}

.left {
    width: fit-content;
    margin: 0 0 0 4px
    /*transform: translateX(-10px);*/
}
.left.own{
    margin-left: 4px;
}
.reset-prize{
    margin: 0 0 0 77%;
    color: #21ba45;
    width: fit-content;
    position: relative;

}
.create-tournament-actions.save-prize{
    margin: 24px auto 4px;
}
.create-tournament-actions.prize{
    margin: 20px;
    display: flex;
    justify-content: flex-end;
}
.template-name.abs{
    position: absolute;
    bottom: 70px;
}
.error-mes.upload{
    padding: 7px 0;
    height: 20px;
    font-size: 11px;
}
.upload{
    min-width: 200px;
}
.ui.checkbox label, .ui.checkbox+label{
    color: #21ba45!important;
}
.label-X.own{
    color:#21ba45!important;
}
span.label-X{
    color: #21ba45!important;
}
.date-flex.column.margin-r{
    color: #21ba45!important;
}
.check-box.test{
    display: flex;
}