.date-from, .date-to, .filter-inputs{
    display: flex;
}
.date-err {
    margin: 7px 0;
    font-size: 11px;
}
.games-data-home{
    display:flex;
    align-items: baseline;
}
.games-data-home table{
    width: auto!important;
}
.games-data-home table tbody tr{
    display: flex;
    align-items: baseline;
}
.games-data-home table thead tr{
   text-align: center;
}
.home label {
    display: block;
    margin: 0 0 5px;
}
.home-select{
    margin: 4px 11px 8px;
}
.input-box.home_ {
    padding: 13px 13px 13px 0;
}
.bold-data{
    font-weight: 800;
    font-size: 14px;
    color: #94d86e;
}
