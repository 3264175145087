@media (min-width: 1023px) {
  .tour-info {
    position: static;
    background-image: none;
    background-color: transparent;
    height: 100%;
  }

  .tour-info__title {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tour-info__tabs {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tour-info__body {
    padding-bottom: 15px;
  }

  .knockout {
    .players-information.players-information--special {
      position: static;
      width: 100%;
      height: 100%;
      background: none;
      padding: 0;
    }

    .tour-info__tabs {
      justify-content: space-around;
    }

    .tour-info__tab {
      width: auto;
    }
  }
}
