.spin-content {
    margin: 12px 0;
}

.spin-page .ui.input > input {
    max-width: 90% !important;
}
.spin-info-content{
    width: 88%;
    margin: 12px 0;
}
.t-title{
    color: #0f7bcc;
    font-size: 14px;
    text-align: center!important;
}
