.tournament-content{
    margin: 12px 0;
    min-width: 100%;
}

.input-box-search-tour{
    display: flex;
    flex-wrap: wrap;
}

.disabled  .datepicker---time-input---2ICRB > input, .disabled .datepicker---time-input-icon---3TeZ8{
    pointer-events: none!important;
    opacity: .45!important;
}
.header-own{
    border: 0 solid transparent!important;
    background: #1b1c1d29!important;
}

.ui.table.winners td {
    padding: 1.1em .7em !important;
}

td a:hover, td a.active {
    color: #303132;
    text-decoration: none;
    background-color: transparent !important
}
.set{
    position: fixed;
    left: 40%;
    top: 4%;
    margin: 0;
    z-index: 2;
    padding: 12px;
    width: 200px;
}